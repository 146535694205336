import React, { useState } from 'react'
import { TabContainer } from '../../../../../components/tab/TabContainer'
import { TabItem } from '../../../../../components/tab/TabItem'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { ErrorPage } from '../../../../error/errorPage'
import { VerificationPathSubByString } from '../../../../../utils'
import { RegistrarEfectivoFaltanteValues, UpdateCuadernoEfectivoValues } from '../../../../../FormSchemes/CuadernoScheme'
import { Callers } from '../../../../../hooks/Callers'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ListIngresoCuadernoEfectivoHistorialTable } from './ListIngresoCuadernoEfectivoHistorialTable'
import { DeleteValues } from '../../../../../FormSchemes/EfectivoGeneralScheme'
import { ListCuadernoEfectivoTable } from './ListCuadernoEfectivoTable'
import { ModalPdf } from '../../../../../components/modals/ModalPdf'
import CuadernoBoletaPdf from '../../cuadernos/cuaderno_historial/CuadernoBoletaPdf'

export const CuadernoTab = ({
    dataCard,
}) => {
    const {
        dispatch,
        params,
        // data, setData,
        // paginate, setPaginate,
        // selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        // stateData, setStateData,
        // // imports
        // requestAuthPaginate,
        // TableContainer,
        // Paginator,
        // formatFilters,
        // Searcher,
        // FilterSelect,
        // RangeDate,
        Actions,
        // TableSection,
        // ActionSection,
        Section,
        ModalForm,
    } = useGeneralParams('')
    const [updateEfectivoModal, setUpdateEfectivoModal] = useState(false)
    const [registrarFaltanteModal, setRegistrarFaltanteModal] = useState(false)
    const [RevertirIngresoModal, setRevertirIngresoModal] = useState(false)
    const [BoletaPdfModModal, setBoletaPdfModModal] = useState(false)

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <Section>
            <Actions
                buttons={[
                    {
                        icon: 'money-bill',
                        label: 'Registrar faltante o sobrante',
                        action: () => setRegistrarFaltanteModal(true),
                        className: 'dark:bg-blue-700 bg-blue-500 text-white',
                        disabled: dataCard?.revisado
                    },
                    {
                        icon: 'money-bills',
                        label: 'Ingresar cuaderno a tesorería',
                        action: () => setUpdateEfectivoModal(true),
                        className: 'dark:bg-green-800 bg-green-500 text-white',
                        disabled: dataCard?.revisado
                    },
                    {
                        icon: 'circle-minus',
                        label: 'Revertir proceso de ingreso de cuaderno',
                        action: () => setRevertirIngresoModal(true),
                        className: 'dark:bg-red-700 bg-red-500 text-white',
                        disabled: !dataCard?.revisado
                    },
                    {
                        icon: 'file-pdf',
                        label: 'Boleta PDF',
                        action: () => setBoletaPdfModModal(true),
                        className: 'dark:bg-pink-600 bg-pink-500 text-white',
                        disabled: false
                    },
                ]}
            />
            <TabContainer>
                <TabItem
                    to='efectivo'
                    label='Efectivo'
                    active={VerificationPathSubByString('efectivo')}
                />
                <TabItem
                    to='movimientos'
                    label='Ingreso de cuaderno'
                    active={VerificationPathSubByString('movimientos')}
                />
            </TabContainer>
            <Routes>
                <Route index element={
                    <Route
                        path='efectivo'
                        element={
                            <ListCuadernoEfectivoTable
                                //
                                params={params}
                                setSelectAllChecked={setSelectAllChecked}
                                setIsChecked={setIsChecked}
                                selecteds={selecteds}
                                setSelecteds={setSelecteds}
                                selectAllChecked={selectAllChecked}
                                isChecked={isChecked}
                                dataCard={dataCard}
                            />
                        } />
                } />
                <Route
                    path='efectivo'
                    element={
                        <ListCuadernoEfectivoTable
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            dataCard={dataCard}
                        />
                    } />
                <Route
                    path='movimientos'
                    element={
                        <ListIngresoCuadernoEfectivoHistorialTable
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            dataCard={dataCard}
                        />
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
            {
                registrarFaltanteModal &&
                <ModalForm
                    setModal={setRegistrarFaltanteModal}
                    label={<span>Registrar faltante o sobrante del cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={RegistrarEfectivoFaltanteValues(dataCard)}
                    urlApi={`/cuadernoEfectivo/faltanteSobrante`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Actualizar'
                />
            }
            {
                updateEfectivoModal &&
                <ModalForm
                    setModal={setUpdateEfectivoModal}
                    label={<span>Registrar Ingresos de cuaderno <span className='dark:text-green-500 text-green-600 font-bold'>{dataCard?.codigo}</span></span>}
                    dataValues={UpdateCuadernoEfectivoValues(dataCard)}
                    urlApi={`/efectivoHistorial/cuaderno`}
                    method={'post'}
                    call={reloadTable}
                    buttonLabel='Ingresar cuaderno a tesorería'
                />
            }
            {
                RevertirIngresoModal &&
                <ModalForm
                    setModal={setRevertirIngresoModal}
                    label='¿Revertir el proceso de ingreso de cuaderno?'
                    dataValues={DeleteValues()}
                    urlApi={`/efectivoHistorial/cuaderno/${params.cuaderno_id}`}
                    method={'delete'}
                    call={reloadTable}
                    buttonLabel='Eliminar'
                />
            }
             {
                BoletaPdfModModal &&
                <ModalPdf
                    setModal={setBoletaPdfModModal}
                    width={'md:max-w-5xl'}
                >
                    <CuadernoBoletaPdf />
                </ModalPdf>
            }
        </Section>
    )
}