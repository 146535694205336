import React from 'react'
import { Link } from 'react-router-dom'

export const TabItem = ({ to, label, active, aditionalClassname = '', minW = false, isChart }) => {
    return (
        <Link
            to={to}
            className={`group flex flex-nowrap justify-center items-center gap-1 w-auto font-bold ${minW ? 'min-w-80' : ''} ${aditionalClassname} py-4 px-5 rounded-t-xl active ${active ? 'bg-white dark:bg-gray-800/60 text-yellow-600 dark:text-yellow-500' : 'bg-transparent dark:bg-transparent dark:text-gray-300/60 hover:bg-gray-50/70 hover:dark:bg-gray-800/30'}`}
        >
            {
                active ?
                    <i className={`${isChart ? 'fa fa-chart-simple' : 'fa-regular fa-clipboard'} w-6 text-xl`} />
                    :
                    <i className='fa fa-angle-down text-xl w-6 pt-0 group-hover:translate-y-3 transition-transform duration-200' />
            }
            {label}
        </Link>
    )
}