import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { CreatePersonalValues, CreateValues, DeleteValues } from '../../../../FormSchemes/AlmacenScheme'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../hooks/Callers'

export const ListAlmacenTable = ({ almTag, rolname }) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        // updateModal, setUpdateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        // formatFilters,
        Searcher,
        // FilterSelect,
        // RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        redirect
    } = useGeneralParams({ pageSize: 20 })
    // const [createAlmacenPersonalModal, setCreateAlmacenPersonalModal] = useState(false)
    const [createAlmacenVendedoresModal, setCreateAlmacenVendedoresModal] = useState(false)
    const [createAlmacenPuntosDeVentaModal, setCreateAlmacenPuntosDeVentaModal] = useState(false)
    const [createAlmacenEmpresasModal, setCreateAlmacenEmpresasModal] = useState(false)
    const [createAlmacenMayoristasModal, setCreateAlmacenMayoristasModal] = useState(false)
    const [createAlmacenProveedorModal, setCreateAlmacenProveedorModal] = useState(false)
    const [createAlmacenVentasDeOficinaModal, setCreateAlmacenVentasDeOficinaModal] = useState(false)
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/almacens/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre', almTag, rolname }
        }
        )
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), reloadMainTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), reloadMainTable]);

    const recall = () => {
        Callers(dispatch)
    }

    const ButtonTagValidations = () => {
        switch (almTag) {
            case 'general':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen General',
                        action: () => setCreateModal(true),
                        // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            case 'proveedor':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen para proveedores',
                        action: () => setCreateAlmacenProveedorModal(true),
                        // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            case 'empresas':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen para empresas',
                        action: () => setCreateAlmacenEmpresasModal(true),
                        // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            case 'mayoristas':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen para mayoristas',
                        action: () => setCreateAlmacenMayoristasModal(true),
                        // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            default:
                return []
        }
    }

    const ButtonRolValidations = () => {
        switch (rolname) {
            case 'carro-distribuidor':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen para carros distribuidores',
                        action: () => setCreateAlmacenVendedoresModal(true),
                        // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            case 'punto-de-venta':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen para puntos de venta',
                        action: () => setCreateAlmacenPuntosDeVentaModal(true),
                        // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            case 'oficina':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen para oficinas',
                        action: () => setCreateAlmacenVentasDeOficinaModal(true),
                        // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            case 'empresa':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen para empresas',
                        action: () => setCreateAlmacenEmpresasModal(true),
                        // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            case 'mayorista':
                return [
                    {
                        icon: 'add',
                        label: 'Crear Almacen para mayoristas',
                        action: () => setCreateAlmacenMayoristasModal(true),
                        // // className: 'bg-cyan-500 dark:bg-cyan-700 text-white',
                    },
                ]
            default:
                return []
        }
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={ButtonTagValidations()}
                />
                <Actions
                    buttons={ButtonRolValidations()}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Encargado',
                            value: 'encargado'
                        }
                    ]}
                    reloadTable={recall}
                />
                <div className='flex gap-2 flex-wrap'>
                    {/* <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    /> */}
                    {/* <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo_almacen'
                        options={[
                            {
                                label: 'Todos',
                                value: ''
                            },
                            {
                                label: 'General',
                                value: 'general'
                            },
                            {
                                label: 'Personal',
                                value: 'personal'
                            },
                            {
                                label: 'Proveedor',
                                value: 'proveedor'
                            }
                        ]}
                    /> */}
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        // {
                        //     label: 'Nombre',
                        //     columns: ['nombre']
                        // },
                        {
                            label: 'Almacén',
                            columns: ['nombre']
                        },
                        ...(
                            almTag === 'general' ?
                                [
                                    {
                                        label: 'Tipo',
                                        columns: ['principal'],
                                        boolean: true,
                                        booleanOptions: ['Principal', '']
                                    }
                                ]
                                :
                                []
                        ),
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'cyan',
                                    icon: 'fa-share-from-square',
                                    action: (data) => redirect(`productos/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Ver productos',
                                    redirect: true
                                },
                                {
                                    type: 'yellow',
                                    icon: 'fa-list',
                                    action: (data) => redirect(`historial/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Ver historial',
                                    redirect: true
                                },
                                {
                                    type: 'green',
                                    icon: 'fa-boxes-packing',
                                    action: (data) => redirect(`compras/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Compras',
                                    redirect: true
                                },
                                // {
                                //     type: 'edit',
                                //     icon: 'fa-edit',
                                //     action: (data) => UpdateValuesModal(data, setCurrentData, setUpdateModal),
                                // },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    doubleClickAction={(dataItem) => redirect(`productos/${dataItem.id}`)}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Crear almacén general'
                    dataValues={CreateValues({ tag: 'general' })}
                    urlApi={'/almacen'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                createAlmacenProveedorModal &&
                <ModalForm
                    setModal={setCreateAlmacenProveedorModal}
                    label='Crear almacén para proveedor'
                    dataValues={CreateValues({ tag: 'proveedor' })}
                    urlApi={'/almacen'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                createAlmacenVendedoresModal &&
                <ModalForm
                    setModal={setCreateAlmacenVendedoresModal}
                    label='Crear almacén para vendedores'
                    dataValues={CreatePersonalValues('/users/rol/carro-distribuidor?omitir=conAlmacén')}
                    urlApi={'/almacens'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                createAlmacenPuntosDeVentaModal &&
                <ModalForm
                    setModal={setCreateAlmacenPuntosDeVentaModal}
                    label='Crear almacén para Puntos de Venta'
                    dataValues={CreatePersonalValues('/users/rol/punto-de-venta?omitir=conAlmacén')}
                    urlApi={'/almacens'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                createAlmacenVentasDeOficinaModal &&
                <ModalForm
                    setModal={setCreateAlmacenVentasDeOficinaModal}
                    label='Crear almacén para Oficina'
                    dataValues={CreatePersonalValues('/users/rol/oficina?omitir=conAlmacén')}
                    urlApi={'/almacens'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                createAlmacenEmpresasModal &&
                <ModalForm
                    setModal={setCreateAlmacenEmpresasModal}
                    label='Crear almacén para Empresas'
                    dataValues={CreatePersonalValues('/users/rol/empresa?omitir=conAlmacén')}
                    urlApi={'/almacens'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                createAlmacenMayoristasModal &&
                <ModalForm
                    setModal={setCreateAlmacenMayoristasModal}
                    label='Crear almacén para Mayoristas'
                    dataValues={CreatePersonalValues('/users/rol/mayorista?omitir=conAlmacén')}
                    urlApi={'/almacens'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar almacén'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacen/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}