import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoImageForCardInfo, MainTargetInfo, TitleOfCardInfo } from '../../../../../components/card/MainTargetInfo';
import { useSelector } from 'react-redux';
import BoxImage from '../../../../../assets/box.png';

const CardInfoAlmacenProducto = ({ data, setData }) => {
    const params = useParams()
    const reloadMinorTable = useSelector(state => state.login.reloadMinorTable)
    return (
        <MainTargetInfo
            extraClassName=''
            urlApi={`/almacenProducto/${params.almacen_producto_id}`}
            setData={setData}
            dataRef={[params.almacen_producto_id, reloadMinorTable]}
        >
            <ItemCardTargetInfo
                logo={<LogoImageForCardInfo logo={BoxImage} />}
            // logoLabel={<LogoLabelTargetInfo logo={<NoteIconSvg />} />}
            // aditionalClassName=''
            >
                <TitleOfCardInfo label='Información de Almacén' />
                <div className='grid grid-cols-1 md:grid-cols-2 w-full md:gap-6 mt-2'>
                    <div>
                        <DescriptionTargetInfo label={'Almacén:'} data={data?.nombreAlm} />
                        <DescriptionTargetInfo label={'Producto:'} data={data?.nombreProd} />
                        <DescriptionTargetInfo label={'Descripción:'} data={data?.descProd} />
                    </div>
                    <div>
                        <DescriptionTargetInfo label={'Estado:'} data={data?.tipoProd} />
                        <DescriptionTargetInfo label={'Capacidad:'} data={data?.capacidadProd} />
                        <DescriptionTargetInfo label='Saldo actual:' data={data?.cantidad} />
                    </div>
                </div>
            </ItemCardTargetInfo>
            {/* <ItemCardTargetInfo>
                <NumberTargetInfo label='Total ingreso' data={data?.cantidad_ingreso} />
                <NumberTargetInfo label='Total egreso' data={data?.cantidad_egreso} />
                <NumberTargetInfo label='Total' data={data?.cantidad} />
            </ItemCardTargetInfo> */}
            {/* <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Existente' data={data?.cantidad} borderBotton={false} /> */}
            {/* <NumberGridTargetInfo label='Deudas' data={data?.cantidad_deuda} />
                <NumberGridTargetInfo label='Garantías' data={data?.cantidad_garantia} /> */}
            {/* <NumberGridTargetInfo label='Total' data={data?.cantidad_total} /> */}
            {/* <EmptyGridTargetInfo label='.' data={''} borderBotton={false} />
                <EmptyGridTargetInfo label='.' data={''} borderBotton={false} />
                <EmptyGridTargetInfo label='.' data={''} borderBotton={false} />
            </NumberCalculatorsTargetInfo> */}
            {/* <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Venta Bs.' data={data?.monto_venta} />
                <NumberGridTargetInfo label='Deuda Bs.' data={data?.monto_deuda} />
                <NumberGridTargetInfo label='Garantía Bs.' data={data?.monto_garantia} />
                <NumberGridTargetInfo label='Efectivo Bs.' data={data?.monto_efectivo} borderBotton={false} />
            </NumberCalculatorsTargetInfo> */}
        </MainTargetInfo>
    )
}

export default CardInfoAlmacenProducto