import * as Yup from 'yup';

export const solicitarCompraValues = (almacen_id) => {
    return {
        initialValues: {
            almacen_id: almacen_id || '',
            // producto_id: '',
            // estado_id: '',
            productos: []
        },
        fieldsValidation: Yup.object().shape({}),
        fields: [
            {
                label: 'Seleccionar producto',
                name: 'productos',
                type: 'solicitarCompra',
                required: false,
                urlApi: `/almacenProductos/${almacen_id}/all`,
                indexLabel: 'etiqueta',
                indexId: 'id',
                // generalData: data,
                title: 'COMPRA DE PRODUCTOS'
            },
            {
                type: 'divider'
            },
            {
                label: 'Observaciones Solicitud de compra',
                name: 'obs_solicitud_compra',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const CompraProductosPaso1 = () => {
    return {
        fields: [
            {
                label: 'Observaciones de solicitud de compra',
                name: 'obs_solicitud_compra',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const CompraProductosPaso2 = (proveedores, defaultValue, efectivoOptions, defaultEfectivo) => {
    return {
        fields: [
            {
                label: 'Proveedor',
                name: 'almacen_proveedor_id',
                type: 'selectSearchStatic',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Proveedor...',
                autoFocus: false,
                data: proveedores,
                defaultValue: defaultValue
            },
            {
                label: 'Cuenta',
                name: 'efectivo_id',
                type: 'selectSearchStatic',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Cuenta...',
                autoFocus: false,
                data: efectivoOptions,
                defaultValue: defaultEfectivo
            },
            {
                type: 'divider'
            },
            {
                label: 'Monto de compra',
                name: 'monto_compra',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: false,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto de flete',
                name: 'monto_flete',
                type: 'groupnumber',
                subData: 'Bs.',
                required: false,
                disabled: false,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto de otros gastos',
                name: 'monto_otros',
                type: 'groupnumber',
                subData: 'Bs.',
                required: false,
                disabled: false,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                type: 'divider'
            },
            {
                label: 'Observaciones confirmación de compra',
                name: 'obs_confirmacion_compra',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const CompraProductosPaso3 = (transportistas, defaultValue) => {
    return {
        fields: [
            {
                label: 'Transportista',
                name: 'transportista_id',
                type: 'selectSearchStatic',
                // urlApi: '/transportistas',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Transportista...',
                autoFocus: false,
                data: transportistas,
                defaultValue: defaultValue
            },
            {
                type: 'divider'
            },
            {
                label: 'Observaciones confirmación de salida',
                name: 'obs_confirmacion_salida',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const CompraProductosPaso4 = () => {
    return {
        fields: [
            {
                label: 'Fecha de entrega al proveedor',
                name: 'fecha_entrega_proveedor',
                type: 'datetime',
                required: true,
                placeholder: 'Fecha...',
                autoFocus: false
            },
            {
                label: 'Observaciones de distribución de envases',
                name: 'obs_distribucion_envase',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const CompraProductosPaso5 = () => {
    return {
        fields: [
            {
                type: 'divider'
            },
            {
                label: 'Observaciones de cantidad de retorno',
                name: 'obs_cantidad_retorno',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const CompraProductosPaso7 = () => {
    return {
        fields: [
            {
                type: 'divider'
            },
            {
                label: 'Precinto',
                name: 'precinto',
                type: 'text',
                required: false,
                placeholder: 'Nro...',
                autoFocus: false
            },
            {
                label: 'Observaciones de solicitud de compra',
                name: 'obs_solicitud_compra',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
            {
                label: 'Observaciones confirmación de compra',
                name: 'obs_confirmacion_compra',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
            {
                label: 'Observaciones confirmación de salida',
                name: 'obs_confirmacion_salida',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
            {
                label: 'Observaciones de distribución de envases',
                name: 'obs_distribucion_envase',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
            {
                label: 'Observaciones de cantidad de retorno',
                name: 'obs_cantidad_retorno',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
            {
                label: 'Observaciones de confirmación de llegada',
                name: 'obs_confirmacion_llegada',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const CompraProductosPaso6 = () => {
    return {
        fields: [
            {
                type: 'divider'
            },
            {
                label: 'Confirmar llegada de producto',
                name: 'confirmar_llegada',
                type: 'checkbox',
            },
            {
                label: 'Observaciones de confirmación de llegada',
                name: 'obs_confirmacion_llegada',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}