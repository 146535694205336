import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { AlmacenHistorialData } from '../../../AlmacenHistorialData';
import { BackButton } from '../../../../../../../components/buttons/ButtonDefault';

export const AlmacenHistorials = () => {
    const [data, setData] = useState(null);
    return (
        <div className='relative'>
            <BackButton />
            <CardInfo
                data={data}
                setData={setData}
            />
            <AlmacenHistorialData
                dataCard={data}
                toList='cierre'
            />
        </div>
    )
}