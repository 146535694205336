import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { formatDateWithTime } from '../../../../../utils/dateFormat'
import { CreateValues, ReajusteDeEfectivoValues } from '../../../../../FormSchemes/EfectivoGeneralHistorialScheme'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { setReloadMainTable, setReloadMinorTable, setReloadSubTable } from '../../../../../store/slices/auth/authSlice'
import { RegistrarGastoValues } from '../../../../../FormSchemes/EfectivoHistorialEgresosScheme'
import { CreateValuesEfectivoTraspasosExterno } from '../../../../../FormSchemes/EfectivoProductoScheme'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { UpdateValuesModal } from '../../../../../FormSchemes/GeneralFunctions'
import { DeleteValues } from '../../../../../FormSchemes/CuadernoScheme'
import { useSelector } from 'react-redux'

export const ListEfectivoHistorialTable = ({
    efectivoParams,
    dataCard,
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        // UpdateValuesModal,
        // redirect
    } = useGeneralParams()

    const [registrarGastoModal, setRegistrarGastoModal] = useState(false)
    const [traspasosModal, setTraspasosModal] = useState(false)
    const [CurrentData, setCurrentData] = useState(false)
    const [DeleteModal, setDeleteModal] = useState(false)
    const [EgresoDirecto, setEgresoDirecto] = useState(false)
    const [AjustareEfectivoModal, setAjustareEfectivoModal] = useState(false)

    const reloadMinorTable = useSelector(state => state.login.reloadMinorTable)

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: efectivoParams?.type === 'subEfectivo' ? `/efectivo/${params.sub_efectivo_id}/historial/pag` : (efectivoParams?.type === 'efectivoCierre' ? `/efectivoCierre/${params.efectivo_cierre_id}/historial/pag` : `/efectivo/${params.efectivo_id}/historial/pag`),
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'tipo' }
        })
    }
    useEffect(() => {
        getDataPaginate()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, params.efectivo_id, params.sub_efectivo_id, reloadMinorTable])

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado])

    const recall = () => {
        dispatch(setReloadMinorTable())
        dispatch(setReloadMainTable())
        dispatch(setReloadSubTable())
    }

    return (
        <Section>
            <ActionSection>
                {
                    efectivoParams?.type !== 'efectivoCierre' && efectivoParams?.type === 'subEfectivo' &&
                    <Actions
                        buttons={[
                            {
                                icon: 'minus',
                                label: 'Egreso directo',
                                action: () => setEgresoDirecto(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white',
                            },
                            {
                                icon: 'minus',
                                label: 'Registrar Gasto',
                                action: () => setRegistrarGastoModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                            {
                                icon: 'minus',
                                label: 'Traspasos',
                                action: () => setTraspasosModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                            {
                                icon: 'add',
                                label: 'Ingreso directo',
                                action: () => setCreateModal(true),
                            },
                            {
                                icon: 'money-bill',
                                label: 'Reajuste de efectivo',
                                action: () => setAjustareEfectivoModal(true),
                            },
                        ]}
                    />
                }
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Concepto',
                            value: 'tipo'
                        },
                    ]}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Entidad',
                            columns: ['nombre', 'encargado', 'producto_nombre']
                        },
                        {
                            label: 'Origen / Destino',
                            columns: ['user_nombres', 'user_apellidos', 'proveedor_encargado']
                        },
                        {
                            label: 'Monto anterior Bs.',
                            columns: ['monto_anterior'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Monto Bs.',
                            columns: ['monto'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Monto actual Bs.',
                            columns: ['monto_actual'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Billetes Bs.',
                            columns: ['billetes'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Monedas Bs.',
                            columns: ['monedas'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Dolares en Bs.',
                            columns: ['dolares_en_bs'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'ingreso / egreso',
                            columns: ['ingreso'],
                            booleanState: true,
                            booleanOptions: ['ingreso', 'egreso']
                        },
                        {
                            label: 'CÓDIGO DE TRANSACCIÓN',
                            columns: ['codigo_transaccion', 'tipo'],
                        },
                        {
                            label: 'CONFIRMACIÓN',
                            columns: ['verificado'],
                            boolean: true,
                            booleanOptions: ['Confirmado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-xmark'
                        },
                        {
                            label: 'fecha de registro',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id',
                                    tooltipText: 'Eliminar',
                                    validate: { value: 'abierto', validator: true }
                                }
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Registrar Ingreso directo'
                    dataValues={CreateValues(efectivoParams?.type === 'subEfectivo' ? params?.sub_efectivo_id : params?.efectivo_id, true)}
                    urlApi={`/efectivo_historial`}
                    method={'post'}
                    call={() => {
                        recall()
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                EgresoDirecto &&
                <ModalForm
                    setModal={setEgresoDirecto}
                    label='Registrar Egreso directo'
                    dataValues={CreateValues(efectivoParams?.type === 'subEfectivo' ? params?.sub_efectivo_id : params?.efectivo_id, false)}
                    urlApi={`/efectivo_historial`}
                    method={'post'}
                    call={() => {
                        recall()
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                registrarGastoModal &&
                <ModalForm
                    setModal={setRegistrarGastoModal}
                    label='Registro de Gasto'
                    dataValues={RegistrarGastoValues(efectivoParams?.type === 'subEfectivo' ? params?.sub_efectivo_id : params?.efectivo_id, true)}
                    urlApi={`/registrarGasto`}
                    method={'post'}
                    call={() => {
                        recall()
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                traspasosModal &&
                <ModalForm
                    setModal={setTraspasosModal}
                    label='Traspaso de efectivo a otras entidades'
                    dataValues={CreateValuesEfectivoTraspasosExterno(dataCard)}
                    urlApi={`/efectivoTraspasos`}
                    method={'post'}
                    call={() => {
                        recall()
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                DeleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar movimiento de efectivo'
                    dataValues={DeleteValues(CurrentData)}
                    urlApi={`/efectivoHistorial/${CurrentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
            {
                AjustareEfectivoModal &&
                <ModalForm
                    setModal={setAjustareEfectivoModal}
                    label='Reajuste de efectivo'
                    dataValues={ReajusteDeEfectivoValues(dataCard, true)}
                    urlApi={`/efectivo_historial/reajuste`}
                    method={'post'}
                    call={() => {
                        recall()
                    }}
                    buttonLabel='Registrar'
                />
            }
        </Section>
    )
}