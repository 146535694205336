import React, { useState } from 'react'
// import MainTargetInfo from './MainTargetInfo'
import { ListEfectivoCierreTable } from './ListEfectivoCierreTable';
import CardInfoEfectivo from '../elementosGenerales/CardInfo';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from '../../../../error/errorPage';
import { EfectivoHistorial } from '../efectivo_historial';
import { BackButton } from '../../../../../components/buttons/ButtonDefault';

export const EfectivoCierresHistorial = ({ efectivoParams, reloaders }) => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <Routes>
            <Route index element={
                <div className='relative'>
                    <BackButton />
                    <CardInfoEfectivo
                        reload={reload}
                        data={data}
                        setData={setData}
                        nameLabel='CIERRES'
                        efectivoParams={efectivoParams}
                    />
                    <ListEfectivoCierreTable
                        reload={reload}
                        setReload={setReload}
                        dataCard={data}
                        efectivoParams={efectivoParams}
                        reloaders={reloaders}
                    />
                </div>
            } />
            <Route
                path={`/historial/:efectivo_cierre_id`}
                element={<EfectivoHistorial
                    efectivoParams={{
                        type: 'efectivoCierre'
                    }}
                />}
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}
