import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'authGeneral',
    initialState: {
        log: false,
        userDetail: null,
        rols: [],
        rol: {},
        menu: {},
        menus: [],
        rolsToSelects: [],
        reloadMainTable: false,
        reloadSubTable: false,
        reloadMinorTable: false,
        reloadNestedTable: false,
    },
    reducers: {
        loginOn: (state) => {
            state.log = true
        },
        loginOut: (state) => {
            state.log = false
        },
        saveToken: (_, action) => {
            localStorage.setItem('token', action.payload)
        },
        removeToken: () => {
            localStorage.removeItem('token')
        },
        saveUserDetail: (state, action) => {
            let menus = []
            if (action?.payload?.rols != null && action?.payload?.rols !== undefined)
                for (const i of action?.payload?.rols) {
                    if (i?.menus?.length > 0)
                        menus.push(...i.menus)
                }
            state.userDetail = action.payload
            state.rols = action.payload?.rols
            state.rolsToSelects = action.payload?.rolsToSelects
            state.menus = menus
        },
        setReloadMainTable: (state, action) => {
            state.reloadMainTable = !state.reloadMainTable
        },
        setReloadSubTable: (state, action) => {
            state.reloadSubTable = !state.reloadSubTable
        },
        setReloadMinorTable: (state, action) => {
            state.reloadMinorTable = !state.reloadMinorTable
        },
        setReloadNestedTable: (state, action) => {
            state.reloadNestedTable = !state.reloadNestedTable
        },
        setCurrentMenu: (state, action) => {
            state?.userDetail?.rols?.forEach(role => {
                role?.menus?.forEach(menu => {
                    if (menu.nombre === action.payload) {
                        state.menu.id = menu.id;
                        state.menu.nombre = menu.nombre;
                        state.menu.etiqueta = menu.etiqueta;
                    }
                });
            });
        },
        setCurrentRol: (state, action) => {
            state.rol = state?.userDetail?.rols?.find(r => r.nombre === action.payload)
        },
    },
})

export const {
    loginOn,
    loginOut,
    saveToken,
    removeToken,
    saveUserDetail,
    setReloadMainTable,
    setReloadSubTable,
    setReloadMinorTable,
    setReloadNestedTable,
    setCurrentMenu,
    setCurrentRol
} = authSlice.actions