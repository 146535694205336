import React, { useState } from 'react'
import { AlmacenHistorialData } from '../../AlmacenHistorialData';
import CardInfoAlmacenProducto from '../../elementosGenerales/CardInfoAlmacenProducto';
import { BackButton } from '../../../../../../components/buttons/ButtonDefault';

export const AlmacenHistorials = () => {
    const [data, setData] = useState(null);
    return (
        <div className='relative'>
            <BackButton />
            <CardInfoAlmacenProducto
                data={data}
                setData={setData}
            // setReload={setReload}
            />
            <AlmacenHistorialData
                dataCard={data}
                toList='normal'
            />
        </div>
    )
}
