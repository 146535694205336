import React from 'react'
import { useNavigate } from 'react-router-dom'

export const BackButton = () => {
    const navigate = useNavigate()
    return (
        <div className='flex rounded-full absolute -top-1 -left-1 sm:-top-2 sm:-left-2 z-20 justify-center'>
            <button onClick={() => navigate('..')} className='text-white dark:text-gray-300 hover:dark:text-gray-200 rounded-md' title='Cerrar ventana'>
                <i className='fa-solid fa-circle-chevron-left text-[30px] text-gray-600 dark:text-gray-400 rounded-t-md' />
            </button>
        </div>
    )
}
