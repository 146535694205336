import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { CreateSubCuentaValues, CreateValues, DeleteValues } from '../../../../FormSchemes/EfectivoGeneralScheme'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
// import { CreateValuesExtraerSubEfectivosExterno } from '../../../../FormSchemes/EfectivoProductoScheme'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'

export const ListEfectivoTable = ({ globalParams, efectivoParams, reloaders, dispatchs }) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
        redirect,
        params

    } = useGeneralParams()
    // const [extraerSubEfectivosModal, setExtraerSubEfectivosModal] = useState(false)
    const [CuentaPersonalModal, setCuentaPersonalModal] = useState(false)
    const [SubCuentaModal, setSubCuentaModal] = useState(false)
    const [SubCuentaProdModal, setSubCuentaProdModal] = useState(false)

    // let recallCount = 1

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: efectivoParams?.type === 'subEfectivo' ? `/efectivo/${params.efectivo_id}/subEfectivos/pag` : `/efectivos/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre', ...globalParams }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, params.efectivo_id, ...reloaders]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado]);

    const recall = () => {
        dispatchs()
    }

    return (
        <Section>
            <ActionSection>
                {
                    globalParams?.tipo === 'otras-cuentas' && efectivoParams?.type !== 'subEfectivo' ?
                        <Actions
                            buttons={[
                                {
                                    icon: 'add',
                                    label: 'Crear cuenta general',
                                    action: () => setCreateModal(true)
                                },
                                {
                                    icon: 'add',
                                    label: 'Crear cuenta personal',
                                    action: () => setCuentaPersonalModal(true),
                                    className: 'dark:bg-red-700 bg-red-500 text-white'
                                },
                            ]}
                        />
                        :
                        efectivoParams?.type === 'subEfectivo' &&
                        <Actions
                            buttons={[
                                {
                                    icon: 'add',
                                    label: 'Crear sub-cuenta',
                                    action: () => setSubCuentaModal(true),
                                    className: 'dark:bg-cyan-700 bg-cyan-500 text-white'

                                },
                                {
                                    icon: 'add',
                                    label: 'Crear sub-cuenta de producto',
                                    action: () => setSubCuentaProdModal(true),
                                    className: 'dark:bg-rose-700 bg-rose-500 text-white'

                                },
                            ]}
                        />

                }
                {/* {
                    efectivoParams?.type === 'subEfectivo' &&
                    <Actions
                        buttons={[
                            {
                                icon: 'minus',
                                label: 'Centralizar efectivo',
                                action: () => setExtraerSubEfectivosModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                        ]}
                    />
                } */}
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        (
                            efectivoParams?.type === 'subEfectivo' ?
                                null
                                :
                                {
                                    label: 'Entidad',
                                    columns: ['nro', 'nombre', 'encargado_nombre', 'encargado_detalles']
                                }
                        ),
                        (
                            efectivoParams?.type === 'subEfectivo' ?
                                {
                                    label: 'Cuenta',
                                    columns: ['nro', 'nombre', 'encargado_nombre', 'encargado_detalles', 'producto_nombre']
                                } : null
                        ),
                        // {
                        //     label: 'Encargado',
                        //     columns: ['encargado_nombre', 'encargado_detalles']
                        // },
                        {
                            label: 'Monto total Bs.',
                            columns: ['monto_total'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Monto ingreso Bs.',
                            columns: ['monto_total_ingreso'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Monto egreso Bs.',
                            columns: ['monto_total_egreso'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Billetes Bs.',
                            columns: ['billetes_total'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Monedas Bs.',
                            columns: ['monedas_total'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Dolares Sus.',
                            columns: ['dolares_total'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Dolares Bs.',
                            columns: ['dolares_en_bs_total'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                (
                                    efectivoParams?.type === 'subEfectivo' ?
                                        null
                                        :
                                        {
                                            type: 'yellow',
                                            icon: 'fa-table',
                                            action: (data) => {
                                                redirect(`${data.id}`)
                                            },
                                            reference: 'id',
                                            tooltipText: 'Sub-cuentas',
                                            redirect: true,
                                            newIcon: {
                                                className: 'material-symbols-outlined',
                                                name: 'apps'
                                            }
                                        }
                                ),
                                {
                                    type: 'cyan',
                                    icon: 'fa-plus-minus',
                                    action: (data) => redirect(`historial/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Ingreso/Egresos',
                                    redirect: true,
                                    newIcon: {
                                        className: 'material-symbols-outlined',
                                        name: 'exposure'
                                    }
                                },
                                {
                                    type: 'green',
                                    icon: 'fa-money-bills',
                                    action: (data) => redirect(`cierres/${data.id}`),
                                    reference: 'id',
                                    tooltipText: 'Cierres',
                                    redirect: true,
                                    newIcon: {
                                        className: 'material-symbols-outlined',
                                        name: 'universal_currency_alt'
                                    }
                                },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id',
                                    tooltipText: 'Eliminar',
                                    validate: { value: 'abierto', validator: true }
                                }
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data?.data}
                    doubleClickAction={efectivoParams?.type === 'subEfectivo' ? (dataItem) => redirect(`historial/${dataItem.id}`) : (dataItem) => redirect(`${dataItem.id}`)}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label={`Crear cuenta general`}
                    dataValues={CreateValues(false)}
                    urlApi={'/efectivo'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                CuentaPersonalModal &&
                <ModalForm
                    setModal={setCuentaPersonalModal}
                    label='Crear cuenta personal'
                    dataValues={CreateValues(true)}
                    urlApi={'/efectivo'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                SubCuentaModal &&
                <ModalForm
                    setModal={setSubCuentaModal}
                    label='Crear sub-cuenta'
                    dataValues={CreateSubCuentaValues(params.efectivo_id)}
                    urlApi={'/efectivo'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                SubCuentaProdModal &&
                <ModalForm
                    setModal={setSubCuentaProdModal}
                    label='Crear sub-cuenta de producto'
                    dataValues={CreateSubCuentaValues(params.efectivo_id, true)}
                    urlApi={'/efectivo'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            }
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar efectivo'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/efectivo/${currentData.id}`}
                    method={'delete'}
                    call={recall}
                    buttonLabel='Eliminar'
                />
            }
            {/* {
                extraerSubEfectivosModal &&
                <ModalForm
                    setModal={setExtraerSubEfectivosModal}
                    label='Cargar almacenes'
                    dataValues={CreateValuesExtraerSubEfectivosExterno(dataCard)}
                    urlApi={`/extraerSubEfectivos`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        // dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            } */}
        </Section>
    )
}