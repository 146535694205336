import React, { useEffect, useState } from 'react'
import { requestDefaulAuth } from '../../../../http/httpRequest'
import { Section } from '../../../../components/table/Section'
import { useDispatch } from 'react-redux'
import { ActionSection } from '../../../../components/table/ActionSection'
import { RangeDate } from '../../../../components/datePicker/CustomDateRangePicker'
// import { FilterSelect } from '../../../../components/form/FilterSelect'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { CardUserVentas, LoaderCards } from '../incentivos/IncentivosCards'
import { DefaultMultiSelectVendedores } from '../../../../components/defaults'
import { formatFilters } from '../../../../utils/defaulStates'

export const GastosCuadernosCards = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [Load, setLoad] = useState(false)
    const [Paginate, setPaginate] = useState({ initial: '', final: '', rolnames: ['carro-distribuidor', 'punto-de-venta'] })
    const [SelectedDay, setSelectedDay] = useState(undefined)
    const getDatosGenerales = async () => {
        setLoad(true)
        await requestDefaulAuth(
            'get',
            `/gastosDeVendedores`,
            data,
            setData,
            dispatch,
            Paginate
        )
        setLoad(false)
    }
    useEffect(() => {
        getDatosGenerales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Paginate?.initial, Paginate?.final, Paginate?.rolnames])

    const reload = () => {
        getDatosGenerales()
    }
    return (
        <>
            <Section>
                <div className='text-gray-700 dark:text-gray-300 font-semibold text-xl text-center mb-2'>
                    <div>
                        GASTOS DE VENDEDORES
                    </div>
                </div>
                <ActionSection>
                    <div className='flex gap-2 flex-wrap'>
                        <RangeDate
                            selectedDay={SelectedDay}
                            setSelectedDay={setSelectedDay}
                            setPaginate={setPaginate}
                        />
                        <DefaultMultiSelectVendedores
                            paginate={Paginate}
                            setPaginate={setPaginate}
                            formatFilter={formatFilters}
                        />
                        {/* <FilterSelect
                            paginate={Paginate}
                            setPaginate={setPaginate}
                            name='rolnames'
                            options={[
                                {
                                    label: 'Todos',
                                    value: ''
                                },
                                {
                                    label: 'Carro distribuidor',
                                    value: 'ventas'
                                },
                                {
                                    label: 'Punto de venta',
                                    value: 'punto-de-venta'
                                },
                                {
                                    label: 'Oficina',
                                    value: 'oficina'
                                },
                            ]}
                        /> */}
                        <MinimalActionButton
                            icon='repeat'
                            label=''
                            action={reload}
                        />
                    </div>
                </ActionSection>
                {
                    Load ?
                        <LoaderCards length={2} />
                        :
                        <CardUserVentas
                            data={data?.userList}
                            zeroDanger={false}
                        />
                }
            </Section>
        </>

    )
}