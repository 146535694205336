import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import { requestAuth } from "../../../../http/httpRequest";
import { Months } from "../../../../utils/dictionary";
import { useDispatch } from "react-redux";
import { toastOn } from "../../../../store/slices/toast";
import { MinimalActionButton } from "../../../../components/form/minimalActionButton";
import { VerificationImageExist } from "../../../../utils";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);

export const VentasPorProductosRetPorMes = () => {
    const [dataTcc100, setDataTcc100] = useState([]);
    const [dataTcc200, setDataTcc200] = useState([]);
    const dispatch = useDispatch()
    const [labels, setLabels] = useState([]);
    const [gestion, setGestion] = useState(new Date().getFullYear());
    const [listTipoNegocios, setListTipoNegocios] = useState([]);
    const [tipoNegocio, setTipoNegocio] = useState('Abarrotes');
    const [recall, setRecall] = useState(false);

    const startYear = 2023;
    const endYear = new Date().getFullYear() + 1;
    const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
    
    const getData = async () => {
        await requestAuth(
            'get',
            `/informe/tipoNegocio/${tipoNegocio}/ventasPorMes?year=${gestion}`,
            null
        )
            .then((res) => {
                const fullData = Array.from({ length: 12 }, (_, i) => ({
                    mes: i + 1,
                    tcc100: 0,
                    tcc200: 0,
                }));

                res.data?.forEach(venta => {
                    const index = fullData.findIndex(item => item.mes === venta.mes);
                    if (index !== -1) {
                        fullData[index].tcc100 = venta.tcc100 || 0;
                        fullData[index].tcc200 = venta.tcc200 || 0;
                    }
                });

                setLabels(fullData.map(venta => `${Months[venta.mes]}`));
                setDataTcc100(fullData.map(venta => venta.tcc100));
                setDataTcc200(fullData.map(venta => venta.tcc200));
            })
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
            })
    };
    const getTipoNegocios = async () => {
        await requestAuth(
            'get',
            `/nombreEstado/Tipos de negocio/subEstados`,
            null
        )
            .then((res) => {
                setListTipoNegocios(res.data.map(x => x.nombre))
            })
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar los tipos de negocio.' }))
            })
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gestion, tipoNegocio, recall]);

    useEffect(() => {
        getTipoNegocios()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleGestionChange = (e) => {
        setGestion(e.target.value);
    };

    const handleTipoNegocioChange = (e) => {
        setTipoNegocio(e.target.value);
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: `Ventas TCC 100 (${gestion})`,
                data: dataTcc100,
                backgroundColor: 'rgba(63,71,78, 1)',
                borderColor: 'rgba(63,71,78, 1)',
                borderWidth: 0,
            },
            {
                label: `Ventas TCC 200 (${gestion})`,
                data: dataTcc200,
                backgroundColor: 'rgba(243,61,73, 1)',
                borderColor: 'rgba(243,61,73, 1)',
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: context => `Total: ${context.raw}`,
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: `Ventas por mes de ${gestion}`,
                },
                ticks: { color: 'rgba(170,170,170)' },
                grid: {
                    color: 'rgba(170,170,170,.7)'
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Meses',
                },
                ticks: { color: 'rgba(170,170,170)' },
                grid: {
                    color: 'rgba(170,170,170,.7)'
                },
            },
        },
    };

    return (
        <div>
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                <div className='dark:text-gray-300 text-center text-gray-800'>
                    <h1 className='text-xl font-bold flex justify-center  flex-col sm:flex-row items-center gap-2 relative'>
                        <span>CANTIDAD DE PRODUCTOS VENDIDOS POR TIPO CLIENTE</span>
                        <select
                            id="gestion-select"
                            className="'bg-transparent dark:bg-gray-900 text-gray-800 text-lg dark:text-gray-200 outline-none border border-gray-300 rounded-lg dark:focus:border-gray-400 focus:border-gray-300 focus:ring-0 dark:border-gray-700 dark:placeholder-gray-400 dark:focus:ring-0"
                            value={gestion}
                            onChange={handleGestionChange}
                        >
                            {years.map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        <select
                            id="gestion-select"
                            className="'bg-transparent dark:bg-gray-900 text-gray-800 text-lg dark:text-gray-200 outline-none border border-gray-300 rounded-lg dark:focus:border-gray-400 focus:border-gray-300 focus:ring-0 dark:border-gray-700 dark:placeholder-gray-400 dark:focus:ring-0"
                            value={tipoNegocio}
                            onChange={handleTipoNegocioChange}
                        >
                            {listTipoNegocios?.map(tipoNeg => (
                                <option key={tipoNeg} value={tipoNeg}>
                                    {tipoNeg}
                                </option>
                            ))}
                        </select>
                        <div className="sm:absolute top-0 right-0 w-16 sm:w-36">
                            <img src={require(`../../../../assets/${VerificationImageExist(tipoNegocio)}`)} alt="img" className="" />
                        </div>
                    </h1>
                    <h4 className='text-lg font-semibold z-10'>AC - 100LT Y DE AC - 200LT</h4>
                </div>
                <MinimalActionButton
                    icon='repeat'
                    label=''
                    action={() => setRecall(x => !x)}
                />
            </div>
            <Bar data={chartData} options={options} />
        </div>
    );
};