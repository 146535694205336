import React, { useEffect, useState } from 'react'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { ModalForm } from '../../../../../components/modals/ModalForm'
import { UpdateValuesModal } from '../../../../../FormSchemes/GeneralFunctions'
import { UpdateCuadernoEfectivoValues } from '../../../../../FormSchemes/CuadernoEfectivoScheme'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { Callers } from '../../../../../hooks/Callers'
import { useSelector } from 'react-redux'

export const ListCuadernoEfectivoTable = ({
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
    dataCard
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        // formatFilters,
        Searcher,
        // FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
    } = useGeneralParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const [updateEfectivoModal, setUpdateEfectivoModal] = useState(false)
    const [currentData, setCurrentData] = useState(null)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/cuaderno/${params.cuaderno_id}/efectivos/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'tipo' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), params.efectivo_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Tipo',
                            value: 'tipo'
                        },
                        {
                            label: 'Producto',
                            value: '$Producto.nombre$'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    {/* <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Abiertos y cerrados',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    /> */}
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Producto',
                            columns: ['tipo', 'producto'],
                            // tag: true
                        },
                        // {
                        //     label: 'Producto',
                        //     columns: ['producto']
                        // },
                        {
                            label: 'Monto total.',
                            columns: ['monto_total_string'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Billetes',
                            columns: ['billetes_string'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Monedas',
                            columns: ['monedas_string'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Dolares',
                            columns: ['dolares_string'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'edit',
                                    icon: 'fa-edit',
                                    action: (item) => UpdateValuesModal(item, setCurrentData, setUpdateEfectivoModal),
                                    disabled: dataCard?.revisado
                                },
                                // {
                                //     type: 'delete',
                                //     icon: 'fa-trash',
                                //     action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                //     reference: 'id'
                                // },
                            ],
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
                {
                    updateEfectivoModal &&
                    <ModalForm
                        setModal={setUpdateEfectivoModal}
                        label={<span>Actualizar efectivo de <span className='dark:text-green-500 text-green-600 font-bold'>{currentData?.producto ?? 'FALTANTE'}</span></span>}
                        dataValues={UpdateCuadernoEfectivoValues(currentData)}
                        urlApi={`/cuadernoEfectivo`}
                        method={'post'}
                        call={() => {
                            // setRecallState(!recallState)
                            // dispatch(setReloadSubTable())
                            // dispatch(setReloadMainTable())
                            reloadTable()
                        }
                        }
                        buttonLabel='Actualizar'
                    />
                }
            </TableSection>
        </>
    )
}