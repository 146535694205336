import React, { useState } from 'react';
import { TabContainer } from '../../../../../components/tab/TabContainer';
import { TabItem } from '../../../../../components/tab/TabItem';
import { useGeneralParams } from '../../../../../hooks/useDataPaginate';
import { Route, Routes } from 'react-router-dom';
import { ErrorPage } from '../../../../error/errorPage';
import { VerificationPathByString } from '../../../../../utils';
import { ListClienteDeudaTable } from './ListClienteDeudaTable';
import { ListClientePrestamoTable } from './ListClientePrestamoTable';
import { ListClienteGarantiaTable } from './ListClienteGarantiaTable';
import { PerfilCliente } from './PerfilCliente';
import { ListCuadernoHistorialTable } from '../../cuadernos/cuaderno_historial/ListCuadernoHistorialTable';
import { VentasPorMes } from './VentasPorMes';

export const ClienteTab = ({
    reload,
    setReload,
}) => {
    const {
        params,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        Section,
    } = useGeneralParams('')
    const [recallState] = useState(false);

    return (
        <Section>
            <TabContainer>
                {
                    [{
                        to: 'perfil',
                        label: 'Perfíl'
                    },
                    {
                        to: 'deudas',
                        label: 'Deudas'
                    },
                    {
                        to: 'prestamos',
                        label: 'Prestamos'
                    },
                    {
                        to: 'garantias',
                        label: 'Garantias'
                    },
                    {
                        to: 'historial-compras',
                        label: 'Historial de compras'
                    },
                    {
                        to: 'compras-por-mes',
                        label: 'Compras por mes'
                    }
                    ].map((i, idx) => (
                        <TabItem
                            key={idx}
                            to={i.to}
                            label={i.label}
                            active={VerificationPathByString('/' + i.to)}
                        />
                    ))
                }
            </TabContainer>
            <Routes>
                <Route index element={
                    <PerfilCliente
                        reload={reload}
                        setReload={setReload}
                        //
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route
                    path='perfil'
                    element={
                        <PerfilCliente
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='deudas'
                    element={
                        <ListClienteDeudaTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='prestamos'
                    element={
                        <ListClientePrestamoTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='garantias'
                    element={
                        <ListClienteGarantiaTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='historial-compras'
                    element={
                        <ListCuadernoHistorialTable
                            reload={reload}
                            setReload={setReload}
                            //
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            user_cli_id={params.user_cli_id}
                        />
                    } />
                <Route
                    path='compras-por-mes'
                    element={
                        <VentasPorMes />
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Section>
    )
}
