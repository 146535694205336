import React from 'react'
import { HistorialGarantia } from './historial_garan'
import { ErrorPage } from '../../../error/errorPage'
import { Route, Routes } from 'react-router-dom'
import { ListDeudaEnvaseTable } from './ListDeudaEnvaseTable'


export const DeudaEnvases = ({ globalParams }) => {
    return (
        <Routes>
            <Route index element={
                <ListDeudaEnvaseTable globalParams={globalParams} />
            } />
            <Route
                path={`:deuda_id`}
                element={<HistorialGarantia globalParams={globalParams} />}
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}