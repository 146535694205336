import React from 'react'
import { ListClientesTable } from './ListClientesTable'
import { Route, Routes } from 'react-router-dom'
import { ErrorPage } from '../../../error/errorPage'
import { PerfilCliente } from './perfil_cli'


export const Clientes = () => {
    return (
        <Routes>
            <Route index element={
                <ListClientesTable
                />
            } />
            <Route
                path={'/users_cli/:user_cli_id/*'}
                element={
                    <PerfilCliente
                    />
                }
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}