import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useMenuHandling from '../../hooks/useMenuHandling'
import { iconSelectByNameMenu } from '../../utils/iconByNameMenu'
import { useLocation } from 'react-router-dom'

const Sidebar = ({
    sidebar,
    setSidebar,
}) => {
    const { rols, params } = useMenuHandling()
    const [activeNav, setActiveNav] = useState([params.rolname] ?? [])
    const [viewSide, setViewSide] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [ClassicMode, setClassicMode] = useState(localStorage.getItem('navigation') === 'deploy' ? false : true)
    // console.log(rols?.map(a => a.nombre))
    const location = useLocation()
    const clicMenu = () => {
        if (window.innerWidth < 640) {
            setSidebar(false)
        }
    }
    useEffect(() => {
        const storedNavigation = localStorage.getItem('navigation')
        if (storedNavigation === 'deploy') {
            let fullData = []
            setClassicMode(false)
            if (rols) {
                for (const r of rols ?? []) {
                    if (r.menus.length > 0) {
                        fullData.push(r.nombre)
                    }
                }
            }
            setActiveNav(fullData)
        }
    }, [rols])

    useEffect(() => {
        function handleScreenWidth() {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleScreenWidth)

        return () => {
            window.removeEventListener('resize', handleScreenWidth)
        }
    }, [])

    useEffect(() => {
        if (screenWidth < 640) {
            setViewSide(true)
            setSidebar(false)
        }
        else {
            setViewSide(false)
            setSidebar(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenWidth])

    return (
        <>
            <aside
                // id='logo-sidebar'
                className={'fixed top-0 left-0 z-20 w-64 h-screen pt-[65px] pb-16 overflow-auto transition-transform bg-gray-700 border-gray-200  dark:bg-gray-800 dark:border-gray-700' + (sidebar ? ' sm:translate-x-0 shadow-xl dark:shadow-gray-900' : ' -translate-x-full')}
            // aria-label='Sidebar'
            >
                {
                    rols?.slice()?.sort((a, b) => a.orden - b.orden)?.map((rol, index) => (
                        <div
                            key={index}
                            className={` transition-all duration-200 ease-in-out ${rol.nombre === params.rolname ? 'bg-gray-800 dark:bg-gray-700' : ''}`}
                        >
                            <div
                                className={`flex items-center cursor-pointer select-none py-2 px-3 dark:hover:text-gray-200 hover:text-gray-200 ${activeNav.includes(rol.nombre) ? 'text-gray-200 dark:text-gray-300 font-bold' : 'text-gray-300 dark:text-gray-300/90 text-base font-bold justify-end'}`}
                                onClick={() => setActiveNav(x => activeNav.includes(rol.nombre) ? activeNav.filter(item => item !== rol.nombre) : (ClassicMode ? [rol.nombre] : [...x, rol.nombre]))}
                            >
                                <i className='fa-solid fa-list-ul'></i>
                                <span className='flex-1 ml-2 whitespace-pre-wrap font-semibold'>{rol?.etiqueta}</span>
                                <span className='relative flex h-3 w-3 mr-2'>
                                    <span className={`relative inline-flex rounded-full h-3 w-3 transition-all duration-500 ease bg-red-600 ${rol.nombre === params.rolname && !activeNav.includes(rol.nombre) ? 'scale-100' : 'scale-0'}`}></span>
                                </span>
                                <i className={`transition-transform duration-400 ease-in-out fa fa-angle-down ${activeNav.includes(rol.nombre) ? 'rotate-0' : '-rotate-90'} text-xs`} />
                            </div>
                            <div className='relative overflow-hidden h-auto'>
                                {/* <div className={`dark:bg-gray-700 overflow-hidden bg-gray-300 text-sm transition-transform ${activeNav.includes(rol.nombre) ? 'translate-y-0 pb-3' : '-translate-y-full max-h-0'}`}> */}
                                <div className={`overflow-hidden  text-sm`}
                                    style={{
                                        overflow: 'hidden',
                                        height: activeNav.includes(rol.nombre) ? (((rol.menus.length > 0 ? rol.menus.length : 1) * 36 + 15) + 'px') : '0px',
                                        transition: 'height 0.1s ease',
                                    }}
                                >

                                    {
                                        rol.menus?.slice()?.sort((a, b) => a.orden - b.orden)?.map((menu, index) => (
                                            <Link
                                                key={index}
                                                to={`rol/${rol?.nombre}/menu/${menu?.nombre}`}
                                                className={`flex items-center p-2 ml-2 rounded-l-full transition-colors duration-100 ease ${decodeURIComponent(location.pathname) === `/rol/${rol?.nombre}/menu/${menu?.nombre}` || decodeURIComponent(location.pathname)?.includes(`/rol/${rol?.nombre}/menu/${menu?.nombre}/`) ? 'dark:bg-gray-800 bg-yellow-500 dark:text-yellow-500 text-gray-800 justify-end font-semibold' : 'font-semibold dark:text-gray-200 text-gray-300 hover:bg-gray-600 dark:hover:bg-gray-600'}`}
                                                onClick={clicMenu}
                                                title={menu?.etiqueta}
                                            >
                                                <div>
                                                    {
                                                        decodeURIComponent(location.pathname) === `/rol/${rol?.nombre}/menu/${menu?.nombre}` || decodeURIComponent(location.pathname)?.includes(`/rol/${rol?.nombre}/menu/${menu?.nombre}/`) ?
                                                            <div className="flex items-center gap-x-0 text-nowrap mr-1">
                                                                <span
                                                                    className="overflow-hidden max-w-[216px]"
                                                                    title={menu?.etiqueta}
                                                                >
                                                                    {menu?.etiqueta.length > 30 ? `${menu.etiqueta.slice(0, 30)}...` : menu.etiqueta}
                                                                </span>
                                                                <span>
                                                                    <i
                                                                        className={`${iconSelectByNameMenu(menu.nombre)} translate-x-2 mt-0.5 transition-transform duration-200 text-[10px]`}
                                                                    />
                                                                </span>
                                                            </div>
                                                            :
                                                            // <span className=''><i className={iconByNameMenu(menu.nombre)} /> {menu?.etiqueta}</span>
                                                            <div className='flex items-center gap-x-0 text-nowrap mr-2'>
                                                                {/* <i className={iconByNameMenu('circle')} /> <span className=''> {menu?.etiqueta}</span> */}
                                                                <i className='far fa-file-lines text-gray-400 text-[12px] p-0' /><span className='ml-1.5'>{menu?.etiqueta.length > 30 ? `${menu.etiqueta.slice(0, 30)}...` : menu.etiqueta}</span>
                                                            </div>
                                                    }
                                                </div>
                                            </Link>
                                        ))
                                    }
                                    {
                                        rol?.menus?.length === 0 && activeNav.includes(rol.nombre) &&
                                        <div className='text-sm'>
                                            <ul className='font-medium'>
                                                <div
                                                    className={`flex items-center justify-center p-2 rounded-l-full dark:text-gray-500 text-gray-500 font-semibold`}
                                                >
                                                    <span className=''>(vacio)</span>
                                                </div>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </aside >
            {
                viewSide &&
                <div className={'fixed top-0 right-0 z-10 w-full h-screen pt-[65px] pb-16 overflow-auto transition-all duration-300 ' + (sidebar ? ' sm:translate-x-0 shadow-xl bg-gray-700 dark:bg-gray-800 opacity-50 ' : ' translate-x-full bg-gray-300 dark:bg-gray-500 opacity-30 ')} onClick={() => setSidebar(false)}></div>
            }
        </>
    )
}

export default Sidebar
// fa-solid fa-truck-front