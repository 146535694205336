import React from 'react'
import { ListVendedoresTable } from './ListVendedoresTable'
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { ErrorPage } from '../../../error/errorPage';
import { PerfilVendedor } from './perfil_vend';

export const Vendedores = ({ globalParams }) => {
    // const [mainReloadTable, setMainReloadTable] = useState(false)
    return (
        <Routes>
            <Route index element={
                <ListVendedoresTable
                    // mainReloadTable={mainReloadTable}
                    // setMainReloadTable={setMainReloadTable}
                    globalParams={globalParams}
                />
            } />
            <Route
                path={`/users_vend/:user_vend_id/*`}
                element={<PerfilVendedor
                // mainReloadTable={mainReloadTable}
                // setMainReloadTable={setMainReloadTable}
                />}
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}