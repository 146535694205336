import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { setReloadMainTable } from '../../../../store/slices/auth/authSlice'
import { TabBoard } from '../../../../components/tab/TabBoard'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { DefaultMultiSelectVendedores } from '../../../../components/defaults'
import { useLocation } from 'react-router-dom'
// import { formatDateWithTime } from '../../../../utils/dateFormat'

export const AlmacenDeudaEnvasePorVendedor = ({ urlApi, title, subtitle, tipoDeuda = '' }) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        // updateModal, setUpdateModal,
        // deleteModal, setDeleteModal,
        // currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        // FilterSelect,
        RangeDate,
        // Actions,
        TableSection,
        ActionSection,
        Section,

    } = useGeneralParams({ aditionals: { rolnames: ['carro-distribuidor', 'punto-de-venta'] } })

    const location = useLocation()
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: urlApi,
            data: null,
            queries: { ...paginate, },
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.rolnames, location.pathname]);

    // useEffect(() => {
    //     console.log(selecteds)
    // }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.rolnames]);

    const recall = () => {
        getDataPaginate()
        setSelecteds([])
        setSelectAllChecked(false)
        setIsChecked(false)
        dispatch(setReloadMainTable())
    }
    return (
        <TabBoard
            title={title}
            subtitle={subtitle}
        >
            <Section>
                <ActionSection>
                    <div className='flex gap-2 flex-wrap'>
                        <RangeDate
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            paginate={paginate}
                            setPaginate={setPaginate}
                        />
                        <DefaultMultiSelectVendedores
                            paginate={paginate}
                            setPaginate={setPaginate}
                            formatFilter={formatFilters}
                        />
                        <MinimalActionButton
                            icon='repeat'
                            label=''
                            action={recall}
                        />
                    </div>
                </ActionSection>
                <TableSection
                    stateData={stateData}
                    paginator={Paginator}
                    data={data}
                >
                    <TableContainer
                        headers={[
                            {
                                label: 'Responsable',
                                columns: ['envaseEncargadoCodigo', 'envaseEncargadoDetalles'],
                                // icon: 'fa-solid fa-user-circle text-lg',
                                // skipLine: true,
                                className: 'flex items-center',
                                // stickyL: true
                            },
                            // {
                            //     label: 'Deudor',
                            //     columns: ['envaseDeudorCodigo', 'envaseDeudorDetalles'],
                            //     // icon: 'fa-solid fa-user-circle text-lg',
                            //     // skipLine: true,
                            //     className: 'flex items-center',
                            //     // stickyL: true
                            // },
                            {
                                label: 'Producto',
                                columns: ['producto_nombre'],
                            },
                            {
                                label: 'Cuaderno',
                                columns: ['cuaderno'],
                            },
                            {
                                label: 'Deuda envase inicial',
                                columns: ['saldo_inicial'],
                                numberValue: true,
                                sum: true
                            },
                            {
                                label: 'Deuda envase',
                                columns: ['saldo'],
                                numberValue: true,
                                sum: true
                            },
                            // ...(
                            //     tipoDeuda === 'garantía' ?
                            //         [
                            //             {
                            //                 label: 'Método de pago',
                            //                 columns: ['metodo_pago_garantia'],
                            //                 tag: true
                            //             },
                            //             {
                            //                 label: 'Monto inicial Bs.',
                            //                 columns: ['monto_garantia_inicial'],
                            //                 numberValue: true,
                            //                 sumDec: true
                            //             },
                            //             {
                            //                 label: 'Saldo Bs.',
                            //                 columns: ['monto_garantia'],
                            //                 numberValue: true,
                            //                 sumDec: true
                            //             }
                            //         ] :
                            //         []
                            // ),
                            {
                                label: 'Estado',
                                columns: ['estado'],
                                boolean: true,
                                booleanOptions: ['Completado', 'Pendiente'],
                                booleanIconTrue: 'fa-check',
                                booleanIconFalse: 'fa-circle'
                            },
                            // {
                            //     label: tipoDeuda === 'garantía' ? 'Fecha' : 'Fecha de préstamo',
                            //     columns: ['createdAt'],
                            //     transform: true,
                            //     func: formatDateWithTime
                            // },
                            // ...(
                            //     tipoDeuda === 'garantía' ?
                            //         []
                            //         :
                            //         [{
                            //             label: 'Fecha límite',
                            //             columns: ['fecha_limite'],
                            //             transform: true,
                            //             func: formatDateWithTime
                            //         }]
                            // ),
                            // {
                            //     label: 'Observaciones',
                            //     columns: ['obs'],
                            // },
                        ]}
                        data={data.data}
                        aditionalCols={data.aditionalCols}
                        aditionalColsRef={null}
                        aditionalColsAmount={false}
                        checkList={false}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        setSelectAllChecked={setSelectAllChecked}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        stateData={stateData}
                    />
                </TableSection>
            </Section>
            {/* <Bars
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='INGRESOS DE VACIOS Y EGRESO CON CONTENIDO'
                hoverTitle='Cantidad'
            /> */}
            {/* <Pies
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='INGRESOS DE VACIOS'
                position={0}
                hoverTitle='Cantidad'
            />
            <Pies
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='EGRESO CON CONTENIDO'
                position={1}
                hoverTitle='Cantidad'
            />
            <Pies
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='TRASPASO CON CONTENIDO'
                hoverTitle='Cantidad'
                position={2}
            />
            <Pies
                data={data.data}
                aditionalCols={data.aditionalCols}
                title='TRASPASO DE VACIOS'
                hoverTitle='Cantidad'
                position={3}
            /> */}
        </TabBoard>
    )
}