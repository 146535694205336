import React, { useEffect } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { formatDateWithTime } from '../../../../utils/dateFormat'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { useSelector } from 'react-redux'
import { FilterSelect } from '../../../../components/form/FilterSelect'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { Callers } from '../../../../hooks/Callers'

export const ListIngresosYEgresosTable = () => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        // imports
        formatFilters,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        Searcher,
        RangeDate,
        TableSection,
        ActionSection,
        Section,

    } = useGeneralParams()
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/allEfectivoHistorial/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'tipo' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.revisado, reloadMainTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.revisado, reloadMainTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }
    return (
        <Section>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Concepto',
                            value: 'tipo'
                        },
                        {
                            label: 'Producto',
                            value: '$Producto.nombre$'
                        }
                    ]}
                    reloadTable={() => {
                        reloadTable()
                    }}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='revisado'
                        options={[
                            {
                                label: 'Revisados y pendientes',
                                value: ''
                            },
                            {
                                label: 'Revisados',
                                value: 'true'
                            },
                            {
                                label: 'Pendientes',
                                value: 'false'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Entidad',
                            columns: ['mainEfectivoNombre', 'nombre', 'encargado', 'producto_nombre']
                        },
                        {
                            label: 'Origen / Destino',
                            columns: ['user_nombres', 'user_apellidos', 'proveedor_encargado']
                        },
                        // {
                        //     label: 'Monto anterior Bs.',
                        //     columns: ['monto_anterior'],
                        //     numberValue: true,
                        // },
                        {
                            label: 'Monto Bs.',
                            columns: ['monto'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Monto actual Bs.',
                            columns: ['monto_actual'],
                            numberValue: true,
                            sumDec: true
                        },
                        // {
                        //     label: 'Billetes Bs.',
                        //     columns: ['billetes'],
                        //     numberValue: true,
                        // },
                        // {
                        //     label: 'Monedas Bs.',
                        //     columns: ['monedas'],
                        //     numberValue: true,
                        // },
                        // {
                        //     label: 'Dolares en Bs.',
                        //     columns: ['dolares_en_bs'],
                        //     numberValue: true,
                        // },
                        {
                            label: 'ingreso / egreso',
                            columns: ['ingreso'],
                            booleanState: true,
                            booleanOptions: ['ingreso', 'egreso']
                        },
                        {
                            label: 'CÓDIGO DE TRANSACCIÓN',
                            columns: ['codigo_transaccion', 'tipo'],
                        },
                        // {
                        //     label: 'CONFIRMACIÓN',
                        //     columns: ['verificado'],
                        //     boolean: true,
                        //     booleanOptions: ['Confirmado', 'Pendiente'],
                        //     booleanIconTrue: 'fa-check',
                        //     booleanIconFalse: 'fa-xmark'
                        // },
                        {
                            label: 'fecha de registro',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
        </Section>
    )
}