import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import { loginOn } from '../store/slices/auth'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import DefaultBanner from '../components/banner/DefaultBanner'
import ListCuadernosAbiertosPanel from '../components/panel/ListCuadernosAbiertosPanel'
import config from "../config.json";

export const Home = () => {
    const userDetail = useSelector(state => state.login.userDetail)
    const menus = useSelector(state => state.login.menus)
    const params = useParams()
    const location = useLocation()
    // const currentRol = useSelector(state => state.login.currentRol)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const element = document.documentElement

    useEffect(() => {
        if (localStorage?.getItem('token')) {
            dispatch(loginOn())
            navigate('/')
        }
        if (localStorage?.getItem('theme') !== 'dark') {
            element.classList.remove('dark')
            localStorage.removeItem('theme')
        }
        else {
            element.classList.add('dark')
            localStorage.setItem('theme', 'dark')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.localStorage.setItem('location', location.pathname)
    }, [location.pathname])


    useEffect(() => {
        if (menus != null && menus.length > 0) {
            document.title = `${menus.find(x => x.nombre === params.menuname) ? (menus.find(x => x.nombre === params.menuname)?.etiqueta + ' - ') : ''}${config.simpleName ?? ''}`
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.menuname, menus]);
    return (
        <>
            <DefaultBanner />
            <ListCuadernosAbiertosPanel rols={userDetail?.rols} dispatch={dispatch} />
            {/* <div className='my-4'>
                <h1 className='dark:text-white font-semibold text-md'>Lista de roles disponibles <span className='dark:text-indigo-400 text-indigo-500'>{userDetail?.username}</span></h1>
            </div> */}
            {/* <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mb-4'>
                {
                    userDetail?.rols &&
                    userDetail.rols.map((rol, index) => (
                        <Link
                            key={index}
                            className={`flex justify-center transition ease-in-out border-t-8 border-yellow-400 dark:border-yellow-600 rounded-lg ${rol.nombre === currentRol?.nombre ? 'dark:bg-gray-600 bg-gray-400' : 'bg-white dark:bg-gray-800'} dark:hover:bg-gray-700 hover:bg-gray-100 dark:hover:border-red-700 hover:border-red-500`}
                            to={`rol/${rol.nombre}`}
                        >
                            <div className='flex flex-col items-center py-4 px-3 gap-2 text-center'>
                                <h5 className='mb-1 text-lg font-semibold text-gray-900 dark:text-white'>{rol.etiqueta}</h5>
                                <i className='far fa-file-lines text-[80px] text-gray-600 dark:text-gray-300'></i>
                            </div>
                        </Link>
                    ))
                }
            </div> */}
            <div className='flex items-center justify-center h-48 mb-4 rounded bg-white dark:bg-gray-800'>
                <p className='text-2xl text-gray-400 dark:text-gray-500'>+</p>
            </div>
        </>
    )
}