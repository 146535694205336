import React from 'react'
import { LoaderTable } from './LoaderTable'

export const TableSection = ({ stateData, children, paginator, data }) => {
    return (
        <>
            {paginator}
            <div className='relative'>
                <div className='grid grid-cols-1 overflow-auto relative border-2 border-gray-600 dark:border-gray-800 text-white'>
                    {children}
                </div>
                {
                    paginator &&
                    <LoaderTable
                        stateData={stateData}
                        data={data}
                    />
                }
            </div>
            {paginator}
        </>
    )
}
