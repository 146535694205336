import * as Yup from 'yup';

export const UpdateValues = (data) => {
    return {
        initialValues: {
            id: data?.id || '',
            nombre: data?.nombre || '',
            departamento_id: data?.departamento_id || '',
            encargado_id: data?.encargado_id || '',
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(5, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre del almacen',
                name: 'nombre',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Buscar usuario por departamento',
                name: 'departamento_id',
                type: 'doubleSelectApi',
                required: false,
                optionDescription: 'nombre',
                urlApi: '/departamentos',
                sub_name: 'encargado_id',
                sub_label: 'Seleccionar usuario',
                sub_urlApi: '/departamento/{param}/users',
                sub_optionDescription: 'etiqueta',
            },
        ]
    }
}

export const CreateValues = (almacen_producto_id, ingreso = true, tipo = 'Ingreso directo') => {
    return {
        initialValues: {
            almacen_producto_id: almacen_producto_id || '',
            cantidad: 0,
            // user_id: '',
            ingreso: ingreso,
            tipo: tipo,
            tranportista_id: ''
        },
        fieldsValidation: Yup.object().shape({
            // user_id: Yup.number()
            //     .required('Campo requerido'),
            // departamento_id: Yup.number()
            //     .required('Campo requerido'),
            cantidad: Yup.number()
                .required('Campo requerido')
                .positive('La cantidad debe ser mayor que 0')
                .moreThan(0, 'La cantidad debe ser mayor que 0'),
        }),
        fields: [
            // {
            //     label: 'Buscar usuario por departamento',
            //     name: 'departamento_id',
            //     type: 'doubleSelectApi',
            //     required: false,
            //     optionDescription: 'nombre',
            //     urlApi: '/departamentos',
            //     sub_name: 'user_id',
            //     sub_label: 'Seleccionar usuario',
            //     sub_urlApi: '/departamento/{param}/users',
            //     sub_optionDescription: 'etiqueta',
            // },
            {
                label: 'Cantidad',
                name: 'cantidad',
                type: 'number',
                required: false,
            },
            {
                label: 'Concepto',
                name: 'tipo',
                type: 'selectSearch',
                urlApi: `/nombreEstado/Conceptos de ${ingreso ? 'ingreso' : 'egreso'} en almacén/subEstados`,
                required: false,
                labelDescription: 'nombre',
                labelId: 'nombre',
                placeholder: 'Concepto...',
                autoFocus: false,
                defaultValue: ingreso ? { value: 'Ingreso directo', label: 'Ingreso directo' } : { value: 'Egreso directo', label: 'Egreso directo' }
            },
            {
                label: 'Transportista',
                name: 'transportista_id',
                type: 'selectSearch',
                urlApi: '/users/rol/transportista',
                required: false,
                labelDescription: 'etiqueta',
                placeholder: 'Transportista...',
                autoFocus: false,
            },
            // {
            //     label: '¿Ingreso?',
            //     name: 'ingreso',
            //     type: 'checkbox',
            // },
        ],

    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                type: 'notify',
                text: 'Al eliminar el movimiento se recorreran las cantidades anteriores de cada registro posterior a la fecha del movimiento!'
            }
        ]
    }
}

// traspaso
// export const CreateValuesTraspaso = (almacen_producto_id) => {
//     return {
//         initialValues: {
//             almacen_producto_id: almacen_producto_id || '',
//             destino_almacen_producto_id: '',
//             cantidad: 0,
//             // user_id: '',
//             ingreso: false,
//         },
//         fieldsValidation: Yup.object().shape({
//             // user_id: Yup.number()
//             // .required('Campo requerido'),
//             // departamento_id: Yup.number()
//             // .required('Campo requerido'),
//             cantidad: Yup.number()
//                 .required('Campo requerido')
//                 .positive('La cantidad debe ser mayor que 0')
//                 .moreThan(0, 'La cantidad debe ser mayor que 0'),
//         }),
//         fields: [
//             // {
//             //     label: 'Buscar usuario por departamento',
//             //     name: 'departamento_id',
//             //     type: 'doubleSelectApi',
//             //     required: false,
//             //     urlApi: '/departamentos',
//             //     sub_name: 'user_id',
//             //     sub_label: 'Seleccionar usuario',
//             //     sub_urlApi: '/almacen_producto_estados/{param}/users',
//             // },
//             {
//                 label: 'Seleccionar un producto',
//                 name: 'destino_almacen_producto_id',
//                 type: 'selectApi',
//                 required: true,
//                 urlApi: `/almacen_producto_estados/${almacen_producto_id}/traspaso`,
//                 optionDescription: 'almacen_estado_producto_nombre',
//                 infoTags: [
//                     {
//                         label: 'Producto',
//                         data: 'producto_nombre'
//                     },
//                     {
//                         label: 'Estado',
//                         data: 'estado_nombre'
//                     },
//                     {
//                         label: 'Cantidad total',
//                         data: 'cantidad_total',
//                         mark: true
//                     },
//                 ]
//             },
//             {
//                 label: 'Cantidad',
//                 name: 'cantidad',
//                 type: 'number',
//                 required: false,
//             },
//             {
//                 label: '¿Ingreso?',
//                 name: 'ingreso',
//                 type: 'checkbox',
//             },
//         ],

//     }
// }

export const CreateValuesTraspasoExterno = (almacen_producto_id) => {
    return {
        initialValues: {
            almacen_producto_id: almacen_producto_id || '',
            almacen_id: '',
            cantidad: 0,
            // user_id: '',
            ingreso: false,
        },
        fieldsValidation: Yup.object().shape({
            // user_id: Yup.number()
            // .required('Campo requerido'),
            // departamento_id: Yup.number()
            // .required('Campo requerido'),
            cantidad: Yup.number()
                .required('Campo requerido')
                .positive('La cantidad debe ser mayor que 0')
                .moreThan(0, 'La cantidad debe ser mayor que 0'),
        }),
        fields: [
            {
                label: 'Seleccionar un almacén',
                name: 'almacen_id',
                type: 'doubleSelectApi',
                required: true,
                urlApi: `/almacen_producto_estados/${almacen_producto_id}/almacenes`,
                optionDescription: 'nombre',
                sub_name: 'destino_almacen_producto_id',
                sub_label: 'Seleccionar un producto',
                sub_urlApi: `/almacen/{param}/producto_estado/${almacen_producto_id}`,
                sub_optionDescription: 'almacen_estado_producto_nombre',
                sub_infoTags: [
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Estado',
                        data: 'estado_nombre'
                    },
                    {
                        label: 'Cantidad total',
                        data: 'cantidad_total',
                        mark: true
                    },
                ]
            },
            {
                label: 'Cantidad',
                name: 'cantidad',
                type: 'number',
                required: false,
            },
            {
                label: '¿Ingreso?',
                name: 'ingreso',
                type: 'checkbox',
            },
        ],

    }
}

export const CreateValuesTraspasosExterno = (almacen_producto_id, data, isPersonal, isIngress) => {
    return {
        initialValues: {
            almacen_producto_id: almacen_producto_id || '',
            tipo: isPersonal ? 'Cargar carro' : '',
            ingreso: isIngress ? true : false,
            dataList: [],
            // cantidad: 0,
            // user_id: '',
        },
        fieldsValidation: Yup.object().shape({
            tipo: Yup.string()
                .required('Campo requerido'),
            // departamento_id: Yup.number()
            // dataList: Yup.number()
            //     .required('Campo requerido'),
            // cantidad: Yup.number()
            // .required('Campo requerido')
            // .positive('La cantidad debe ser mayor que 0')
            // .moreThan(0, 'La cantidad debe ser mayor que 0'),
        }),
        fields: [
            {
                label: 'Concepto',
                name: 'tipo',
                type: 'selectSearch',
                urlApi: isIngress ? `/nombreEstado/Conceptos de ingreso en almacén/subEstados` : `/nombreEstado/Conceptos de egreso en almacén/subEstados`,
                required: false,
                labelDescription: 'nombre',
                labelId: 'nombre',
                placeholder: 'Concepto...',
                autoFocus: false,
                defaultValue: isPersonal && !isIngress && { value: 'Cargar carro', label: 'Cargar carro' }
            },
            {
                label: 'Seleccionar usuarios',
                name: 'users',
                type: 'almacenTraspasosForm',
                required: false,
                urlApi: `/almacenProducto/${almacen_producto_id}/${isPersonal ? 'personalExternos' : 'externos'}`,
                indexLabel: 'etiqueta',
                generalData: data,
                title: 'Distribución de almacén',
                isIngress: isIngress
            },
        ],

    }
}

export const CreateValuesDescargaDeExternos = (almacen_producto_id, data) => {
    return {
        initialValues: {
            almacen_producto_id: almacen_producto_id || '',
            transaccion_estado_nombre: 'Descarga de carro',
            dataList: []
            // cantidad: 0,
            // user_id: '',
            // ingreso: false,
        },
        fieldsValidation: Yup.object().shape({
            // user_id: Yup.number()
            // .required('Campo requerido'),
            // departamento_id: Yup.number()
            // dataList: Yup.number()
            //     .required('Campo requerido'),
            // cantidad: Yup.number()
            // .required('Campo requerido')
            // .positive('La cantidad debe ser mayor que 0')
            // .moreThan(0, 'La cantidad debe ser mayor que 0'),
        }),
        fields: [
            {
                label: 'Concepto',
                name: 'transaccion_estado_nombre',
                type: 'selectSearch',
                urlApi: `/nombreEstado/Conceptos de ingreso en almacén/subEstados`,
                required: false,
                labelDescription: 'nombre',
                labelId: 'nombre',
                placeholder: 'Concepto...',
                autoFocus: false,
                defaultValue: { value: 'Descarga de carro', label: 'Descarga de carro' }
            },
            {
                label: 'Seleccionar usuarios',
                name: 'users',
                type: 'almacenTraspasosIngresosForm',
                required: false,
                urlApi: `/almacenProducto/${almacen_producto_id}/descargas`,
                indexLabel: 'etiqueta',
                generalData: data,
                title: 'Distribución de almacén'
            },
        ]
    }
}

export const CreateValuesTraspasosInternos = (almProductoId, data, tipo, isDistrib, isVaciado) => {
    return {
        initialValues: {
            almProductoId: almProductoId || '',
            tipo: tipo,
            user_id: '',
            ingreso: false,
            isVaciado: isVaciado,
            dataList: []
            // cantidad: 0,
        },
        fieldsValidation: Yup.object().shape({
            // user_id: Yup.number()
            // .required('Campo requerido'),
            // departamento_id: Yup.number()
            // dataList: Yup.number()
            //     .required('Campo requerido'),
            // cantidad: Yup.number()
            // .required('Campo requerido')
            // .positive('La cantidad debe ser mayor que 0')
            // .moreThan(0, 'La cantidad debe ser mayor que 0'),
        }),
        fields: [
            {
                label: 'Vendedor encargado',
                name: 'user_id',
                type: 'selectSearch',
                urlApi: `/vendedores`,
                required: false,
                labelDescription: 'nombre',
                placeholder: 'Vendedor...',
                autoFocus: false,
                // defaultValue: { value: '2', label: 'Clientes' }
            },
            // {
            //     label: 'Seleccionar usuarios',
            //     name: 'users',
            //     type: 'almacenTraspasosIternosForm',
            //     required: false,
            //     urlApi: `/almProductos/${almProductoId}/internals`,
            //     indexLabel: 'etiqueta',
            //     generalData: data,
            //     title: 'Distribución de almacén'
            // },
            {
                label: 'Seleccionar usuarios',
                name: 'users',
                type: 'almacenTraspasosForm',
                required: false,
                indexLabel: 'tipoProducto',
                generalData: data,
                title: 'Distribución de almacén',
                ...(
                    isDistrib ? {
                        urlApi: `/almacenProducto/${almProductoId}/internos?tipo=distrib`,
                        isDoubleAmount: true
                    }
                        :
                        isVaciado ?
                            {
                                urlApi: `/almacenProducto/${almProductoId}/internos?tipo=vaciado`,
                                isDoubleAmount: true,

                            }
                            :
                            {
                                urlApi: `/almacenProducto/${almProductoId}/internos`,
                                isDoubleAmount: false
                            }
                )
            },
        ],

    }
}

export const VentaMoverFecha = (data, user_id) => {
    return {
        initialValues: {
            almacen_historial_id: data.id,
            createdAt: data.createdAt
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Fecha',
                name: 'createdAt',
                type: 'datetime',
                required: true,
                placeholder: 'Fecha...',
                autoFocus: false
            },
            {
                type: 'notify',
                text: 'Al editar la fecha se recorrerán las cantidades anteriores de cada registro posterior a la fecha más antigua!'
            }
        ]
    }
}

export const AHEditar = (currentData, fields) => {
    return {
        initialValues: {
            almacen_historial_id: currentData.id,
            obs: currentData.obs ?? '',
            nro_boleta: currentData.nro_boleta ?? ''
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: fields
    }
}