import * as Yup from 'yup';
import { NumberIntegerMinOneRequired, NumberIntegerRequired, NumberRequired, StringRequired, ToTwoDecimal } from '../utils/dataValidations';

const sharedFieldsVenta1 = ({ disabled, urlApiAlmacen, user_id, tipo }) => {
    return [
        {
            label: 'Seleccione producto de almacén',
            name: 'almacen_producto_id',
            type: 'selectSearchDetailCard',
            urlApi: urlApiAlmacen,
            required: true,
            disabled: disabled != null ? disabled : false,
            labelDescription: 'etiqueta',
            placeholder: 'Producto...',
            autoFocus: true,
            nameNumber: 'cantidadAlmacen',
            labelNumberRestante: 'Restante',
            nameNumberRestante: 'cantidad_total_restante',
            alterField: 'cantidadTotal',
            exclamationDefault: 'Seleccione un producto!',
            exclamationRestante: 'Stock insuficiente!',
            infoTags: [
                {
                    label: 'Producto',
                    data: 'producto_nombre'
                },
                {
                    label: 'Estado',
                    data: 'estado_nombre'
                },
                {
                    label: 'En almacén',
                    data: 'cantidadAlmacen',
                    mark: true
                },
            ]
        },
        {
            label: tipo === 'venta-retornable-oficina' || tipo === 'venta-no-retornable-oficina' ? 'Empresa/Mayorista' : 'Cliente',
            name: 'user_id',
            type: 'selectSearch',
            urlApi: `/vendedor/${user_id}/clientes?tag=${tipo === 'venta-retornable-oficina' || tipo === 'venta-no-retornable-oficina' ? 'cliente-mayor' : 'cliente-menor'}`,
            required: true,
            disabled: disabled != null ? disabled : false,
            labelDescription: 'etiqueta',
            placeholder: 'Cliente...',
            autoFocus: false,
        },
        {
            label: 'Precio unitario',
            name: 'precio_unitario',
            type: 'groupnumberCalculator',
            subData: 'Bs.',
            required: true,
            disabled: disabled != null ? disabled : false,
            ChangeCalculator: (values, setValues, e) => {
                const precio_unitario = e.target.valueAsNumber || 0;
                const cantidad = values.cantidad || 0;
                const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                setValues('venta_total', venta_total)

                const efectivo_total = values.efectivo_total || 0;
                const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                setValues('deuda_total', deuda_total)
            }
        },
        {
            label: 'Cantidad',
            name: 'cantidad',
            type: 'numberCalculator',
            subData: null,
            required: true,
            disabled: disabled != null ? disabled : false,
            locked: false,
            ChangeCalculator: (values, setValues, e) => {
                const cantidad = e.target.valueAsNumber || 0;
                const precio_unitario = values.precio_unitario || 0;
                const venta_total = ToTwoDecimal(precio_unitario * cantidad);
                setValues('venta_total', venta_total)

                const efectivo_total = values.efectivo_total || 0;
                const deuda_total = ToTwoDecimal(venta_total - efectivo_total)
                setValues('deuda_total', deuda_total)

                const cantidad_retorno = values.cantidad_retorno || 0
                const cantidad_deuda = cantidad - cantidad_retorno
                setValues('cantidad_deuda', cantidad_deuda)

                const cantidad_total = values.cantidadAlmacen || 0
                const total = ToTwoDecimal(cantidad_total - cantidad)
                setValues('cantidad_total_restante', total < 0 ? 0 : total)
            }
        },
    ]
}
const sharedFieldsVenta2 = ({ disabled, tipo }) => {
    return [
        {
            label: 'Método de pago',
            name: 'metodo_pago',
            type: 'select',
            required: true,
            disabled: disabled != null ? disabled : false,
            options: [
                {
                    label: 'Efectivo',
                    value: 'efectivo'
                },
                {
                    label: 'Transferencia',
                    value: 'transferencia'
                },
                {
                    label: 'Cheque',
                    value: 'cheque'
                },
                {
                    label: 'Combinado E/T',
                    value: 'combinado'
                },
            ],
        },
        {
            label: 'Total venta',
            name: 'venta_total',
            type: 'groupnumber',
            subData: 'Bs.',
            required: true,
            disabled: true,
            highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
        },
        {
            label: 'En efectivo',
            name: 'efectivo_total',
            type: 'groupnumberCalculator',
            subData: 'Bs.',
            required: true,
            disabled: disabled != null ? disabled : false,
            ChangeCalculator: (values, setValues, e) => {
                const efectivo_total = e.target.valueAsNumber || 0;
                const transferencia_total = values.transferencia_total || 0;
                const venta_total = values.venta_total || 0
                const deuda_total = ToTwoDecimal(venta_total - efectivo_total - transferencia_total)
                setValues('deuda_total', deuda_total)
            },
            ChangeCondition: (values, setValues, setAditionalClass) => {
                setValues('efectivo_total', 0)
                setValues('transferencia_total', 0)
                setValues('deuda_total', values.venta_total)
                if (!['efectivo', 'combinado'].includes(values.metodo_pago)) {
                    setAditionalClass('hidden')
                }
                else {
                    setAditionalClass('')
                }
            },
            fieldDependency: 'metodo_pago',
            highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
        },
        {
            label: 'Por transferencia',
            name: 'transferencia_total',
            type: 'groupnumberCalculator',
            subData: 'Bs.',
            required: true,
            disabled: disabled != null ? disabled : false,
            ChangeCalculator: (values, setValues, e) => {
                const transferencia_total = e.target.valueAsNumber || 0;
                const efectivo_total = values.efectivo_total || 0;
                const venta_total = values.venta_total || 0
                const deuda_total = ToTwoDecimal(venta_total - transferencia_total - efectivo_total)
                setValues('deuda_total', deuda_total)
            },
            ChangeCondition: (values, setValues, setAditionalClass) => {
                setValues('efectivo_total', 0)
                setValues('transferencia_total', 0)
                setValues('deuda_total', values.venta_total)
                if (['transferencia', 'cheque', 'combinado'].includes(values.metodo_pago)) {
                    setAditionalClass('')
                }
                else {
                    setAditionalClass('hidden')
                }
            },
            fieldDependency: 'metodo_pago',
            highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
        },
        {
            label: 'Saldo',
            name: 'deuda_total',
            type: 'groupnumber',
            subData: 'Bs.',
            required: true,
            disabled: true,
            highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
        },
        {
            label: 'Fecha estimada de cobro',
            name: 'fecha_estimada_cobro',
            type: 'date',
            required: false,
            disabled: disabled != null ? disabled : false,
            ChangeCondition: (values, setValues, setAditionalClass) => {
                if (values.deuda_total > 0)
                    setAditionalClass('')
                else
                    setAditionalClass('hidden')
            },
            fieldDependency: 'deuda_total',
            highlight: 'bg-gray-600 dark:bg-gray-500 bg-opacity-25 dark:bg-opacity-20'
        },
    ]
}

const sharedEncargadoObs = ({ tipo }) => {
    return [
        {
            type: 'divider'
        },
        tipo === 'venta-retornable-oficina' || tipo === 'venta-no-retornable-oficina' ?
            {
                label: 'Encargado de entrega',
                name: 'encargado_id',
                type: 'selectSearch',
                urlApi: `/vendedores`,
                required: false,
                labelDescription: 'nombre',
                placeholder: 'Encargado...',
                autoFocus: false,
            }
            :
            {},
        // ,
        tipo === 'venta-retornable-oficina' || tipo === 'venta-no-retornable-oficina' || tipo === 'oficina' ?
            {
                label: 'Nro. Boletah',
                name: 'nro_boleta',
                type: 'text',
                required: false,
                placeholder: '#Nro...',
                autoFocus: false
            }
            :
            {}
        ,
        tipo === 'venta-retornable-oficina' || tipo === 'venta-no-retornable-oficina' || tipo === 'oficina' ?
            {
                type: 'divider'
            }
            :
            {}
        ,
        {
            label: 'Observaciones',
            name: 'obs',
            type: 'textArea',
            required: false,
            placeholder: 'Observaciones...',
            autoFocus: false
        },
    ]
}
const sharedInitialValuesVenta = ({ cuaderno_id, tipo }) => {
    return {
        cuaderno_id: cuaderno_id,
        almacen_producto_id: '',
        cantidadTotal: 0,
        cantidad_total_restante: 0,
        user_id: '',
        tipo: tipo,
        precio_unitario: 0,
        cantidad: 0,
        metodo_intercambio: 'normal',
        metodo_pago: 'efectivo',
        venta_total: 0,
        efectivo_total: 0,
        deuda_total: 0,
        transferencia_total: 0,
        fecha_estimada_cobro: '',
        obs: '',
        // 
        encargado_id: '',
        nro_boleta: '',
    }
}

export const VentaRetornableValues = (cuaderno_id, user_id, tipo, disabled = null) => {
    return {
        initialValues: {
            ...sharedInitialValuesVenta({ cuaderno_id, tipo }),
            cantidad_retorno: 0,
            cantidad_deuda: 0,
            metodo_pago_garantia: 'efectivo',
            monto_garantia: 0,
            fecha_estimada_devolucion: '',
        },
        fieldsValidation: Yup.object().shape({
            almacen_producto_id: StringRequired,
            cantidad_deuda: NumberIntegerRequired,
            precio_unitario: NumberRequired,
            cantidad: NumberIntegerMinOneRequired,
            cantidad_retorno: NumberIntegerRequired,
        }),
        fields: [
            ...sharedFieldsVenta1({ disabled, urlApiAlmacen: `/user/${user_id}/almacenes/retornable`, user_id, tipo }),
            {
                label: 'Cantidad recojidos',
                name: 'cantidad_retorno',
                type: 'numberCalculator',
                subData: null,
                required: true,
                disabled: disabled != null ? disabled : false,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad_retorno = e.target.valueAsNumber || 0;
                    const cantidad = values.cantidad || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)
                },
            },
            {
                label: 'Cantidad deuda',
                name: 'cantidad_deuda',
                type: 'number',
                subData: null,
                required: true,
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20',
                locked: true,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    const cantidad_retorno = values.cantidad_retorno || 0
                    const cantidad = values.cantidad || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)
                },
                fieldDependency: 'cantidad',
            },
            {
                label: 'Garantía / Prestamo',
                name: 'metodo_intercambio',
                type: 'selectCondition',
                required: true,
                disabled: disabled != null ? disabled : false,
                options: [
                    {
                        label: 'Normal',
                        value: 'normal',
                        disabled: (values) => {
                            if (values.cantidad_deuda > 0)
                                return true
                        }
                    },
                    {
                        label: 'Garantía',
                        value: 'garantía',
                        disabled: (values) => {
                            if (values.cantidad_deuda <= 0)
                                return true
                        }
                    },
                    {
                        label: 'Prestamo',
                        value: 'préstamo',
                        disabled: (values) => {
                            if (values.cantidad_deuda <= 0)
                                return true
                        }
                    },
                ],
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.cantidad_deuda > 0 && values.metodo_intercambio === 'normal')
                        setValues('metodo_intercambio', 'garantía')
                    if (values.cantidad_deuda <= 0 && values.metodo_intercambio !== 'normal')
                        setValues('metodo_intercambio', 'normal')

                    if (values.cantidad_deuda <= 0 && values.metodo_intercambio === 'normal')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'cantidad_deuda',
                fieldDependencyExtra: 'metodo_intercambio',
            },
            {
                label: 'Método de pago de garantía',
                name: 'metodo_pago_garantia',
                type: 'select',
                required: true,
                disabled: disabled != null ? disabled : false,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    {
                        label: 'Transferencia',
                        value: 'transferencia'
                    },
                    {
                        label: 'Cheque',
                        value: 'cheque'
                    }
                ],
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_intercambio === 'garantía')
                        setAditionalClass('')
                    else
                        setAditionalClass('hidden')
                },
                fieldDependency: 'metodo_intercambio',
                highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto de garantía',
                name: 'monto_garantia',
                type: 'groupnumber',
                subData: 'Bs.',
                required: false,
                disabled: disabled != null ? disabled : false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_intercambio === 'garantía')
                        setAditionalClass('')
                    else
                        setAditionalClass('hidden')
                },
                fieldDependency: 'metodo_intercambio',
                highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Fecha estimada de devolución',
                name: 'fecha_estimada_devolucion',
                type: 'date',
                disabled: disabled != null ? disabled : false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_intercambio === 'préstamo')
                        setAditionalClass('')
                    else
                        setAditionalClass('hidden')
                },
                fieldDependency: 'metodo_intercambio',
                highlight: 'bg-cyan-600 dark:bg-cyan-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                type: 'divider',
            },
            ...sharedFieldsVenta2({ tipo }),
            ...sharedEncargadoObs({ tipo })
        ]
    }
}

export const VentaNoRetornableValues = (cuaderno_id, user_id, tipo, disabled = null) => {
    return {
        initialValues: {
            ...sharedInitialValuesVenta({ cuaderno_id, tipo }),
        },
        fieldsValidation: Yup.object().shape({
            almacen_producto_id: StringRequired,
            precio_unitario: NumberRequired,
            cantidad: NumberIntegerMinOneRequired,
        }),
        fields: [
            ...sharedFieldsVenta1({ disabled, urlApiAlmacen: `/user/${user_id}/almacenes/no-retornable`, user_id, tipo }),
            ...sharedFieldsVenta2({ tipo }),
            ...sharedEncargadoObs({ tipo })
        ]
    }
}

export const GarantiaDirectaValues = (cuaderno_id, user_id, disabled = null) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            almacen_producto_id: '',
            user_id: '',
            cantidad_deuda: 0,
            metodo_pago_garantia: 'efectivo',
            monto_garantia: 0,
            cantidadTotal: 0,
            cantidad_total_restante: 0,
        },
        // fieldsValidation: Yup.object().shape({
        // }),
        fields: [
            {
                label: 'Seleccione producto de almacén',
                name: 'almacen_producto_id',
                type: 'selectSearchDetailCard',
                urlApi: `/user/${user_id}/almacenes/vacios`,
                required: true,
                disabled: disabled != null ? disabled : false,
                labelDescription: 'etiqueta',
                placeholder: 'Producto...',
                autoFocus: true,
                nameNumber: 'cantidadAlmacen',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'cantidad_total_restante',
                alterField: 'cantidadTotal', // referencia de cantidadAlmacen
                exclamationDefault: 'Seleccione un producto!',
                // exclamationRestante: 'Stock insuficiente!',
                infoTags: [
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Estado',
                        data: 'estado_nombre'
                    },
                    {
                        label: 'En almacén',
                        data: 'cantidadAlmacen',
                        mark: true
                    },
                ]
            },
            {
                label: 'Cliente',
                name: 'user_id',
                type: 'selectSearch',
                urlApi: `/vendedor/${user_id}/clientes`,
                required: true,
                disabled: disabled != null ? disabled : false,
                labelDescription: 'etiqueta',
                placeholder: 'Cliente...',
                autoFocus: false,
            },
            {
                label: 'Cantidad deuda',
                name: 'cantidad_deuda',
                type: 'number',
                subData: null,
                required: true,
                disabled: false,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20',
                locked: false,
                // ChangeCalculator: (values, setValues, e) => {
                //     const cantidad_deuda = e.target.valueAsNumber || 0;
                //     const cantidadAlmacen = values.cantidadAlmacen || 0;
                //     const cantidadRestante = ToTwoDecimal(cantidadAlmacen + cantidad_deuda)
                //     setValues('cantidad_total_restante', cantidadRestante)
                // },
            },
            {
                label: 'Método de pago de garantía',
                name: 'metodo_pago_garantia',
                type: 'select',
                required: true,
                disabled: disabled != null ? disabled : false,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    {
                        label: 'Transferencia',
                        value: 'transferencia'
                    },
                    {
                        label: 'Cheque',
                        value: 'cheque'
                    }
                ],
                highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto de garantía',
                name: 'monto_garantia',
                type: 'groupnumber',
                subData: 'Bs.',
                required: false,
                disabled: disabled != null ? disabled : false,
                highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
            },
        ]
    }
}

export const ResetValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}

export const GastoValues = (cuaderno_id) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            estado_gasto_id: '',
            monto: 0,
            obs: '',

        },
        fieldsValidation: Yup.object().shape({
            estado_gasto_id: StringRequired,
            monto: NumberRequired,
        }),
        fields: [
            {
                label: 'Tipo de gasto',
                name: 'estado_gasto_id',
                type: 'selectSearch',
                urlApi: '/nombreEstado/Gastos vendedores/subEstados',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Tipo de gasto...',
                autoFocus: false,
                // defaultValue: { value: '1', label: 'Clientes' }
            },
            {
                label: 'Monto del Gasto',
                name: 'monto',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,

            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'text',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const DevolucionDePrestamosValues = (cuaderno_id, user_id, tipo) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            deuda_envase_id: '',
            saldo: 0,
            saldo_restante: 0,
            cantidad: 0,
            obs: '',
            tipo: tipo,
            encargado_id: '',
            nro_boleta: ''
        },
        fieldsValidation: Yup.object().shape({
            cantidad: NumberIntegerMinOneRequired,
            saldo_restante: NumberIntegerRequired,
        }),
        fields: [
            {
                label: 'Seleccion un usuario',
                name: 'deuda_envase_id',
                type: 'selectSearchDetailCard',
                // param: 'user_id',
                urlApi: `/user/${user_id}/prestamos?tagFilter=${tipo}`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: null,
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'envaseDeudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Fecha límite',
                        data: 'fecha_limite',
                        isDateValue: true
                    },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Envases devueltos',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                }
            },
            {
                label: 'Envases restantes',
                name: 'saldo_restante',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: true,
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            ...sharedEncargadoObs({ tipo })
        ]
    }
}

export const DevolucionDeGarantiasValues = (cuaderno_id, user_id, tipo) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            deuda_envase_id: '',
            metodo_pago: 'efectivo',
            saldo: 0,
            saldo_restante: 0,
            cantidad: 0,
            cantidadTotal: 0,
            monto: 0,
            monto_garantia: 0,
            monto_restante: 0,
            obs: '',
            tipo: tipo,
            encargado_id: '',
            nro_boleta: ''
        },
        fieldsValidation: Yup.object().shape({
            cantidad: NumberIntegerRequired,
            saldo_restante: NumberIntegerRequired,
        }),
        fields: [
            {
                label: 'Seleccion cliente',
                name: 'deuda_envase_id',
                type: 'selectSearchDetailCard',
                urlApi: `/user/${user_id}/garantias?tagFilter=${tipo}`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: null,
                setAction: (setValues, data) => {
                    if (data != null) {
                        setValues('monto_garantia', data.monto_garantia)
                        setValues('monto_restante', data.monto_garantia)
                    }
                    else {
                        setValues('monto_garantia', 0)
                        setValues('monto_restante', 0)
                        setValues('monto', 0)
                        setValues('cantidad', 0)
                        setValues('cantidad_restante', 0)
                    }
                },
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'envaseDeudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Monto Garantía Bs.',
                        data: 'monto_garantia',
                        mark: true,
                    },
                    // {
                    //     label: 'Fecha límite:',
                    //     data: 'fecha_limite',
                    //     isDateValue: true
                    // },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    {
                        label: 'Transferencia',
                        value: 'transferencia'
                    },
                    {
                        label: 'Cheque',
                        value: 'cheque'
                    },
                ],
            },
            {
                label: 'Garantía',
                name: 'monto_garantia',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto_garantia = e.target.valueAsNumber || 0;
                    const monto = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto devuelto',
                name: 'monto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    // if (values.metodo_pago !== 'efectivo')
                    //     setAditionalClass('hidden')
                    // else
                    //     setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Garantía restante',
                name: 'monto_restante',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('monto_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    // if (values.metodo_pago !== 'efectivo')
                    //     setAditionalClass('hidden')
                    // else
                    //     setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
                fieldDependencyExtra: 'monto_garantia',
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Envases recogidos',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    // if (values.metodo_pago !== 'efectivo')
                    //     setAditionalClass('hidden')
                    // else
                    //     setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Envases restantes',
                name: 'saldo_restante',
                type: 'numberCalculator',
                subData: null,
                required: true,
                locked: true,
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0;
                    const saldo_restante = saldo - cantidad
                    setValues('saldo_restante', saldo_restante)
                },
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            ...sharedEncargadoObs({ tipo })
        ]
    }
}

export const CobrosValues = (cuaderno_id, user_id) => {
    return {
        initialValues: {
            cuaderno_id: cuaderno_id,
            deuda_id: '',
            metodo_pago: 'efectivo',
            saldo: 0,
            saldo_restante: 0,
            monto: 0,
            obs: '',
        },
        fieldsValidation: Yup.object().shape({
            cantidad: NumberIntegerRequired,
            saldo_restante: NumberIntegerRequired,
        }),
        fields: [
            {
                label: 'Seleccion un usuario',
                name: 'deuda_id',
                type: 'selectSearchDetailCard',
                urlApi: `/user/${user_id}/deudas`,
                required: true,
                labelDescription: 'etiqueta',
                placeholder: 'Deudor...',
                autoFocus: true,
                nameNumber: 'saldo',
                labelNumberRestante: 'Restante',
                nameNumberRestante: 'saldo_restante',
                alterField: 'cantidad',
                exclamationDefault: 'Seleccione un deudor!',
                exclamationRestante: 'El resto debe ser mayor o igual a 0!',
                setAction: (setValues, data) => {
                    if (data != null) {
                        setValues('monto_garantia', data.monto_garantia)
                        setValues('monto_restante', data.monto_garantia)
                    }
                    else {
                        setValues('monto_garantia', 0)
                        setValues('monto_restante', 0)
                        setValues('monto', 0)
                        setValues('cantidad', 0)
                        setValues('cantidad_restante', 0)
                    }
                },
                infoTags: [
                    {
                        label: 'Deudor',
                        data: 'deudorDetalles'
                    },
                    {
                        label: 'Producto',
                        data: 'producto_nombre'
                    },
                    {
                        label: 'Fecha',
                        data: 'createdAt',
                        isDateValue: true
                    },
                    {
                        label: 'Saldo',
                        data: 'saldo',
                        mark: true
                    },
                ]
            },
            {
                label: 'Método de pago',
                name: 'metodo_pago',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Efectivo',
                        value: 'efectivo'
                    },
                    {
                        label: 'Transferencia',
                        value: 'transferencia'
                    },
                    {
                        label: 'Cheque',
                        value: 'cheque'
                    },
                ],
            },
            {
                label: 'Saldo',
                name: 'saldo',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const saldo = e.target.valueAsNumber || 0;
                    const monto = values.saldo || 0
                    const monto_restante = saldo - monto
                    setValues('saldo_restante', monto_restante)
                },
                highlight: 'bg-green-600 dark:bg-green-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Monto devuelto',
                name: 'monto',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: false,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const saldo = values.saldo || 0
                    const monto_restante = saldo - monto
                    setValues('saldo_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
            },
            {
                label: 'Saldo restante',
                name: 'saldo_restante',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monto = e.target.valueAsNumber || 0;
                    const monto_garantia = values.monto_garantia || 0
                    const monto_restante = monto_garantia - monto
                    setValues('saldo_restante', monto_restante)
                },
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_pago !== 'efectivo')
                        setAditionalClass('hidden')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'metodo_pago',
                fieldDependencyExtra: 'monto',
                highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            },
            // {
            //     label: 'Envases recogidos',
            //     name: 'cantidad',
            //     type: 'numberCalculator',
            //     subData: null,
            //     required: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         const cantidad = e.target.valueAsNumber || 0;
            //         const saldo = values.saldo || 0;
            //         const saldo_restante = saldo - cantidad
            //         setValues('saldo_restante', saldo_restante)
            //     },
            //     ChangeCondition: (values, setValues, setAditionalClass) => {
            //         if (values.metodo_pago !== 'efectivo')
            //             setAditionalClass('hidden')
            //         else
            //             setAditionalClass('')
            //     },
            //     fieldDependency: 'metodo_pago',
            // },
            // {
            //     label: 'Envases restantes',
            //     name: 'saldo_restante',
            //     type: 'numberCalculator',
            //     subData: null,
            //     required: true,
            //     locked: true,
            //     disabled: true,
            //     ChangeCalculator: (values, setValues, e) => {
            //         const cantidad = e.target.valueAsNumber || 0;
            //         const saldo = values.saldo || 0;
            //         const saldo_restante = saldo - cantidad
            //         setValues('saldo_restante', saldo_restante)
            //     },
            //     highlight: 'bg-red-600 dark:bg-red-500 bg-opacity-25 dark:bg-opacity-20'
            // },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}

export const VentaCambiarFecha = (venta, user_id) => {
    return {
        initialValues: {
            cuaderno_id: venta.cuaderno_id,
            createdAt: venta.createdAt
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Fecha',
                name: 'createdAt',
                type: 'datetime',
                required: true,
                placeholder: 'Fecha...',
                autoFocus: false
            }
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}