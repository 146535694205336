import { useEffect, useState } from 'react'
import { requestAuth } from '../../http/httpRequest'
import { formatFilters } from '../../utils/defaulStates'

export const FilterSelect = ({
    paginate,
    setPaginate,
    options,
    name,
}) => {
    const [selectWidth, setSelectWidth] = useState('auto')
    const [valueOption, setValueOption] = useState('')

    const handleSelectChange = (event) => {
        const selectedOption = options.find(opt => opt.value === event.target.value)
        const optionText = selectedOption ? selectedOption.label : ''
        const optionTextWidth = getTextWidth(optionText)

        setSelectWidth(`${optionTextWidth + 55}px`)
    }

    const getTextWidth = (text) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.font = '14px sans-serif'
        return context.measureText(text).width
    }
    return (
        <>

            <select
                as='select'
                name={name}
                className={`py-1 ${valueOption === '' ? 'bg-transparent dark:bg-gray-900 text-gray-800 dark:text-gray-400' : 'bg-white dark:bg-gray-700 dark:text-gray-200 text-gray-800'} outline-none border border-gray-300 text-sm rounded-lg dark:focus:border-gray-400 focus:border-gray-300 focus:ring-0 dark:border-gray-700 dark:placeholder-gray-400 dark:focus:ring-0`}
                onChange={(event) => {
                    handleSelectChange(event)
                    formatFilters(event.target.name, event.target.value, paginate, setPaginate)
                    setValueOption(event.target.value)
                }}
                style={{ width: selectWidth }}
            >
                {options?.map((opt, index) => (
                    <option key={index} value={opt.value} className={`${index === 0 ? 'text-gray-400 dark:text-gray-500' : ''}`}>{opt.label}</option>
                ))}
            </select>
        </>
    )
}

export const FilterSelectAsync = ({
    paginate,
    setPaginate,
    // options,
    name,
    formatFilter,
    urlApi,
    indexes,
    defaultLabel
}) => {
    // const [selectWidth, setSelectWidth] = useState('auto')
    const [options, setOptions] = useState([])
    const [valueOption, setValueOption] = useState('')

    // const handleSelectChange = (event) => {
    //     const selectedOption = options.find(opt => opt[indexes.valueIndex] === event.target.value)
    //     const optionText = selectedOption ? selectedOption[indexes.labelIndex] : ''
    //     const optionTextWidth = getTextWidth(optionText)

    //     setSelectWidth(`${optionTextWidth + 55}px`)
    // }

    const getOptions = async () => {
        await requestAuth(
            'get',
            urlApi,
            null,
        ).then((response) => (
            setOptions(response.data)
        ))
    }
    // const getTextWidth = (text) => {
    //     const canvas = document.createElement('canvas')
    //     const context = canvas.getContext('2d')
    //     context.font = '14px sans-serif'
    //     return context.measureText(text).width
    // }

    useEffect(() => {
        getOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <select
            as='select'
            name={name}
            className={`py-1 ${valueOption === '' ? 'dark:bg-gray-800 bg-gray-200 text-gray-800 dark:text-gray-400' : 'bg-white dark:bg-gray-700 dark:text-gray-200 text-gray-800'} outline-none border border-gray-300 text-sm rounded-lg dark:focus:border-gray-400 focus:border-gray-300 focus:ring-0 dark:border-gray-700 dark:placeholder-gray-400 dark:focus:ring-0`}
            onChange={(event) => {
                // handleSelectChange(event)
                formatFilter(event.target.name, event.target.value, paginate, setPaginate)
                setValueOption(event.target.value)
            }}
        // style={{ width: selectWidth }}
        >
            <option value='' className='text-gray-400 dark:text-gray-500'>{defaultLabel}</option>
            {options?.map((opt, index) => (
                <option key={index} value={opt[indexes.valueIndex]}>{opt[indexes.labelIndex]}</option>
            ))}
        </select>
    )
}

export const FilterMultiSelectAsync = ({
    paginate,
    setPaginate,
    options,
    name,
}) => {
    return (
        <>
            <div className='flex flex-row gap-2 text-sm dark:text-gray-300'>
                {
                    options?.map((opt, idx) => (
                        <div
                            key={idx}
                            className={`border  rounded-lg px-2 flex items-center cursor-pointer ${paginate[name] && paginate[name].filter(x => x === opt.value).length > 0 ? 'bg-white dark:bg-gray-900 border-gray-300 dark:border-gray-600' : 'text-gray-400 dark:text-gray-500 border-gray-200 dark:border-gray-800'}`}
                            onClick={() => formatFilters(name, opt.value, paginate, setPaginate, true)}
                        >
                            {opt.label}
                        </div>
                    ))
                }
            </div>
        </>
    )
}