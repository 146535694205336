import React, { useEffect, useState } from 'react'
import { requestAuth } from '../../http/httpRequest'
import { toastOn } from '../../store/slices/toast'
import { Link } from 'react-router-dom'
import { NoteIconSvg } from '../../utils/styleFormat'

const ListCuadernosAbiertosPanel = ({ rols = [], dispatch }) => {
    const [generalData, setGeneralData] = useState({
        Cuadernos: [],
        loading: 'loading'
    })

    const getCuadernosAbiertos = async () => {
        await requestAuth(
            'get',
            `/cuadernos/estado/true`,
            null
        )
            .then((response) => {
                setGeneralData(x => ({ ...x, Cuadernos: response.data, loading: 'loaded' }))
                setGeneralData(x => ({ ...x, loading: 'loaded' }))
            }
            )
            .catch((error) => {
                setGeneralData(x => ({ ...x, loading: 'loaded' }))
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
            })
    }

    useEffect(() => {
        getCuadernosAbiertos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className='my-4'>
                <h1 className='dark:text-white font-semibold text-md'>Cuaderno abiertos</h1>
            </div>
            <div className='grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mb-4'>
                {
                    generalData.loading === 'loading' ?
                        <div className='text-sm dark:text-gray-500'>Cargando...</div>
                        :
                        generalData?.Cuadernos.length > 0 ?
                            generalData?.Cuadernos.map((cuaderno, index) => (
                                <Link
                                    key={index}
                                    className={`flex justify-center transition ease-in-out border-t-8 border-yellow-500 dark:border-yellow-600 rounded-lg bg-white dark:bg-gray-800 dark:hover:bg-gray-700 hover:bg-gray-100 dark:hover:border-red-700 hover:border-red-500 text-gray-800 dark:text-gray-300`}
                                    to={`rol/ventas/menu/cuadernos/historial/${cuaderno.id}/ventas`}
                                >
                                    <div className='flex flex-col items-center py-4 px-3 gap-2 text-center'>
                                        <h5 className='mb-1 text-xl font-bold'>{cuaderno.codigo}</h5>
                                        <span className=''>
                                            <NoteIconSvg />
                                        </span>
                                        {/* <i className='far fa-file-lines text-[80px] text-gray-600 dark:text-gray-300'></i> */}
                                        <span className='text-md font-semibold'>{cuaderno.nombre_completo}</span>
                                        {/* <span className='text-md font-semibold'>{cuaderno.roles}</span> */}
                                    </div>
                                </Link>
                            ))
                            :
                            <div className='text-gray-700 italic'>Todos los cuadernos estan cerrados!</div>
                }
            </div>
        </>
    )
}

export default ListCuadernosAbiertosPanel