import React, { useState } from 'react'
import { LogoTitle } from '../components/link/LogoTitle'
// import logo from '../assets/logo.png'
// import logoWhite from '../assets/logoWhite.png'
import logo from '../assets/logo.png'
import textureImage from '../assets/texture.png'
import { DefaultCard } from '../components/card/DefaultCard'
import { DefaultTitle } from '../components/font/defaultTitle'
import { DefaultAlert } from '../components/alert/DefaultAlert'
import { Form, Formik } from 'formik'
import { Input } from '../components/form/input'
import { DefaultSubmit } from '../components/form/DefaultSubmit'
import { requestAuth } from '../http/httpRequest'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loginOn, saveToken, saveUserDetail } from '../store/slices/auth'
import { toastOn } from '../store/slices/toast/toastSlice'

export const Login = () => {
  const [message, setMessage] = useState(false)
  const [textMessage, setTextMessage] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  return (
    <>
      <div className='absolute top-0 left-0 w-full flex flex-col sm:flex-row items-center z-20 justify-center py-8 mx-auto h-screen lg:py-0 rounded-br-full rounded-tl-full'>
        <div className='flex flex-col sm:flex-row bg-yellow-500 rounded-lg sm:rounded-lg shadow-md shadow-gray-800 dark:shadow-gray-800 min-w-80'>
          {/* <div className='bg-white rounded-l-lg rounded-r-lg sm:rounded-l-lg sm:rounded-r-none shadow dark:border mx-auto '> */}
          <LogoTitle
            to='/login'
            src={logo}
          />
          <DefaultCard>
            <DefaultTitle text='Inicie sesión en su cuenta' />
            <DefaultAlert
              color='red'
              show={message}
              setShow={setMessage}
              text={textMessage}
            />
            <Formik
              initialValues={{
                codigo: '',
                password: '',
              }}

              // validationSchema={loginScheme}

              onSubmit={async (values) => {
                setMessage(false)
                await requestAuth(
                  'post',
                  `/auth/login`,
                  values
                )
                  .then((response) => {
                    dispatch(loginOn())
                    if (response?.data?.user?.id + "" === window.localStorage.getItem('id') && window.localStorage.getItem('location')) {
                      navigate(window.localStorage.getItem('location'))
                    }
                    else {
                      navigate('/')
                      navigate(window.localStorage.setItem('id', response?.data?.user?.id))
                    }

                    dispatch(toastOn({ type: 'success', message: 'Inicio de sesión exitoso!' }))
                    dispatch(saveToken(response.data.token))
                    dispatch(saveUserDetail(response.data.user))
                  }
                  )
                  .catch(error => {
                    setMessage(true)
                    setTextMessage(error?.response?.data?.message || 'Usuario o contraseña incorrectos!')
                  })
              }}
            >
              {({ errors, isSubmitting, touched, }) => (
                <Form className='space-y-2 md:space-y-4'>
                  <Input
                    type='text'
                    name='codigo'
                    id='codigo'
                    placeholder='Código de usuario'
                    required={true}
                    label='Usuario o correo electrónico'
                    errorMessage={errors.codigo && touched.codigo ? (
                      <div className='text-xs mt-1 font-semibold text-red-600 dark:text-red-600'>{errors.codigo}</div>
                    ) : null}
                    autoFocus={true}
                  />
                  <div className='relative'>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      id='password'
                      placeholder='••••••••'
                      required={true}
                      label='Contraseña'
                      errorMessage={errors.password && touched.password ? (
                        <div className='text-xs mt-1 font-semibold text-red-600 dark:text-red-600'>{errors.password}</div>
                      ) : null}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute inset-y-0 right-2 top-6 flex items-center text-gray-500 dark:text-gray-300"
                    >
                      <i className={`fa fa-${showPassword ? 'eye-slash' : 'fa fa-eye'} w-6`} />
                    </button>
                  </div>
                  <div className='flex items-center justify-between'>
                    <div className='flex items-start'>
                    </div>
                    {/* <LinkSimple text={'Forgot password?'} /> */}
                  </div>
                  <DefaultSubmit
                    isloading={isSubmitting}
                    text={'Iniciar sesión'}
                  />
                </Form>
              )}
            </Formik>
            {/* <LinkLabel
          prevLabel='No tienes una cuenta?'
          to='/register'
          label='Registrate!'
        /> */}
          </DefaultCard>
        </div>
        {/* </div> */}
      </div>
      <div
        style={{
          backgroundImage: `url(${textureImage})`,
          backgroundSize: '800px'
        }}
        className='absolute top-0 left-0 z-10 w-screen h-screen opacity-80 dark:opacity-50 dark:bg-gray-600 bg-gray-300'
      >
      </div>
    </>
  )
}
