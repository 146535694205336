import React, { useState } from 'react'
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { ErrorPage } from '../../../error/errorPage';
import { ListEfectivoTable } from './ListEfectivoTable';
import { EfectivoCierresHistorial } from './efectivo_cierres';
import { EfectivoHistorial } from './efectivo_historial';
import CardInfoEfectivo from './elementosGenerales/CardInfo';
import { useSelector } from 'react-redux';
import { BackButton } from '../../../../components/buttons/ButtonDefault';

export const SubEfectivoIndex = ({ globalParams, efectivoParams, reloaders, dispatchs }) => {
    const [data, setData] = useState(null);
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    return (

        <Routes>
            <Route index element={
                <div className='relative'>
                    <BackButton />
                    <CardInfoEfectivo
                        reload={reloadMainTable}
                        data={data}
                        setData={setData}
                        nameLabel='SUB CUENTAS'
                    />
                    <ListEfectivoTable
                        globalParams={globalParams}
                        dataCard={data}
                        efectivoParams={{
                            type: 'subEfectivo'
                        }}
                        reloaders={reloaders}
                        dispatchs={dispatchs}
                    />
                </div>
            } />
            <Route
                path={`/historial/:sub_efectivo_id/*`}
                element={<EfectivoHistorial
                    efectivoParams={{
                        type: 'subEfectivo'
                    }}
                />}
            />
            <Route
                path={`/cierres/:sub_efectivo_id/*`}
                element={<EfectivoCierresHistorial
                    efectivoParams={{
                        type: 'subEfectivo'
                    }}
                    reloaders={reloaders}
                />}
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}