import React from 'react'
import { FilterMultiSelectAsync } from '../form/FilterSelect'
import { useSelector } from 'react-redux'

export const DefaultMultiSelectVendedores = ({ paginate, setPaginate, formatFilters }) => {
    const rols = useSelector(a => a.login.rolsToSelects)
    let options = rols?.filter(r => r.permitir_cuaderno)?.map(r => (
        {
            label: r.etiqueta,
            value: r.nombre
        }
    ))
    return (
        <FilterMultiSelectAsync
            paginate={paginate}
            setPaginate={setPaginate}
            formatFilter={formatFilters}
            name='rolnames'
            options={options ?? []}
        />
    )
}
