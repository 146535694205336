import React, { useState } from 'react'
// import MainTargetInfo from './MainTargetInfo'
import { ListAlmacenCierreTable } from './ListAlmacenCierreTable'
import { Route, Routes } from 'react-router-dom';
import { AlmacenHistorials } from './almacen_historials_cierres';
import { ErrorPage } from '../../../../../error/errorPage';
import CardInfoAlmacenProducto from '../../elementosGenerales/CardInfoAlmacenProducto';
import { BackButton } from '../../../../../../components/buttons/ButtonDefault';

export const AlmacenCierresHistorial = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <>
            <Routes>
                <Route index element={
                    <div className='relative'>
                        <BackButton />
                        <CardInfoAlmacenProducto
                            data={data}
                            setData={setData}
                        />
                        <ListAlmacenCierreTable
                            reload={reload}
                            setReload={setReload}
                            data={data}
                            setData={setData}
                        />
                    </div>
                } />
                <Route
                    path={`/historial/:almacen_cierre_id`}
                    element={<AlmacenHistorials />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </>
    )
}
