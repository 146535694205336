export function defaultPaginateParams() {
    return {
        pageSize: 10,
        filterBy: '',
        filterParam: '',
        initial: '',
        final: '',
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
    }
}

export const formatFilters = (name, value, paginate, setPaginate, isMulti) => {
    if (isMulti) {
        setPaginate((prevPaginate) => {
            const currentValues = prevPaginate[name] || []; // Valores actuales en `paginate[name]`
            const newValues = [...currentValues];

            if (currentValues.includes(value)) {
                // Si el valor ya está en el array, lo eliminamos
                return {
                    ...prevPaginate,
                    [name]: newValues.filter(val => val !== value),
                };
            } else {
                // Si el valor no está, lo agregamos
                newValues.push(value);
                return {
                    ...prevPaginate,
                    [name]: newValues,
                };
            }
        });
    } else {
        // Para selección única, actualizamos directamente
        setPaginate({
            ...paginate,
            [name]: value,
        });
    }
};


export const DefaultParamsForRecall = (data) => {
    return [
        data.currentPage,
        data.pageSize,
        data.filterBy,
        data.filterParam,
        data.initial,
        data.final
    ]
}