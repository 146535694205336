export const TagsStepCompraDictionary = {
    1: 'Solicitud de compra',
    2: 'Confirmación de compra',
    3: 'Confirmación de salida',
    4: 'Distribución de envases de proveedor',
    5: 'Planificación de llegada',
    6: 'Confirmación de llegada y finalización de compra!',
    7: 'Compra concluida!',
}

export const Months = {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
};