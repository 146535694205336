import React, { useEffect } from 'react'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { TabContainer } from '../../../../components/tab/TabContainer'
import { TabItem } from '../../../../components/tab/TabItem'
import { VerificationPathByString } from '../../../../utils'
import { ErrorPage } from '../../../error/errorPage'
import { EstadoDeCuentas } from './EstadoDeCuentas'
import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { HistorialTransacciones } from './HistorialTransacciones'
import { VentasPorProductos } from '../informeGeneralDeVentas/VentasPorProductos'
import { AlmacenPorVentaDeOficina } from '../informeGeneralDeAlmacen/AlmacenPorVentaDeOficina'
import { HistorialComprasPorTransportista } from './HistorialComprasPorTransportista'
import { TesoreriaGeneralContainer } from './TesoreriaGeneralContainer'

export const InformeGeneralDeTesoreriaTab = () => {
    const {
        Section,
    } = useGeneralParams('')

    const DefaultParams = {
        infProvCompras: {
            headers: [
                {
                    label: 'Proveedor',
                    columns: ['proveedor']
                },
            ],
            aditionalColsRef: 'cantidades'
        }
    }

    const navigate = useNavigate()
    useEffect(() => {
        navigate('estado-de-cuentas')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Section>
            <TabContainer>
                {
                    [
                        {
                            to: 'estado-de-cuentas',
                            label: 'ESTADO DE CUENTAS'
                        },
                        {
                            to: 'monto-ventas-otros-productos',
                            label: 'MONTO DE VENTAS DE OTROS PRODUCTOS'
                        },
                        {
                            to: 'venta-oficina-con-contenido-efectivo-empresa',
                            label: 'EFECTIVO DE VENTAS DE OFICINA EMPRESAS'
                        },
                        {
                            to: 'venta-oficina-con-contenido-efectivo-mayorista',
                            label: 'EFECTIVO DE VENTAS DE OFICINA MAYORISTAS'
                        },
                        {
                            to: 'transacciones',
                            label: 'HISTORIAL DE TRANSACCIONES'
                        },
                        {
                            to: 'historial-compras',
                            label: 'HISTORIAL DE COMPRAS POR TRANSPORTISTAS'
                        },
                        {
                            to: 'compras',
                            label: 'COMPRAS POR PROVEEDOR'
                        }
                    ].map((i, idx) => (
                        <TabItem
                            key={idx}
                            to={i.to}
                            label={i.label}
                            active={VerificationPathByString('/' + i.to)}
                            isChart
                            minW
                        />
                    ))
                }
            </TabContainer>
            <Routes>
                <Route
                    index
                    element={<Navigate to='estado-de-cuentas' />}
                />
                <Route
                    path='estado-de-cuentas'
                    element={
                        <TabBodyContainer>
                            <EstadoDeCuentas
                                title='ESTADO DE CUENTAS Y SUB-CUENTAS'
                                subtitle=''
                                urlApi='informe/tesoreria/estadoDeCuentas/pag'
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='monto-ventas-otros-productos'
                    element={
                        <TabBodyContainer>
                            <VentasPorProductos
                                title='MONTO DE VENTAS DE OTROS PRODUCTOS'
                                // subtitle='Ventas en Unidades de Turriles de 200L'
                                urlApi='informe/ventasPorProducto/pag?tipo=monto'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='venta-oficina-con-contenido-efectivo-empresa'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='EFECTIVO DE VENTA DE OFICINA EMPRESAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=efectivo_total&rolname=empresa'
                                isAmount={true}
                            />
                            <AlmacenPorVentaDeOficina
                                title='EFECTIVO DE VENTA DE OFICINA EMPRESAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=efectivo_total&rolname=empresa'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='venta-oficina-con-contenido-efectivo-mayorista'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='EFECTIVO DE VENTA DE OFICINA MAYORISTAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=efectivo_total&rolname=mayorista'
                                isAmount={true}
                            />
                            <AlmacenPorVentaDeOficina
                                title='EFECTIVO DE VENTA DE OFICINA MAYORISTAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=efectivo_total&rolname=mayorista'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='transacciones'
                    element={
                        <TabBodyContainer>
                            <HistorialTransacciones
                                title='HISTORIAL DE TRANSACCIONES'
                                // subtitle='Ventas en Unidades de Turriles de 200L'
                                urlApi='informe/tesoreria/transacciones/pag'
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='historial-compras'
                    element={
                        <TabBodyContainer>
                            <HistorialComprasPorTransportista
                                title='HISTORIAL DE COMPRAS POR TRANSPORTISTAS'
                                // subtitle='Ventas en Unidades de Turriles de 200L'
                                urlApi='informe/tesoreria/historialCompras/pag'
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='compras'
                    element={
                        <TabBodyContainer>
                            <TesoreriaGeneralContainer
                                title='COMPRAS POR PROVEEDOR'
                                subtitle='Turriles de 200L'
                                urlApi='informe/tesoreria/comprasProveedor/pag'
                                isAmount={false}
                                isNotOficina={false}
                                headers={DefaultParams.infProvCompras.headers}
                                aditionalColsRef={DefaultParams.infProvCompras.aditionalColsRef}
                            />
                        </TabBodyContainer>
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Section>
    )
}

const TabBodyContainer = ({ children }) => {
    return (
        <div className='grid gap-5'>
            {children}
        </div>
    )
}