import React, { useState } from 'react'
export const MultiInputForm = ({
    field,
    values,
    setValues,
}) => {
    const [data, setData] = useState(field.dataRef.Producto_estados)
    const addData = (value, name) => {
        const newData = data.map(item => {
            if (item.tipo === name) {
                return { ...item, precio: parseFloat(value) };
            }
            return item;
        })
        setData(newData)
        setValues('prodTipos', newData)
    }
    return (
        <div className='col-start-1 col-end-13 dark:text-gray-100 bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded p-3' >

            {
                field.title &&
                <center className='font-semibold mb-2'>{field.title}</center>
            }
            <div
                className='grid grid-cols-1 sm:grid-cols-3 gap-2 max-h-80 overflow-auto'
            >
                {
                    field.dataRef.Producto_estados?.map((i, index) => (
                        <div
                            key={index}
                            className='dark:bg-gray-800 bg-gray-300 p-2 rounded-lg'
                        >
                            <div
                                className='mb-2'
                            >
                                <label
                                    htmlFor={index}
                                    className='dark:text-orange-400 text-orange-700 font-bold'
                                >
                                    {i.tipo}
                                </label>
                            </div>
                            <div className='flex gap-0.5'>
                                <input
                                    id={index}
                                    type='number'
                                    name={i.tipo + ''}
                                    className={`shadow-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 ${field.highlight} dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold disabled:opacity-55 disabled:text-gray-500 dark:disabled:text-gray-200`}
                                    defaultValue={i.precio}
                                    onChange={(e) => addData(e.target.value, e.target.name)}
                                />
                                <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-gray-300 rounded-r-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-500'>
                                    {'Bs'}
                                </span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div >
    )
}