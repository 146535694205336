import React from 'react'
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { AlmacenProductos } from './almacen_productos';
import { ErrorPage } from '../../../error/errorPage';
import { Compras } from './compras';
import { ListAlmacenTable } from './ListAlmacenTable';
import { AlmacenHistorials } from './almacen_historial_general';

export const Almacenes = ({ almTag, rolname }) => {
    return (
        // <div className='grid xl:grid-cols-1 gap-4 divide-y-2 divide-gray-600'>
        // <div className='grid grid-cols-1'>
        // <div className='grid grid-cols-1 gap-2'>
        <Routes>
            <Route index element={
                <ListAlmacenTable
                    almTag={almTag}
                    rolname={rolname}
                />
            } />
            <Route
                path={`/productos/:almacen_id/*`}
                element={<AlmacenProductos />}
            />
            <Route
                path={`/historial/:almacen_id/*`}
                element={<AlmacenHistorials />}
            />
            <Route
                path={`/compras/:almacen_id/*`}
                element={<Compras />}
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
        // </div>
        // </div>
    )
}