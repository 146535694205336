import React, { useEffect, useState } from 'react'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { ModalForm } from '../../../../../components/modals/ModalForm'
import { DeleteValues } from '../../../../../FormSchemes/AlmacenHistorialScheme'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../../hooks/Callers'

export const ListCuadernoGastoTable = ({
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
    } = useGeneralParams()
    const [deleteDataModal, setDeleteDataModal] = useState(false)
    const [currentData, setCurrentData] = useState(false)
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/cuaderno/${params.cuaderno_id}/gastos/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, params.efectivo_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Estado',
                            value: 'estado'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Tipo de Gasto',
                            columns: ['estado_gasto_nombre']
                        },
                        {
                            label: 'Efectivo Bs.',
                            columns: ['monto'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Descripcion',
                            columns: ['obs'],
                            // tag: true
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => {
                                        setCurrentData(data)
                                        setDeleteDataModal(true)
                                    },
                                    reference: 'id'
                                },
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
                {
                    deleteDataModal &&
                    <ModalForm
                        setModal={setDeleteDataModal}
                        label='Eliminar traspaso'
                        dataValues={DeleteValues(currentData)}
                        urlApi={`/cuadernoGasto/${currentData.id}`}
                        method={'delete'}
                        call={reloadTable}
                        buttonLabel='Eliminar'
                    />
                }
            </TableSection>
        </>
    )
}