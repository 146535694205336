import React from 'react';
import { exportToExcel } from "react-json-to-excel";
import { listToString } from './dataValidations';
import { getFormattedDate } from './dateFormat';
import { Document, Page, StyleSheet, Text, View, pdf } from '@react-pdf/renderer';
import config from "../config.json";

export const GenerateDoc = (props) => {
    const { docName = getFormattedDate() + '.SoyaSari', sheetName = 'Hoja 1' } = props;
    return (
        <div className='p-0.5 flex gap-0.5'>
            <GenerateDocFile
                icon={'fa-file-excel'}
                color={'green'}
                label={'Excel'}
                onClick={() => exportToExcel([{ sheetName: sheetName, details: ProcessDataConvert(props) }], docName, true)}
            />
            <GenerateDocFile
                icon={'fa-file-pdf'}
                color={'red'}
                label={'PDF'}
                onClick={() => generatePDF({ data: ProcessDataConvert(props), sheetName: config.simpleName ?? '', docName })}
            />
        </div>
    );
}

export const GenerateDocFile = ({ icon, color, label, onClick }) => {
    return (
        <button
            type='button'
            title='Recargar'
            className={`bg-${color}-600 dark:hover:bg-${color}-800 hover:bg-${color}-700 hover:text-gray-100 text-white dark:text-white font-semibold rounded-sm max-w-[150px] flex items-center mx-0 px-2 py-0.5 portrait:m-0 gap-1`}
            onClick={() => onClick()}
        >
            <i className={`fa ${icon} text-md`} />{label && <span className='text-[13px]'> {label}</span>}
        </button>
    );
}

const ProcessDataConvert = ({ headers, data = [], aditionalCols = [], aditionalColsRef = [], aditionalColsAmount = [] }) => {
    var listReturn = [];
    for (const rowItem of data) {
        let item = {};
        for (const head of headers) {
            if (head === null) continue;
            if (head.columns && head.columns[0]) {
                if (head.booleanState) {
                    item[head.label] = rowItem[head.columns[0]] ? head.booleanOptions[0] : head.booleanOptions[1];
                }
                else if (head.boolean) {
                    item[head.label] = rowItem[head.columns[0]] ? head.booleanOptions[0] : head.booleanOptions[1];
                } else {
                    var vals = head.columns;
                    var fields = [];
                    for (const vai of vals) {
                        let minItems = vai.split(':');
                        for (const el of minItems) {
                            fields.push(rowItem[el]);
                        }
                    }
                    let val = listToString(fields, ' ');
                    item[head.label] = val;
                }
            }
            let counter = 0;
            if (aditionalCols != null && aditionalCols.length > 0) {
                for (const col of aditionalCols) {
                    item[col] = aditionalColsAmount ? rowItem.cantidades[counter].toLocaleString('es-BO', { minimumFractionDigits: 2 }) : rowItem.cantidades[counter];
                    counter++;
                }
            }
        }
        listReturn.push(item);
    }
    let totals = {};
    for (const head of headers) {
        if (head === null) continue;
        if (head.sum) {
            totals[head.label] = data.reduce((acumulador, elemento) => acumulador + elemento[head.columns[0]], 0);
        }
        if (head.sumDec) {
            let listNums = data.map(x => x[head.columns[0]] && parseFloat((x[head.columns[0]] + '').replace(/\./g, '').replace(',', '.')));
            let val = listNums.reduce((acumulador, elemento) => acumulador + elemento, 0);
            totals[head.label] = val.toLocaleString('es-BO', { minimumFractionDigits: 2 });
        }
    }
    if (aditionalCols != null && aditionalCols.length > 0) {
        let resultados = [];
        if (data.length > 0)
            resultados = data[0].cantidades.map((_, index) =>
                data.reduce((suma, item) => suma + item.cantidades[index], 0)
            );
        else {
            aditionalCols.map(x => resultados.push(0));
        }
        let counter = 0;
        for (const col of aditionalCols) {
            totals[col] = aditionalColsAmount ? resultados[counter].toLocaleString('es-BO', { minimumFractionDigits: 2 }) : resultados[counter];
            counter++;
        }
    }
    listReturn.push(totals);
    return listReturn;
};

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 5,
    },
    header: {
        fontSize: 11,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#4b5563',
        borderCollapse: 'collapse',
    },
    tableRow: {
        flexDirection: 'row',
        backgroundColor: '#fff',
    },
    tableRowHeader: {
        flexDirection: 'row',
        backgroundColor: '#4b5563',
    },
    tableCell: {
        flex: 1,
        fontSize: 7,
        borderWidth: 0.5,
        padding: 1,
        borderColor: '#4b5563',
    },
    tableCellHeader: {
        flex: 1,
        fontSize: 7,
        borderWidth: 0.5,
        padding: 1,
        borderColor: '#4b5563',
        color: 'white',
    },
});

const MyDocument = ({ data, sheetName, docName }) => {
    const headers = Object.keys(data[0]);

    return (
        <Document>
            <Page style={styles.page}>
                <Text style={styles.header}>{sheetName}</Text>
                <View style={styles.table}>
                    <View style={styles.tableRowHeader}>
                        {headers.map((header, index) => (
                            <Text key={index} style={styles.tableCellHeader}>
                                {header}
                            </Text>
                        ))}
                    </View>
                    {data.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                            {headers.map((header, i) => (
                                <Text key={i} style={styles.tableCell}>
                                    {item[header]}
                                </Text>
                            ))}
                        </View>
                    ))}
                </View>
            </Page>
        </Document>
    );
};

const generatePDF = ({ data, sheetName, docName }) => {
    pdf(<MyDocument data={data} sheetName={sheetName} docName={docName} />).toBlob().then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = docName + '.pdf';
        link.click();
    });
};
