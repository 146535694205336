import React from 'react'
import { ListDeudaTable } from './ListDeudaTable'
import { Route, Routes } from 'react-router-dom'
import { HistorialDeudas } from './historial_deudas'
import { ErrorPage } from '../../../error/errorPage'


export const Deudas = () => {
    return (
        <Routes>
            <Route index element={
                <ListDeudaTable />
            } />
            <Route
                path={`:deuda_id`}
                element={<HistorialDeudas />}
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}