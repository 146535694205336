import { useEffect, useState } from "react";
import { requestAuth } from "../../../../../http/httpRequest";
import { useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import { Months } from "../../../../../utils/dictionary";
import { useDispatch } from "react-redux";
import { toastOn } from "../../../../../store/slices/toast";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);

export const VentasPorMes = ({ isGeneral }) => {
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);
    const dispatch = useDispatch()
    const [gestion, setGestion] = useState(new Date().getFullYear());
    const params = useParams();

    const startYear = 2023;
    const endYear = new Date().getFullYear() + 1;
    const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

    const getData = async () => {
        await requestAuth(
            'get',
            `/informe/cliente/${isGeneral ? 0 : params.user_cli_id}/ventasPorMes?year=${gestion}`,
            null
        )
            .then((res) => {
                const fullData = Array.from({ length: 12 }, (_, i) => ({
                    mes: i + 1,
                    total_ventas: 0,
                }));

                res.data?.forEach(venta => {
                    const index = fullData.findIndex(item => item.mes === venta.mes);
                    if (index !== -1) {
                        fullData[index].total_ventas = venta.total_ventas;
                    }
                });

                setLabels(fullData.map(venta => `${Months[venta.mes]}`));
                setData(fullData.map(venta => venta.total_ventas));
            })
            .catch(error => {
                dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
            })
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gestion]);

    const handleGestionChange = (e) => {
        setGestion(e.target.value);
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: `Ventas Totales (${gestion})`,
                data,
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: context => `Bs. ${context.raw}`,
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: `Ventas por mes de ${gestion}`,
                },
                ticks: { color: 'rgba(170,170,170)' },
                grid: {
                    color: 'rgba(170,170,170,.7)'
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Meses',
                },
                ticks: { color: 'rgba(170,170,170)' },
                grid: {
                    color: 'rgba(170,170,170,.7)'
                },
            },
        },
    };

    return (
        <div>
            <div style={{ marginBottom: '20px', marginTop: '10px' }}>
                <div className='dark:text-gray-300 text-gray-800'>
                    <h1 className='text-xl font-bold flex justify-center items-center gap-2'>
                        <span>COMPRA DE PRODUCTOS POR MES</span>
                        <select
                            id="gestion-select"
                            className="'bg-transparent dark:bg-gray-900 text-gray-800 text-lg dark:text-gray-200 outline-none border border-gray-300 rounded-lg dark:focus:border-gray-400 focus:border-gray-300 focus:ring-0 dark:border-gray-700 dark:placeholder-gray-400 dark:focus:ring-0"
                            value={gestion}
                            onChange={handleGestionChange}
                        >
                            {years.map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </h1>
                </div>
            </div>

            <Line data={chartData} options={options} />
        </div>
    );
};