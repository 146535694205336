// Dependencies
import React from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
// Styles
import { Icon, divIcon, point } from 'leaflet';

const MapComponent = ({ data }) => {
    let geo = [data?.geolocalizacion?.split(',')[0] ?? -16.524568684632587, data?.geolocalizacion?.split(',')[1] ?? -68.21297334607009]
    const geoMarkers = [
        {
            geocode: geo,
            popUp: 'Srirams Home'
        },
    ];

    function createCustomClusterIcon(cluster) {
        return new divIcon({
            html: `<div class='cluster-icon'>${cluster.getChildCount()}</div>`,
            className: 'custom-marker-cluster',
            iconSize: point(28, 28, true)
        });
    }
    const customIcon = new Icon({
        iconUrl: require('./location.png'),
        iconSize: [26, 26]
    });
    return (
        data?.geolocalizacion &&
        <MapContainer center={geo} zoom={13}>
            <TileLayer
                // attribution='&copy; <a href='http://www.thunderforest.com/'>Thunderforest</a>, &copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors'
                url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <MarkerClusterGroup
                chunkedLoading
                iconCreateFunction={createCustomClusterIcon}
            >
                {geoMarkers.map((item, index) => (
                    <Marker key={index} position={item.geocode} icon={customIcon}>
                        <Tooltip>
                            <h2>{item.popUp}</h2>
                        </Tooltip>
                    </Marker>
                ))}
            </MarkerClusterGroup>
        </MapContainer>
    );
};

export default MapComponent;
