import React, { useEffect, useState } from 'react'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { DeleteValues, VentaCambiarFecha } from '../../../../../FormSchemes/CuadernoHistorialScheme'
import { formatDateWithTime } from '../../../../../utils/dateFormat'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../../hooks/Callers'

export const ListCuadernoHistorialTable = ({
    params,
    setSelectAllChecked,
    setIsChecked,
    selecteds,
    setSelecteds,
    selectAllChecked,
    isChecked,
    user_cli_id
}) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        stateData, setStateData,
        currentData, setCurrentData,
        deleteModal, setDeleteModal,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
        ModalForm,
        UpdateValuesModal,
    } = useGeneralParams()
    const [AnularModal, setAnularModal] = useState(false)
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/cuaderno/${params.cuaderno_id ?? 0}/historial/pag`,
            // url: `/informe/cliente/${params.user_cli_id}/ventasPorMes?year=2024`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombre', ...(user_cli_id ? { user_id: user_cli_id } : {}) }
        })
    }
    const [venta, setVenta] = useState(null)
    const [ventaEditarFechaModal, setVentaEditarFechaModal] = useState(false)
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, params.cuaderno_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadSubTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }

    const ViewClientHeader = [
        {
            label: 'Cuaderno',
            columns: ['codigo']
        },
        {
            label: 'Producto',
            columns: ['producto_nombre']
        },
        {
            label: 'Cantidad',
            columns: ['cantidad'],
            numberValue: true,
            sum: true
        },
        {
            label: 'Pago',
            columns: ['metodo_pago'],
        },
        {
            label: 'Precio unitario Bs.',
            columns: ['precio_unitario'],
            numberValue: true,
            sumDec: true
        },
        {
            label: 'Venta Bs.',
            columns: ['venta_total'],
            numberValue: true,
            sumDec: true
        },
        {
            label: 'Efectivo Bs.',
            columns: ['efectivo_total'],
            numberValue: true,
            sumDec: true
        },
        {
            label: 'Transferencia Bs.',
            columns: ['transferencia_total'],
            numberValue: true,
            sumDec: true
        },
        {
            label: 'Fecha de registro',
            columns: ['createdAt'],
            transform: true,
            func: formatDateWithTime
        },
    ]
    return (
        <>
            <ActionSection>
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombre'
                        },
                        {
                            label: 'Estado',
                            value: 'estado'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={
                        user_cli_id ?
                            ViewClientHeader
                            :
                            [
                                {
                                    label: 'Vendido a',
                                    columns: ['user_nombres', 'user_apellido_paterno:user_apellido_materno']
                                },
                                {
                                    label: 'Producto',
                                    columns: ['producto_nombre']
                                },
                                {
                                    label: 'Intercambio',
                                    columns: ['metodo_intercambio'],
                                    // tag: true
                                },
                                {
                                    label: 'Cantidad',
                                    columns: ['cantidad'],
                                    numberValue: true,
                                    sum: true
                                },
                                {
                                    label: 'Cantidad recojido',
                                    columns: ['cantidad_retorno'],
                                    numberValue: true,
                                    sum: true
                                },
                                {
                                    label: 'Cantidad deuda',
                                    columns: ['cantidad_deuda'],
                                    numberValue: true,
                                    sum: true
                                },
                                {
                                    label: 'Pago',
                                    columns: ['metodo_pago'],
                                },
                                {
                                    label: 'Precio unitario Bs.',
                                    columns: ['precio_unitario'],
                                    numberValue: true,
                                    sumDec: true
                                },
                                {
                                    label: 'Venta Bs.',
                                    columns: ['venta_total'],
                                    numberValue: true,
                                    sumDec: true
                                },
                                {
                                    label: 'Efectivo Bs.',
                                    columns: ['efectivo_total'],
                                    numberValue: true,
                                    sumDec: true
                                },
                                {
                                    label: 'Transferencia Bs.',
                                    columns: ['transferencia_total'],
                                    numberValue: true,
                                    sumDec: true
                                },
                                {
                                    label: 'Saldo Bs.',
                                    columns: ['deuda_total'],
                                    numberValue: true,
                                    sumDec: true
                                },
                                {
                                    label: 'Garantía Bs.',
                                    columns: ['monto_garantia_string'],
                                    numberValue: true,
                                    sumDec: true
                                },
                                {
                                    label: 'Encargado',
                                    columns: ['encargado'],
                                },
                                {
                                    label: 'Tipo',
                                    columns: ['tipo'],
                                },
                                {
                                    label: 'Fecha de registro',
                                    columns: ['createdAt'],
                                    transform: true,
                                    func: formatDateWithTime
                                },
                                {
                                    label: '',
                                    // stickyR: true,
                                    popActions: [
                                        {
                                            subTitle: 'Editar fecha de la venta',
                                            icon: 'far fa-calendar-days',
                                            action: (i) => {
                                                setVenta(i)
                                                setVentaEditarFechaModal(true)
                                            },
                                        },
                                        {
                                            subTitle: 'Anular préstamo',
                                            action: (data) => UpdateValuesModal(data, setCurrentData, setAnularModal),
                                            icon: 'far fa-square-minus',
                                        },
                                        {
                                            subTitle: 'Eliminar',
                                            action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                            reference: 'id',
                                            adClass: 'hover:text-red-600',
                                            icon: 'fa fa-trash',
                                        },
                                    ],
                                },
                            ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
                {
                    ventaEditarFechaModal &&
                    <ModalForm
                        setModal={setVentaEditarFechaModal}
                        label='Editar fecha de venta'
                        dataValues={VentaCambiarFecha(venta)}
                        urlApi={`/cuadernoHistorial/${venta.id}/cambiarFecha`}
                        method={'put'}
                        call={reloadTable}
                        buttonLabel='Editar fecha'
                    />
                }
                {
                    deleteModal &&
                    <ModalForm
                        setModal={setDeleteModal}
                        label='Eliminar venta'
                        dataValues={DeleteValues(currentData)}
                        urlApi={`/cuadernoHistorial/${currentData.id}`}
                        method={'delete'}
                        call={reloadTable}
                        buttonLabel='Eliminar'
                    />
                }
                {
                    AnularModal &&
                    <ModalForm
                        setModal={setAnularModal}
                        label='Anular préstamo'
                        dataValues={DeleteValues(currentData)}
                        urlApi={`/cuadernoHistorial/anularPrestamo/${currentData.id}`}
                        method={'put'}
                        call={reloadTable}
                        buttonLabel='Anular'
                    />
                }
            </TableSection>
        </>
    )
}