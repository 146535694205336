import * as Yup from 'yup';
import { formatInputDate } from '../utils/dateFormat';

export const CreateValues = ({ tag }) => {
    return {
        initialValues: {
            nombre: '',
            tag: tag
            // encargado_id: '',
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre del almacen',
                name: 'nombre',
                type: 'text',
                // required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
        ]
    }
}

export const CreatePersonalValues = (urlApi) => {
    return {
        initialValues: {
            // nombre: '',
            users: [],
            productos: [],
            tag: 'persona'
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Seleccionar usuarios',
                name: 'users',
                type: 'checkboxes',
                required: false,
                urlApi: urlApi,
                indexLabel: 'etiqueta'
            },
            {
                label: 'Seleccionar producto',
                name: 'productos',
                type: 'checkboxes',
                required: false,
                urlApi: `/producto/almacen/items`,
                indexLabel: 'etiqueta'
            },
        ]
    }
}

export const NotaDeEntregaDeProductoValues = (almacen_id) => {
    return {
        initialValues: {
            almacen_id: almacen_id,
            fecha_salida: formatInputDate(new Date()),
            receptores: [],
            encargado_despacho: '',
            transportista: '',
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Fecha de salida',
                name: 'fecha_salida',
                type: 'date',
                required: false,
                placeholder: 'Desde...',
                autoFocus: false
            },
            {
                label: 'Receptor',
                name: 'receptores',
                type: 'multiSelectSearch',
                urlApi: '/almacenes/tipo/persona',
                required: true,
                labelDescription: 'etiqueta',
                labelId: 'id',
                placeholder: 'Receptor...',
                autoFocus: false,
            },
            {
                label: 'Encargado del despacho',
                name: 'encargado_despacho',
                type: 'selectSearch',
                urlApi: '/users/rol/transportista', // cambiar
                required: false,
                labelDescription: 'etiqueta',
                labelId: 'etiqueta',
                placeholder: 'Encargado...',
                autoFocus: false,
            },
            {
                label: 'Transportista',
                name: 'transportista',
                type: 'selectSearch',
                urlApi: '/users/rol/transportista',
                required: false,
                labelDescription: 'etiqueta',
                labelId: 'etiqueta',
                placeholder: 'Transportista...',
                autoFocus: false,
            },
        ]
    }
}

export const NotaDeRecepcionDeVaciosValues = (almacen_id) => {
    return {
        initialValues: {
            almacen_id: almacen_id,
            fecha_ingreso: formatInputDate(new Date()),
            emisores: [],
            encargado_despacho: '',
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Fecha de ingreso',
                name: 'fecha_ingreso',
                type: 'date',
                required: false,
                placeholder: 'Desde...',
                autoFocus: false
            },
            {
                label: 'Encargado',
                name: 'encargado_despacho',
                type: 'selectSearch',
                urlApi: '/users/rol/transportista', // cambiar
                required: false,
                labelDescription: 'etiqueta',
                labelId: 'etiqueta',
                placeholder: 'Encargado...',
                autoFocus: false,
            },
            {
                label: 'Receptor',
                name: 'emisores',
                type: 'multiSelectSearch',
                urlApi: '/almacenes/tipo/persona',
                required: true,
                labelDescription: 'etiqueta',
                labelId: 'id',
                placeholder: 'Emisor...',
                autoFocus: false,
            },
        ]
    }
}

export const UpdateValues = (data) => {
    return {
        initialValues: {
            id: data?.id || '',
            nombre: data?.nombre || '',
            departamento_id: data?.departamento_id || '',
            encargado_id: data?.encargado_id || '',
        },
        fieldsValidation: Yup.object().shape({
            // nombre: Yup.string()
            //     .min(5, 'Mínimo 4 caracteres!')
            //     .max(50, 'Máximo 40 caracteres!')
            //     .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre del almacen',
                name: 'nombre',
                type: 'text',
                // required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Buscar usuario por departamento',
                name: 'departamento_id',
                type: 'doubleSelectApi',
                required: false,
                optionDescription: 'nombre',
                urlApi: '/vendedores',
                sub_name: 'encargado_id',
                sub_label: 'Seleccionar usuario',
                sub_urlApi: '/departamento/{param}/users',
                sub_optionDescription: 'etiqueta',
            },
            // {
            //     label: 'Vendedor responsable',
            //     name: 'user_id',
            //     type: 'selectSearch',
            //     urlApi: `/vendedores`,
            //     required: false,
            //     labelDescription: 'nombre',
            //     placeholder: 'Vendedor...',
            //     autoFocus: false,
            //     // defaultValue: { value: '2', label: 'Clientes' }
            // },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}