import React from 'react'
import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { ItemCardTargetInfo, LogoImageForCardInfo, MainTargetInfo, TitleOfCardInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo';
import StoreImage from '../../../../../assets/store.png'
// import { NoteIconSvg } from '../../../../../utils/styleFormat';

const CardInfo = ({ data, setData }) => {
    const params = useParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const tipeAlmIdentity = () => {
        switch (data?.tag) {
            case 'general':
                return 'Almacén General'
            case 'proveedor':
                return 'Almacén de Proveedor'
            case 'persona':
                return 'Almacén de Persona'

            default:
                break;
        }
    }
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 gap-1'
            urlApi={`/almacen/${params.almacen_id}`}
            setData={setData}
            dataRef={[params.almacen_id, reloadSubTable]}
        >
            <ItemCardTargetInfo
                // logoLabel={<LogoLabelTargetInfo logo={<NoteIconSvg />} />}
                logo={<LogoImageForCardInfo logo={StoreImage} />}
                aditionalClassName='col-span-2'
            >
                
                <TitleOfCardInfo label={tipeAlmIdentity()} />
                <TitleTargetInfo label='' data={data?.nombre} />
            </ItemCardTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo