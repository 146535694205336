import React from 'react'
import { useParams } from 'react-router-dom'
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoImageForCardInfo, MainTargetInfo, NumberCalculatorsTargetInfo, NumberGridTargetInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo'
import { JoinStrings } from '../../../../../utils/dataValidations'
import { useSelector } from 'react-redux'
import MoneyImage from '../../../../../assets/money.webp'

const CardInfoEfectivo = ({ reload, data, setData, nameLabel, efectivoParams }) => {
    const params = useParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-1'
            urlApi={efectivoParams?.type === 'subEfectivo' ? `/efectivo/${params.sub_efectivo_id}` : `/efectivo/${params.efectivo_id}`}
            setData={setData}
            dataRef={[params.efectivo_id, reloadSubTable]}
            reload={reload}
        >
            <ItemCardTargetInfo
                // logo={<LogoTargetInfo logo='fa-coins' />}
                logo={<LogoImageForCardInfo logo={MoneyImage} />}
                aditionalClassName='col-span-2 lg:col-span-1'
            >
                <TitleTargetInfo label={null} data={nameLabel} />
                <TitleTargetInfo label={null} data={data?.producto_nombre ?? data?.nombre} />
                {
                    data?.nombres != null && data?.nombres !== '-' &&
                    <DescriptionTargetInfo label='Encargado:' data={data ? JoinStrings([data.nombres, data.apellido_paterno, data.apellido_materno], ' ') : '-'} />
                }
                {/* <DescriptionTargetInfo label='Obs:' data={data?.obs} /> */}
            </ItemCardTargetInfo>
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Monto total Bs.' data={data?.monto_total_string} />
                <NumberGridTargetInfo label='Ingresos Bs.' data={data?.monto_total_ingreso_string} />
                <NumberGridTargetInfo label='Egresos Bs' data={data?.monto_total_egreso_string} />
                <NumberGridTargetInfo label='' data='' />
            </NumberCalculatorsTargetInfo>
            <NumberCalculatorsTargetInfo>
                <NumberGridTargetInfo label='Billetes Bs.' data={data?.billetes_total_string} />
                <NumberGridTargetInfo label='Monedas Bs.' data={data?.monedas_total_string} />
                <NumberGridTargetInfo label='Dolares Sus.' data={data?.dolares_total_string} />
                <NumberGridTargetInfo label='Dolares Bs.' data={data?.dolares_en_bs_total_string} />
            </NumberCalculatorsTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfoEfectivo