import React from 'react'
import { useParams } from 'react-router-dom';
import { DescriptionTargetInfo, ItemCardTargetInfo, LogoImageForCardInfo, MainTargetInfo, NumberCalculatorsTargetInfo, NumberGridTargetInfo, SubTitleTargetInfo, TitleTargetInfo } from '../../../../../components/card/MainTargetInfo';
import { formatDate } from '../../../../../utils/dateFormat';
import { useSelector } from 'react-redux';
import DeudaEnvaseImage from '../../../../../assets/barrelOil.png';

const CardInfo = ({ reload, data, setData, globalParams }) => {
    const params = useParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    return (
        <MainTargetInfo
            extraClassName='grid-cols-1 lg:grid-cols-3 gap-1'
            urlApi={`/deudaEnvases/${globalParams.tipoDeuda}/${params.deuda_id}`}
            setData={setData}
            dataRef={[params.deuda_id, reloadSubTable]}
        >
            <ItemCardTargetInfo
                // logo={<LogoTargetInfo logo='fa-boxes-packing' />}
                logo={<LogoImageForCardInfo logo={DeudaEnvaseImage} />}
                // logoLabel={<LogoLabelTargetInfo logo={<NoteIconSvg />} />}
                aditionalClassName='lg:col-span-2'
            >
                <SubTitleTargetInfo label={null} data={'DEUDA DE ENVASE'} />
                <TitleTargetInfo label={null} data={data?.producto_nombre} />
                {
                    globalParams.tipoDeuda === 'préstamo' &&
                    <DescriptionTargetInfo label={'Fecha límite de deuda:'} data={formatDate(data?.fecha_limite)} />
                }
                <DescriptionTargetInfo label='Fecha de registro:' data={formatDate(data?.createdAt)} />
                <DescriptionTargetInfo label='Observaciones:' data={data?.obs} />
            </ItemCardTargetInfo>
            <NumberCalculatorsTargetInfo colspan=''>
                <NumberGridTargetInfo label='Saldo inicial' data={data?.saldo_inicial} />
                <NumberGridTargetInfo label='Saldo' data={data?.saldo} />
                {
                    globalParams.tipoDeuda === 'garantía' ?
                        <>
                            <NumberGridTargetInfo label='Monto inicial Bs.' data={data?.monto_garantia_inicial} />
                            <NumberGridTargetInfo label='Monto Bs.' data={data?.monto_garantia} borderBotton={false} />
                        </>
                        :
                        <>
                            <NumberGridTargetInfo label='' data={''} />
                            <NumberGridTargetInfo label='' data={''} borderBotton={false} />
                        </>
                }
            </NumberCalculatorsTargetInfo>
        </MainTargetInfo>
    )
}

export default CardInfo