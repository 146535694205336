import React from 'react'
import { ListCuadernoTable } from './ListCuadernoTable'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../error/errorPage'
import { CuadernoMovimientos } from './historialMovimientos'

export const IngresoCuaderno = () => {
    return (
        <Routes>
            <Route index element={
                <ListCuadernoTable
                    globalParams={{}}
                />
            } />
            <Route
                path={`/:cuaderno_id/*`}
                element={<CuadernoMovimientos
                    globalParams={{}}
                />}
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}