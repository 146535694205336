import React from 'react'

export const ModalHeader = ({
    setModal,
    label
}) => {
    return (
        <div className='flex items-center justify-between p-3 border-b rounded-t dark:border-gray-500 border-gray-300 gap-2'>
            <h3 className='text-md font-semibold text-gray-900 dark:text-white'>
                {label}
            </h3>
            <button
                onClick={() => setModal(false)}
                type='button'
                className='text-white dark:text-gray-200 hover:bg-gray-600 hover:text-gray-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:bg-red-800 bg-[rgb(220,38,38)] dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-hide='editUserModal'
            >
                <svg aria-hidden='true' className='w-6 h-6' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clipRule='evenodd' /></svg>
            </button>
        </div>
    )
}
