import React from 'react'

export const TabBoard = ({ title, subtitle, children }) => {
    return (
        <div className='p-2 rounded-b-md overflow-auto dark:bg-gray-800/60 bg-white'>
            <div className='text-center dark:text-gray-300 text-gray-800'>
                <h1 className='text-xl font-bold'>{title}</h1>
                <h4 className='text-lg font-semibold'>{subtitle}</h4>
            </div>
            {children}
        </div>
    )
}
