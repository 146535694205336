import React, { useEffect } from 'react'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { TabContainer } from '../../../../components/tab/TabContainer'
import { TabItem } from '../../../../components/tab/TabItem'
import { VerificationPathByString } from '../../../../utils'
import { ErrorPage } from '../../../error/errorPage'
import { VentasPorVendedorProductos } from './VentasPorVendedorProductos'
import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { VentasPorProductos } from './VentasPorProductos'
import { VentasPorVendedor } from './VentasPorVendedor'
import { VentasPorMes } from '../clientes/perfil_cli/VentasPorMes'
import { TabBoard } from '../../../../components/tab/TabBoard'
import { VentasPorProductosRetPorMes } from './VentasPorProductosRetPorMes'

export const InformeGeneralDeVentasTab = () => {
    const {
        Section,
    } = useGeneralParams('')

    const navigate = useNavigate()
    useEffect(() => {
        navigate('vendedor-productos-por-precios')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Section>
            <TabContainer>
                {
                    [
                        {
                            to: 'vendedor-productos-por-precios',
                            label: 'VENTAS POR VENDEDOR Y PRODUCTOS EN UNIDADES'
                        },
                        {
                            to: 'cantidad-ventas-otros-productos',
                            label: 'CANTIDAD DE VENTAS DE OTROS PRODUCTOS'
                        },
                        {
                            to: 'ventas-por-vendedor',
                            label: 'VENTAS POR VENDEDOR'
                        },
                        {
                            to: 'compras-por-mes',
                            label: 'COMPRA DE PRODUCTOS POR MES'
                        },
                        {
                            to: 'cantidad-de-productos-vendidos-por-tipo-cliente',
                            label: 'CANTIDAD DE PRODUCTOS VENDIDOS POR TIPO CLIENTE'
                        },
                    ].map((i, idx) => (
                        <TabItem
                            key={idx}
                            to={i.to}
                            label={i.label}
                            active={VerificationPathByString('/' + i.to)}
                            isChart
                        />
                    ))
                }
            </TabContainer>
            <Routes>
                <Route
                    index
                    element={<Navigate to='vendedor-productos-por-precios' />}
                />
                <Route
                    path='vendedor-productos-por-precios'
                    element={
                        <TabBodyContainer>
                            <VentasPorVendedorProductos
                                title='VENTAS POR VENDEDOR Y PRODUCTOS EN UNIDADES'
                                subtitle='Ventas en Unidades de Turriles de 200L'
                                urlApi='informe/vendedores/lt200/pag'
                            />
                            <VentasPorVendedorProductos
                                title='VENTAS POR VENDEDOR Y PRODUCTOS EN UNIDADES'
                                subtitle='Ventas en Unidades de Turriles de 100L'
                                urlApi='informe/vendedores/lt100/pag'

                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='cantidad-ventas-otros-productos'
                    element={
                        <TabBodyContainer>
                            <VentasPorProductos
                                title='CANTIDAD DE VENTAS POR PRODUCTOS'
                                // subtitle='Ventas en Unidades de Turriles de 200L'
                                urlApi='informe/ventasPorProducto/pag?tipo=cantidad'
                                isAmount={false}
                            />
                        </TabBodyContainer>
                    } />
                {/* <Route
                    path='monto-ventas-otros-productos'
                    element={
                        <TabBodyContainer>
                            <VentasPorProductos
                                title='MONTO DE VENTAS DE OTROS PRODUCTOS'
                                // subtitle='Ventas en Unidades de Turriles de 200L'
                                urlApi='informe/ventasPorProducto/pag?tipo=monto'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } /> */}
                <Route
                    path='ventas-por-vendedor'
                    element={
                        <TabBodyContainer>
                            <VentasPorVendedor
                                title='VENTAS AL CONTADO Y A CRÉDITO POR VENDEDOR'
                                subtitle='Turriles de 200L'
                                urlApi='informe/ventasPorVendedor/lt200/pag'
                                isAmount={true}
                            />
                            <VentasPorVendedor
                                title='VENTAS AL CONTADO Y A CRÉDITO POR VENDEDOR'
                                subtitle='Turriles de 100L'
                                urlApi='informe/ventasPorVendedor/lt100/pag'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='compras-por-mes'
                    element={
                        <TabBodyContainer>
                            <TabBoard
                            // title={'VENTAS POR MES'}
                            // subtitle={subtitle}
                            >
                                <VentasPorMes isGeneral={true} />
                            </TabBoard>
                        </TabBodyContainer>
                    } />
                <Route
                    path='cantidad-de-productos-vendidos-por-tipo-cliente'
                    element={
                        <TabBodyContainer>
                            <TabBoard
                            // title={'VENTAS POR MES'}
                            // subtitle={subtitle}
                            >
                                <VentasPorProductosRetPorMes isGeneral={true} />
                            </TabBoard>
                        </TabBodyContainer>
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Section>
    )
}

const TabBodyContainer = ({ children }) => {
    return (
        <div className='grid gap-5'>
            {children}
        </div>
    )
}