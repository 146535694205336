import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { DeleteValues } from '../../../../../FormSchemes/AlmacenProductoScheme'
import { MinimalActionButton } from '../../../../../components/form/minimalActionButton'
import { setReloadMainTable } from '../../../../../store/slices/auth/authSlice'
import { useSelector } from 'react-redux'
import { solicitarCompraValues } from '../../../../../FormSchemes/CompraScheme'
import { formatDateNumberWithTime } from '../../../../../utils/dateFormat'
import { ModalStep } from '../../../../../components/modals/ModalStep'
import { StepCompraViews } from '../../../../../components/modals/formularioDeCompra/StepCompraViews'
import { DefaultParamsForRecall } from '../../../../../utils/defaulStates'
import { Callers } from '../../../../../hooks/Callers'

export const ListComprasTable = ({
    reload,
    setReload,
    dataCard
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        deleteModal, setDeleteModal,
        currentData, setCurrentData,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        // formatFilters,
        Searcher,
        // FilterSelect,
        // RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
        UpdateValuesModal,
    } = useGeneralParams()
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)
    const [stepCompraModal, setStepCompraModal] = useState(false)

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/compras/${params.almacen_id}/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'producto' }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), params.almacen_id, reloadSubTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate)]);

    const recall = () => {
        getDataPaginate()
        setReload(!reload)
    }

    const reloadTable = () => {
        Callers(dispatch)
    }

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Solicitar compra',
                            action: () => setCreateModal(true)
                        },
                    ]}
                />
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        {
                            label: 'Producto',
                            value: 'producto'
                        },
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    {/* <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            },
                            {
                                label: 'Activo',
                                value: 'activo'
                            },
                            {
                                label: 'Inactivo',
                                value: 'inactivo'
                            }
                        ]}
                    /> */}
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={recall}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Comprador',
                            columns: ['comprador']
                        },
                        {
                            label: 'Proveedor',
                            columns: ['proveedor']
                        },
                        {
                            label: 'Transportista',
                            columns: ['transportista']
                        },
                        {
                            label: 'Solicitud',
                            type: 'listData',
                            tagList: 'listProductos',
                            tagLabel: 'producto_nombre'
                        },
                        {
                            label: 'Estado',
                            columns: ['concluido'],
                            boolean: true,
                            booleanOptions: ['Concluido', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-xmark'
                        },
                        {
                            label: 'Estado',
                            columns: ['nombre_paso']
                        },
                        {
                            label: 'Fecha de solicitud',
                            columns: ['fecha_solicitud'],
                            transform: true,
                            func: formatDateNumberWithTime
                        },
                        {
                            label: 'Fecha de salida',
                            columns: ['fecha_confirmacion_salida'],
                            transform: true,
                            func: formatDateNumberWithTime
                        },
                        {
                            label: 'Fecha de finalización',
                            columns: ['fecha_fin'],
                            transform: true,
                            func: formatDateNumberWithTime
                        },
                        // {
                        //     label: 'Estado del producto',
                        //     columns: ['estado_nombre'],
                        //     tag: true
                        // },
                        // {
                        //     label: 'Total',
                        //     columns: ['cantidad'],
                        //     numberValue: true
                        // },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'edit',
                                    icon: 'fa-store',
                                    action: (data) => {
                                        setStepCompraModal(true)
                                        setCurrentData(data)
                                    },
                                },
                                // {
                                //     type: 'yellow',
                                //     icon: 'fa-share-from-square',
                                //     action: (data) => redirect(`historial/${data.id}`),
                                //     reference: 'id'
                                // },
                                // {
                                //     type: 'cyan',
                                //     icon: 'fa-shop-lock',
                                //     action: (data) => redirect(`cierres/${data.id}`),
                                //     reference: 'id',
                                //     tooltipText: 'Cierres de almacén'
                                // },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                            // stickyR: true
                        },
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {/* <Paginator
                paginate={paginate}
                setPaginate={setPaginate}
            /> */}
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Solicitar compra'
                    dataValues={solicitarCompraValues(params?.almacen_id)}
                    urlApi={`/compra`}
                    method={'post'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {/*{
                updateModal &&
                <ModalForm
                    setModal={setUpdateModal}
                    label='Editar almacén'
                    dataValues={UpdateValues(currentData)}
                    urlApi={'/almacen'}
                    method={'put'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Editar'
                />
            }*/}
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar producto del almacén'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacen_producto/${currentData.id}`}
                    method={'delete'}
                    call={() => {
                        recall()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Eliminar'
                />
            }
            {
                stepCompraModal &&
                <ModalStep
                    setModal={setStepCompraModal}
                    label='Detalles de la compra del producto'
                >
                    <StepCompraViews
                        data={currentData}
                        setReload={() => recall()}
                        // stepsPermissions={[1, 3, 4, 5]}
                        stepsPermissions={{ buttonsActive: [1, 3, 4, 5, 6], viewSteps: [1, 3, 4, 5, 6] }}
                    />
                </ModalStep>
            }
        </Section>
    )
}