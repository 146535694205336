import React, { useEffect, useState } from 'react'
import { requestAuth } from '../../../http/httpRequest'
import { toastOn } from '../../../store/slices/toast'
import { useDispatch } from 'react-redux'
import { TagsStepCompraDictionary } from '../../../utils/dictionary'
import { ButtonSubmit, Loader, TitleFormData } from './aditionalComponents'
import { formatDateNumberWithTime } from '../../../utils/dateFormat'
import { Form, Formik } from 'formik'
import { ModalFormField } from '../ModalFormField'
import { CompraProductosPaso7 } from '../../../FormSchemes/CompraScheme'
import { ModalPdf } from '../ModalPdf'
import CompraPdf from './CompraPdf'
import { Actions } from '../../form/actions'

export const Step7 = ({ generalData, setMainRecall, setReload, stepNumber, saveUrlApi, stepsPermissions }) => {
  const dispatch = useDispatch()
  const [currentData, setCurrentData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [generalStates, setGeneralStates] = useState({
    compraPDF: false,
  })

  const getData = async () => {
    await requestAuth(
      'get',
      `compra/${generalData?.id}/fullDetail`,
      null
    )
      .then((response) => {
        setCurrentData(response.data)
        setLoading(false)
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const onSubmit = async (values) => {
    await requestAuth(
      'put',
      saveUrlApi,
      values
    )
      .then((response) => {
        dispatch(toastOn({ type: 'success', message: response.data.message }))
        setMainRecall(a => !a)
        setReload()
        setLoading(x => !x)
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message ?? 'Error al enviar el formulario!' }))
      })
  }
  return (
    <>
      <TitleFormData
        title={TagsStepCompraDictionary[stepNumber]}
      />
      {
        loading ?
          <Loader />
          :
          <>
            <div>
              <Actions
                buttons={[
                  {
                    icon: 'file-pdf',
                    label: 'IMPRIMIR PLANILLA DE COMPRA PDF',
                    action: () => setGeneralStates(x => ({ ...x, compraPDF: true })),
                    className: 'dark:bg-pink-600 bg-pink-500 text-white',
                    disabled: false
                  },
                ]}
              />
              <div className='p-2 grid grid-cols-12'>
                <ListItem
                  label={'Precinto:'}
                  text={currentData.precinto}
                />
                <ListItem
                  label={'Almacén:'}
                  text={currentData.almacen_nombre}
                />
                <ListItem
                  label={'Proveedor:'}
                  text={currentData.almacen_proveedor_nombre}
                />
                <ListItem
                  label={'Transportista:'}
                  text={currentData.transportista_nombre}
                />
                <ListItem
                  label={'Fecha de solicitud:'}
                  text={formatDateNumberWithTime(currentData.fecha_solicitud)}
                />
                <ListItem
                  label={'Fecha de confirmación de compra:'}
                  text={formatDateNumberWithTime(currentData.fecha_confirmacion_compra)}
                />
                <ListItem
                  label={'Fecha de confirmación de salida:'}
                  text={formatDateNumberWithTime(currentData.fecha_confirmacion_salida)}
                />
                <ListItem
                  label={'Fecha de llegada al proveedor:'}
                  text={formatDateNumberWithTime(currentData.fecha_entrega_proveedor)}
                />
                <ListItem
                  label={'Fecha de salida del proveedor:'}
                  text={formatDateNumberWithTime(currentData.fecha_planificacion_llegada)}
                />
                <ListItem
                  label={'Fecha de conclusión:'}
                  text={formatDateNumberWithTime(currentData.fecha_fin)}
                />
                {
                  stepsPermissions?.viewSteps?.includes(7) &&
                  <>
                    <ListItem
                      label={'Compra Bs.:'}
                      text={currentData.monto_compra?.toLocaleString('es-BO', { minimumFractionDigits: 2 })}
                    />
                    <ListItem
                      label={'Flete Bs.:'}
                      text={currentData.monto_flete?.toLocaleString('es-BO', { minimumFractionDigits: 2 })}
                    />
                    <ListItem
                      label={'Otros gastos Bs.:'}
                      text={currentData.monto_otros?.toLocaleString('es-BO', { minimumFractionDigits: 2 })}
                    />
                    <ListItem
                      label={'Total Bs.:'}
                      text={currentData.monto?.toLocaleString('es-BO', { minimumFractionDigits: 2 })}
                    />
                  </>
                }
              </div>
              <h2 className='mb-2 font-semibold tracking-normal text-gray-900 dark:text-white'>Retorno de productos y envases</h2>
              <div className='grid grid-cols-2 sm:grid-cols-4 gap-3'>
                {
                  currentData?.retornoMap?.map((retorno, index) => (
                    <MiniCardItem
                      key={index}
                      cantidad={retorno.cantidad}
                      text={retorno.etiqueta}
                    />
                  ))
                }
              </div>
            </div>
            <Formik initialValues={
              {
                precinto: currentData?.precinto ?? '',
                obs_solicitud_compra: currentData?.obs_solicitud_compra,
                obs_confirmacion_compra: currentData?.obs_confirmacion_compra,
                obs_confirmacion_salida: currentData?.obs_confirmacion_salida,
                obs_distribucion_envase: currentData?.obs_distribucion_envase,
                obs_cantidad_retorno: currentData?.obs_cantidad_retorno,
                obs_confirmacion_llegada: currentData?.obs_confirmacion_llegada,
              }
            } onSubmit={onSubmit}>
              {({ errors, isSubmitting, touched, values, submitForm, handleChange, setFieldValue }) => (
                <>
                  <Form className='col-span-12 grid grid-cols-12 bg-white rounded-lg dark:bg-gray-700 gap-3'>
                    <ModalFormField
                      fields={CompraProductosPaso7()?.fields}
                      errors={errors}
                      touched={touched}
                      values={values}
                      setValues={setFieldValue}
                      handleChange={handleChange}
                      // calculators={CompraProductosPaso2()?.calculators}
                      submitForm={submitForm}
                    />
                    <div className='col-span-12 text-right mt-3'>
                      <div className='col-span-12 text-right mt-3'>
                        <ButtonSubmit
                          isSubmitting={isSubmitting}
                          disabled={generalData?.paso === stepNumber && stepsPermissions?.buttonsActive?.includes(5) ? false : true}
                          textButton='Guardar'
                        />
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </>
      }
      {
        generalStates.compraPDF &&
        <ModalPdf
          setModal={() => setGeneralStates(x => ({ ...x, compraPDF: false }))}
          width={'md:max-w-5xl'}
        >
          <CompraPdf compraId={generalData?.id} viewAmount={stepsPermissions?.viewSteps?.includes(7)} />
        </ModalPdf>
      }
    </>
  )
}

const ListItem = ({ text, label }) => {
  return (
    <div className='col-span-12 grid grid-cols-4 text-sm'>
      <div className='col-span-4 sm:col-span-1 flex space-x-2 items-center '>
        <i className='fa-solid fa-check-circle text-green-500' />
        <span className='dark:text-gray-200'>
          {label}
        </span>
      </div>
      <div className='text-sm col-span-4 sm:col-span-3 dark:text-gray-200'>{text}</div>
    </div>
  )
}

const MiniCardItem = ({ cantidad, text }) => {
  return (
    <div className='text-center border border-t-2 border-r-2 border-b-2 border-l-2 border-gray-300 rounded-xl p-1'>
      <div className='text-2xl font-bold text-gray-600 dark:text-gray-300'>{cantidad}</div>
      <div className='text-gray-600 dark:text-gray-300 font-semibold'>{text}</div>
    </div>
  )
}