import React, { useState } from 'react'
import { ListAlmacenProductoTable } from './ListAlmacenProductoTable'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../../error/errorPage'
import { AlmacenHistorials } from './almacen_historials'
import { AlmacenCierresHistorial } from './almacen_cierres'
import CardInfo from '../elementosGenerales/CardInfo'
import { BackButton } from '../../../../../components/buttons/ButtonDefault'

export const AlmacenProductos = () => {
    const [data, setData] = useState(null);
    return (
        <>

            {/* </TabTableSection> */}
            <Routes>
                <Route index element={
                    <div className='relative'>
                        <BackButton />
                        <CardInfo
                            data={data}
                            setData={setData}
                        />
                        <ListAlmacenProductoTable
                            dataCard={data}
                        />
                    </div>
                } />
                <Route
                    path={`/historial/:almacen_producto_id`}
                    element={<AlmacenHistorials />}
                />
                <Route
                    path={`/cierres/:almacen_producto_id/*`}
                    element={<AlmacenCierresHistorial />}
                />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </>
    )
}
