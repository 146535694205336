import React from 'react'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { TabContainer } from '../../../../components/tab/TabContainer'
import { TabItem } from '../../../../components/tab/TabItem'
import { VerificationPathByString } from '../../../../utils'
import { ErrorPage } from '../../../error/errorPage'
// import { VentasPorVendedorProductos } from './VentasPorVendedorProductos'
// import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
// import { VentasPorProductos } from './VentasPorProductos'
import { AlmacenPorVendedor } from './AlmacenPorVendedor'
import { AlmacenPorVentaDeOficina } from './AlmacenPorVentaDeOficina'
import { AlmacenEstadoEmpresasMayoristas } from './AlmacenEstadoEmpresasMayoristas'
import { formatDateWithTime } from '../../../../utils/dateFormat'
import { AlmacenDeudaEnvasePorVendedor } from './AlmacenDeudaEnvasePorVendedor'

export const InformeGeneralDeAlmacenTab = ({ tipo }) => {
    const {
        Section,
    } = useGeneralParams('')

    const DefaultParams = {
        transportistas: {
            headers: [
                {
                    label: 'Fecha de registro',
                    columns: ['createdAt'],
                    transform: true,
                    func: formatDateWithTime
                },
                {
                    label: 'Transportista',
                    columns: ['transportista_nombre:transportista_detalles']
                },
                {
                    label: 'ingreso / egreso',
                    columns: ['ingreso'],
                    booleanState: true,
                    booleanOptions: ['Ingreso', 'Egreso']
                },
                {
                    label: 'Cantidad',
                    columns: ['cantidad'],
                    numberValue: true,
                    sum: true
                },
                {
                    label: 'Concepto',
                    columns: ['tipo'],
                },
            ],
            aditionalColsRef: ''
        },
        ingEgrAlmacenOtros: {
            headers: [
                {
                    label: 'Almacén',
                    columns: ['actual_almacen_nombre']
                },
                {
                    label: 'Producto',
                    columns: ['producto_nombre', 'producto_estado']
                },
                {
                    label: 'Alm. Orig./Dest.',
                    columns: ['nombreAlmDestino']
                },
                {
                    label: 'Transportista',
                    columns: ['transporNombre']
                },
                {
                    label: 'Encargado',
                    columns: ['encarNombre']
                },
                {
                    label: 'Ingr./Egre.',
                    columns: ['ingreso'],
                    booleanState: true,
                    booleanOptions: ['Ingreso', 'Egreso']
                },
                {
                    label: 'Cantidad',
                    columns: ['cantidad'],
                    numberValue: true,
                    sum: true
                },
                {
                    label: 'Concepto',
                    columns: ['tipo'],
                },
                {
                    label: 'Fecha de registro',
                    columns: ['createdAt'],
                    transform: true,
                    func: formatDateWithTime
                },
            ],
            aditionalColsRef: ''
        }
    }

    return (
        <Section>
            <TabContainer>
                {
                    [
                        ...(
                            tipo === 'vendedores' ?
                                [
                                    {
                                        to: 'cantidad-almacenes-por-vendedor',
                                        label: 'CANTIDAD EN ALMACENES POR VENDEDORES'
                                    },
                                    {
                                        to: 'cantidad-garantias-vendedores',
                                        label: 'CANTIDAD DE GARANTÍAS POR VENDEDORES'
                                    },
                                    {
                                        to: 'cantidad-prestamos-vendedores',
                                        label: 'CANTIDAD DE PRÉSTAMOS POR VENDEDORES'
                                    },
                                    {
                                        to: 'detalles-de-envases',
                                        label: 'DETALLES DE ENVASES DE VENDEDORES'
                                    },
                                ]
                                :
                                tipo === 'empresas-y-mayoristas' ?
                                    [
                                        {
                                            to: 'venta-oficina-con-contenido-cantidad-empresa',
                                            label: 'CANTIDAD DE VENTAS DE OFICINA EMPRESAS (POR ENCARGADO)'
                                        },
                                        {
                                            to: 'venta-oficina-con-contenido-cantidad-mayorista',
                                            label: 'CANTIDAD DE VENTAS DE OFICINA MAYORISTAS (POR ENCARGADO)'
                                        },
                                        {
                                            to: 'venta-con-contenido-cantidad-empresa',
                                            label: 'CANTIDAD DE VENTAS A EMPRESAS'
                                        },
                                        {
                                            to: 'venta-con-contenido-cantidad-mayorista',
                                            label: 'CANTIDAD DE VENTAS A MAYORISTAS'
                                        },
                                        {
                                            to: 'estado-envases-empr-may-empresa',
                                            label: 'CANTIDAD DE ENVASES EMPRESAS'
                                        },
                                        {
                                            to: 'estado-envases-empr-may-mayorista',
                                            label: 'CANTIDAD DE ENVASES MAYORISTAS'
                                        },
                                    ]
                                    :
                                    [
                                        {
                                            to: 'estado-ventas',
                                            label: 'ESTADO ACTUAL DE ALMACÉN'
                                        },
                                        {
                                            to: 'estado-otros-productos',
                                            label: 'ESTADO ACTUAL DE ALMACÉN DE OTROS PRODUCTOS'
                                        },
                                        {
                                            to: 'ingresos-egresos-otros-producto',
                                            label: 'INGRESOS Y EGRESOS DE OTROS PRODUCTO'
                                        },
                                        {
                                            to: 'transportistas-tcc',
                                            label: 'MOVIMIENTO TRANSPORTISTAS TCC'
                                        },
                                        {
                                            to: 'transportistas-tv',
                                            label: 'MOVIMIENTO TRANSPORTISTAS TV'
                                        },
                                    ]
                        ),
                    ].map((i, idx) => (
                        <TabItem
                            key={idx}
                            to={i.to}
                            label={i.label}
                            active={VerificationPathByString('/' + i.to)}
                            minW
                            isChart
                        />
                    ))
                }
            </TabContainer>
            <Routes>
                <Route
                    index
                    element={<Navigate to={tipo === 'vendedores' ? 'cantidad-almacenes-por-vendedor' : tipo === 'empresas-y-mayoristas' ? 'venta-oficina-con-contenido-cantidad-empresa' : 'estado-ventas'} />}
                />
                <Route
                    path='cantidad-almacenes-por-vendedor'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVendedor
                                title='INGRESOS Y EGRESOS CON CONTENIDO Y DE VACIOS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenPorVendedor/lt200/pag'
                                isAmount={true}
                            />
                            <AlmacenPorVendedor
                                title='INGRESOS Y EGRESOS CON CONTENIDO Y DE VACIOS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenPorVendedor/lt100/pag'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='cantidad-garantias-vendedores'
                    element={
                        <TabBodyContainer>
                            <AlmacenDeudaEnvasePorVendedor
                                title='CANTIDAD DE GARANTÍAS POR VENDEDORES'
                                // subtitle='Turriles de 200L'
                                urlApi='/deudaEnvases/garantía/pag'
                                isAmount={true}
                                tipoDeuda={'garantía'}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='cantidad-prestamos-vendedores'
                    element={
                        <TabBodyContainer>
                            <AlmacenDeudaEnvasePorVendedor
                                title='CANTIDAD DE PRÉSTAMOS POR VENDEDORES'
                                // subtitle='Turriles de 200L'
                                urlApi='/deudaEnvases/préstamo/pag'
                                isAmount={true}
                                tipoDeuda={'préstamo'}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='venta-oficina-con-contenido-cantidad-empresa'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA DE OFICINA EMPRESAS (POR ENCARGADO)'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=cantidad&rolname=empresa&oficina=true'
                                isAmount={false}
                                isNotOficina={true}
                            />
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA DE OFICINA EMPRESAS (POR ENCARGADO)'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=cantidad&rolname=empresa&oficina=true'
                                isAmount={false}
                                isNotOficina={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='venta-oficina-con-contenido-cantidad-mayorista'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA DE OFICINA MAYORISTAS (POR ENCARGADO)'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=cantidad&rolname=mayorista&oficina=true'
                                isAmount={false}
                                isNotOficina={true}
                            />
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA DE OFICINA MAYORISTAS (POR ENCARGADO)'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=cantidad&rolname=mayorista&oficina=true'
                                isAmount={false}
                                isNotOficina={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='venta-con-contenido-cantidad-empresa'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA A EMPRESAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=cantidad&rolname=empresa&oficina=false'
                                isAmount={false}
                            />
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA A EMPRESAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=cantidad&rolname=empresa&oficina=false'
                                isAmount={false}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='venta-con-contenido-cantidad-mayorista'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA A MAYORISTAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=cantidad&rolname=mayorista&oficina=false'
                                isAmount={false}
                            />
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA A MAYORISTAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=cantidad&rolname=mayorista&oficina=false'
                                isAmount={false}
                            />
                        </TabBodyContainer>
                    } />
                {/* <Route
                    path='venta-oficina-con-contenido-efectivo'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='EFECTIVO DE VENTA DE OFICINA EMPRESAS Y MAYORISTAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=efectivo_total'
                                isAmount={true}
                            />
                            <AlmacenPorVentaDeOficina
                                title='EFECTIVO DE VENTA DE OFICINA EMPRESAS Y MAYORISTAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=efectivo_total'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } /> */}
                <Route
                    path='estado-envases-empr-may-empresa'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='CANTIDAD DE ENVASES EMPRESAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenDetallesEmpMay/lt200/pag?rolname=empresa'
                                isAmount={false}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='CANTIDAD DE ENVASES EMPRESAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenDetallesEmpMay/lt100/pag?rolname=empresa'
                                isAmount={false}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='estado-envases-empr-may-mayorista'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='CANTIDAD DE ENVASES MAYORISTAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenDetallesEmpMay/lt200/pag?rolname=mayorista'
                                isAmount={false}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='CANTIDAD DE ENVASES MAYORISTAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenDetallesEmpMay/lt100/pag?rolname=mayorista'
                                isAmount={false}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='detalles-de-envases'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='DETALLES DE ENVASES DE VENDEDORES'
                                subtitle='Turriles de 200L'
                                urlApi='informe/detallesEnvases/lt200/pag'
                                isAmount={false}
                                isNotOficina={true}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='DETALLES DE ENVASES DE VENDEDORES'
                                subtitle='Turriles de 100L'
                                urlApi='informe/detallesEnvases/lt100/pag'
                                isAmount={false}
                                isNotOficina={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='estado-ventas'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='ESTADO ACTUAL DE ALMACÉN'
                                subtitle='Turriles de 200L'
                                urlApi='/informe/EstadoActual/lt200/pag'
                                isAmount={false}
                                isNotOficina={true}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='ESTADO ACTUAL DE ALMACÉN'
                                subtitle='Turriles de 100L'
                                urlApi='/informe/EstadoActual/lt100/pag'
                                isAmount={false}
                                isNotOficina={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='estado-otros-productos'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='ESTADO ACTUAL DE ALMACÉN DE OTROS PRODUCTOS'
                                subtitle=''
                                urlApi='/informe/EstadoActualOtros/pag?rolname=all'
                                isAmount={false}
                                isNotOficina={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='transportistas-tcc'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='MOVIMIENTO TRANSPORTISTAS TCC'
                                subtitle='Turriles de 200L'
                                urlApi='/informe/AlmacenHistorial/AC - 200LT/pag?rolname=transportista&estado_nombre=TCC'
                                isAmount={false}
                                isNotOficina={false}
                                headers={DefaultParams.transportistas.headers}
                                aditionalColsRef={DefaultParams.transportistas.aditionalColsRef}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='MOVIMIENTO TRANSPORTISTAS TCC'
                                subtitle='Turriles de 100L'
                                urlApi='/informe/AlmacenHistorial/AC - 100LT/pag?rolname=transportista&estado_nombre=TCC'
                                isAmount={false}
                                isNotOficina={false}
                                headers={DefaultParams.transportistas.headers}
                                aditionalColsRef={DefaultParams.transportistas.aditionalColsRef}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='transportistas-tv'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='MOVIMIENTO TRANSPORTISTAS TV'
                                subtitle='Turriles de 200L'
                                urlApi='/informe/AlmacenHistorial/AC - 200LT/pag?rolname=transportista&estado_nombre=TV'
                                isAmount={false}
                                isNotOficina={false}
                                headers={DefaultParams.transportistas.headers}
                                aditionalColsRef={DefaultParams.transportistas.aditionalColsRef}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='MOVIMIENTO TRANSPORTISTAS TV'
                                subtitle='Turriles de 100L'
                                urlApi='/informe/AlmacenHistorial/AC - 100LT/pag?rolname=transportista&estado_nombre=TV'
                                isAmount={false}
                                isNotOficina={false}
                                headers={DefaultParams.transportistas.headers}
                                aditionalColsRef={DefaultParams.transportistas.aditionalColsRef}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='ingresos-egresos-otros-producto'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='INGRESOS Y EGRESOS DE OTROS PRODUCTO'
                                // subtitle='Turriles de 200L'
                                urlApi='/almacen/0/historialGeneral/pag?tipoProd=no-retornable'
                                isAmount={false}
                                isNotOficina={false}
                                headers={DefaultParams.ingEgrAlmacenOtros.headers}
                                aditionalColsRef={DefaultParams.ingEgrAlmacenOtros.aditionalColsRef}
                            />
                        </TabBodyContainer>
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Section>
    )
}

const TabBodyContainer = ({ children }) => {
    return (
        <div className='grid gap-1'>
            {children}
        </div>
    )
}