import React from 'react'
import { ListCuadernoTable } from './ListCuadernoTable'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../error/errorPage'
import { CuadernoHistorial } from './cuaderno_historial'
import { Title1 } from '../../../../components/text/titles'

export const Cuaderno = () => {
    return (
        <Routes>
            <Route index element={
                <div className='flex flex-col gap-4'>
                    {
                        [
                            [true, null, 'CUADERNOS ABIERTOS'],
                            [false, null, 'CUADERNOS CERRADOS'],
                            [null, true, 'CUADERNOS REVISADOS'],
                        ].map((i, index) => (
                            <div key={index} className='bg-gray-50 dark:bg-gray-800/50 rounded-sm p-2'>
                                <Title1 text={i[2]} />
                                <ListCuadernoTable
                                    abierto={i[0]}
                                    revisado={i[1]}
                                />
                            </div>
                        ))
                    }
                </div>
            } />
            <Route
                path={`/historial/:cuaderno_id/*`}
                element={
                    <CuadernoHistorial
                    />
                }
            />
            {/* <Route
                    path={`/almacen/:cuaderno_id/*`}
                    element={<CuadernoAlmacen
                        mainReloadTable={mainReloadTable}
                        setMainReloadTable={setMainReloadTable}
                    />}
                /> */}
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}