import React, { useState } from 'react'
import CardInfoCli from './CardInfo'
import { ClienteTab } from './ClienteTab'
import { BackButton } from '../../../../../components/buttons/ButtonDefault';

export const PerfilCliente = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <div className='relative'>
            <BackButton />
            <CardInfoCli
                reload={reload}
                data={data}
                setData={setData}
            />
            <ClienteTab
                reload={reload}
                setReload={setReload}
                dataCard={data}
            />
        </div>
    )
}