import React, { useState } from 'react'

import CardInfo from '../elementosGenerales/CardInfo'
import { ListComprasTable } from './ListComprasTable'
import { BackButton } from '../../../../../components/buttons/ButtonDefault'

export const Compras = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <div className='relative'>
            <BackButton />
            <CardInfo
                reload={reload}
                data={data}
                setData={setData}
            />
            <ListComprasTable
                reload={reload}
                setReload={setReload}
                dataCard={data}
            />
        </div>
    )
}
