import React, { useState } from 'react'
import { TabContainer } from '../../../../../components/tab/TabContainer'
import { TabItem } from '../../../../../components/tab/TabItem'
import { useGeneralParams } from '../../../../../hooks/useDataPaginate'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ErrorPage } from '../../../../error/errorPage'
import { VerificationPathByString } from '../../../../../utils'
import { ListVendedorDeudaTable } from './ListVendedorDeudaTable'
import { ListVendedorPrestamoTable } from './ListVendedorPrestamoTable'
import { ListVendedorGarantiaTable } from './ListVendedorGarantiaTable'
import { ActionSection } from '../../../../../components/table/ActionSection'
import { Actions } from '../../../../../components/form/actions'
import { ModalForm } from '../../../../../components/modals/ModalForm'
import { AdicionarClientesValues } from '../../../../../FormSchemes/VendedorScheme'
import { ListClientesTable } from './ListClientesTable'
import { useDispatch, useSelector } from 'react-redux'
import { setReloadMainTable } from '../../../../../store/slices/auth'
import { ListCuadernoTable } from '../../cuadernos/ListCuadernoTable'

export const VendedorTab = ({
    reload,
    setReload,
    dataCard
}) => {
    const {
        params,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        Section,
    } = useGeneralParams('')
    const [recallState] = useState(false)
    const dispatch = useDispatch()
    const [asignarClientesModal, setAsignarClientesModal] = useState(false)
    const lock = useSelector(state => state.login.rol?.limitar_permisos)

    return (
        <Section>
            {
                !lock &&
                <ActionSection>
                    <Actions
                        buttons={[
                            {
                                icon: 'add',
                                label: 'Asignar clientes',
                                action: () => setAsignarClientesModal(true)
                            },
                        ]}
                    />
                </ActionSection>
            }
            <TabContainer>
                <TabItem
                    to='clientes'
                    label='Clientes'
                    active={VerificationPathByString('/clientes')}
                />
                <TabItem
                    to='deudas'
                    label='Deudas'
                    active={VerificationPathByString('/deudas')}
                />
                <TabItem
                    to='prestamos'
                    label='Prestamos'
                    active={VerificationPathByString('/prestamos')}
                />
                <TabItem
                    to='garantias'
                    label='Garantias'
                    active={VerificationPathByString('/garantias')}
                />
                <TabItem
                    to='cuadernos'
                    label='Cuadernos'
                    active={VerificationPathByString('/cuadernos')}
                />
            </TabContainer>
            <Routes>
                <Route index element={
                    <ListClientesTable
                        reload={reload}
                        setReload={setReload}
                        params={params}
                        setSelectAllChecked={setSelectAllChecked}
                        setIsChecked={setIsChecked}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        selectAllChecked={selectAllChecked}
                        isChecked={isChecked}
                        recallState={recallState}
                    />
                } />
                <Route
                    path='clientes'
                    element={
                        <ListClientesTable
                            reload={reload}
                            setReload={setReload}
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='deudas'
                    element={
                        <ListVendedorDeudaTable
                            reload={reload}
                            setReload={setReload}
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='prestamos'
                    element={
                        <ListVendedorPrestamoTable
                            reload={reload}
                            setReload={setReload}
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='garantias'
                    element={
                        <ListVendedorGarantiaTable
                            reload={reload}
                            setReload={setReload}
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                        />
                    } />
                <Route
                    path='cuadernos'
                    element={
                        <ListCuadernoTable
                            reload={reload}
                            setReload={setReload}
                            params={params}
                            setSelectAllChecked={setSelectAllChecked}
                            setIsChecked={setIsChecked}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            selectAllChecked={selectAllChecked}
                            isChecked={isChecked}
                            recallState={recallState}
                            isAnyShow={true}
                        />
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
            {
                asignarClientesModal &&
                <ModalForm
                    setModal={setAsignarClientesModal}
                    label='Asignar clientes'
                    dataValues={AdicionarClientesValues(dataCard)}
                    urlApi={`/vendedor/${dataCard.id}/asignarClientes`}
                    method={'post'}
                    call={() => dispatch(setReloadMainTable())}
                    buttonLabel='Registrar'
                />
            }
        </Section>
    )
}
