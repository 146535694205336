import React, { useState } from 'react'
import CardInfo from '../../cuadernos/cuaderno_historial/CardInfo';
import { CuadernoTab } from './CuadernoTab';
import { BackButton } from '../../../../../components/buttons/ButtonDefault';


export const CuadernoMovimientos = () => {
    const [reload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <div className='relative'>
            <BackButton />
            <CardInfo
                reload={reload}
                data={data}
                setData={setData}
                globalParams={{}}
            />
            <CuadernoTab
                dataCard={data}
            />
        </div>
    )
}
