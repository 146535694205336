import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { Actions } from '../../../../components/form/actions'
import { RegistroDeudaDirectaValues } from '../../../../FormSchemes/DeudaScheme'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { UpdateValuesModal } from '../../../../FormSchemes/GeneralFunctions'
import { DeleteValues } from '../../../../FormSchemes/CuadernoScheme'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../hooks/Callers'

export const ListDeudaTable = () => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        // imports
        requestAuthPaginate,
        TableContainer,
        formatDateWithTime,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        TableSection,
        ActionSection,
        ModalForm,
        Section,
        redirect
    } = useGeneralParams()
    const [registrarDeudaDirectaModal, setRegistrarDeudaDirectaModal] = useState(false)
    const [DeleteModal, setDeleteModal] = useState(false)
    const [CurrentData, setCurrentData] = useState(false)
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/deudas/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: '$Producto.nombre$' }
        })
    }

    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.tipo_accion, reloadMainTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.tipo_accion, reloadMainTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }
    return (
        <Section>
            <ActionSection>
                {
                    <Actions
                        buttons={[
                            {
                                icon: 'minus',
                                label: 'Registrar deuda directa',
                                action: () => setRegistrarDeudaDirectaModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                        ]}
                    />
                }
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    width='6/12'
                    options={[
                        {
                            label: 'Producto',
                            value: '$Producto.nombre$'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo_accion'
                        options={[
                            {
                                label: 'Tipo de acción',
                                value: ''
                            },
                            {
                                label: 'Pendientes',
                                value: 'pendientes'
                            },
                            {
                                label: 'Pagadas',
                                value: 'pagadas'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Encargado',
                            columns: ['encargadoCodigo', 'encargadoDetalles'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Deudor',
                            columns: ['deudorCodigo', 'deudorDetalles'],
                            // icon: 'fa-solid fa-user-circle text-lg',
                            // skipLine: true,
                            className: 'flex items-center',
                            // stickyL: true
                        },
                        {
                            label: 'Producto',
                            columns: ['producto'],
                        },
                        {
                            label: 'Detalles',
                            columns: ['tipo'],
                        },
                        {
                            label: 'Cuaderno',
                            columns: ['cuaderno'],
                        },
                        {
                            label: 'Monto inicial Bs.',
                            columns: ['saldo_inicial'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Saldo Bs.',
                            columns: ['saldo'],
                            numberValue: true,
                            sumDec: true
                        },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            boolean: true,
                            booleanOptions: ['Completado', 'Pendiente'],
                            booleanIconTrue: 'fa-check',
                            booleanIconFalse: 'fa-circle'
                        },
                        {
                            label: 'Fecha de Prestamo',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Fecha límite',
                            columns: ['fecha_limite'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'cyan',
                                    icon: 'fa-share-from-square',
                                    action: (data) => {
                                        redirect(`${data.id}`)
                                    },
                                    reference: 'id',
                                },
                                {
                                    type: 'delete',
                                    icon: 'fa-trash',
                                    action: (data) => UpdateValuesModal(data, setCurrentData, setDeleteModal),
                                    reference: 'id'
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    doubleClickAction={(dataItem) => redirect(`${dataItem.id}`)}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                registrarDeudaDirectaModal &&
                <ModalForm
                    setModal={setRegistrarDeudaDirectaModal}
                    label='Registrar deuda inmediata'
                    dataValues={RegistroDeudaDirectaValues()}
                    urlApi={`/deudaDirecta`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        // dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                DeleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar deuda'
                    dataValues={DeleteValues(CurrentData)}
                    urlApi={`/deudaDirecta/${CurrentData.id}`}
                    method={'delete'}
                    call={reloadTable}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}