import React from 'react'

export const Actions = ({ buttons }) => {
    const hover = 'hover:bg-gray-500 hover:text-gray-100 dark:hover:bg-gray-700 hover:border-gray-600'
    return (
        buttons?.length > 0 &&
        <div className='flex gap-1 flex-wrap items-center'>
            {
                buttons.map(({ icon, label, action, className = 'bg-blue-500 dark:bg-sky-700 text-white dark:text-gray-200', disabled = false }, index) => (
                    <button
                        key={index}
                        type='button'
                        disabled={disabled}
                        // title='asd'
                        className
                        ={`${disabled ? 'dark:bg-gray-700/30 bg-gray-300/60 text-gray-500 dark:text-gray-500' : `${className} ${hover}`} border-gray-300 dark:border-gray-800 dark:hover:border-gray-800 border-b-2 border-r-2 ease-in duration-75 font-semibold px-2 rounded-md flex flex-wrap items-center text-left min-h-[30px] gap-2`}
                        onClick={action}
                    >
                        <i className={`fa fa-${icon} text-xs`} />{label && <span className='text-[13px]'> {label}</span>}
                    </button>
                ))
            }
        </div>
    )
}