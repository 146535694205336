import React from 'react'
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { ErrorPage } from '../../../error/errorPage';
import { ListEfectivoTable } from './ListEfectivoTable';
import { EfectivoCierresHistorial } from './efectivo_cierres';
import { EfectivoHistorial } from './efectivo_historial';
import { SubEfectivoIndex } from './SubEfectivoIndex';
import { useDispatch, useSelector } from 'react-redux';
import { setReloadMainTable, setReloadMinorTable, setReloadSubTable } from '../../../../store/slices/auth/authSlice';

export const Efectivo = ({ globalParams }) => {
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    const reloadSubTable = useSelector(state => state.login.reloadSubTable)

    const dispatch = useDispatch()
    return (
        <Routes>
            <Route index element={
                <ListEfectivoTable
                    globalParams={globalParams}
                    reloaders={[reloadMainTable]}
                    dispatchs={() => {
                        dispatch(setReloadMainTable())
                        dispatch(setReloadSubTable())
                        dispatch(setReloadMinorTable())
                    }}
                />
            } />
            <Route
                path={`/:efectivo_id/*`}
                element={<SubEfectivoIndex
                    globalParams={globalParams}
                    efectivoParams={{
                        type: 'subEfectivo'
                    }}
                    reloaders={[reloadSubTable]}
                    dispatchs={() => {
                        dispatch(setReloadMainTable())
                        dispatch(setReloadSubTable())
                        dispatch(setReloadMinorTable())
                    }}
                />}
            />
            <Route
                path={`/historial/:efectivo_id/*`}
                element={<EfectivoHistorial
                />}
            />
            <Route
                path={`/cierres/:efectivo_id/*`}
                element={<EfectivoCierresHistorial
                    reloaders={[reloadSubTable]}
                />}
            />
            <Route path='*' element={<ErrorPage />} />
        </Routes>
    )
}