import React, { useEffect, useState } from 'react'
import { ButtonSubmit, Divisor, InputDataList, Loader, SimpleAlert, TitleFormData } from './aditionalComponents'
import { requestAuth } from '../../../http/httpRequest'
import { toastOn } from '../../../store/slices/toast'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { TagsStepCompraDictionary } from '../../../utils/dictionary'
import { ModalFormField } from '../ModalFormField'
import { CompraProductosPaso4 } from '../../../FormSchemes/CompraScheme'

export const Step4 = ({ generalData, setMainRecall, setReload, saveUrlApi, stepNumber, stepsPermissions }) => {
  const dispatch = useDispatch()
  const [currentData, setCurrentData] = useState(null)
  const [vaciosDistribucion, setVaciosDistribucion] = useState([])
  // const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  // const [options, setOptions] = useState([])
  // const [defaultValue, setDefaultValue] = useState(null)

  const initialValues = {
    // transportista_id: generalData?.transportista_id ?? '',
    productos: vaciosDistribucion,
    fecha_entrega_proveedor: currentData?.fecha_entrega_proveedor,
    obs_distribucion_envase: currentData?.obs_distribucion_envase ?? '',
    // monto: generalData?.monto ?? 0,
  };

  const getData = async () => {
    await requestAuth(
      'get',
      `compra/${generalData?.id}/fullDetail`,
      null
    )
      .then((response) => {
        setCurrentData(response.data)
        setVaciosDistribucion(response.data.vaciosDistribucionMap)

        // setData(response.data.vaciosDistribucionMap.map(s => ({ 'almacen_producto_id': s.id + '', 'cantidad': 0 })))


        // const opt = response.data?.transportistasMap?.map(item => ({
        //   value: item['id'] ?? item.id.toString(),
        //   label: item['etiqueta']
        // }))
        // if (response?.data?.transportista_id)
        // setDefaultValue({ value: response?.data?.transportista_id, label: response?.data?.transportista_nombre })

        // setOptions(opt)

        setLoading(false)
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const addData = (value, name) => {
  //   const newData = data.map(item => {
  //     if (item.compra_historial_id === name) {
  //       return { ...item, cantidad: parseFloat(value) };
  //     }
  //     return item;
  //   })
  //   setData(newData)
  // }
  const onSubmit = async (values) => {
    await requestAuth(
      'put',
      saveUrlApi,
      values
    )
      .then((response) => {
        dispatch(toastOn({ type: 'success', message: response.data.message }))
        setMainRecall(a => !a)
        setReload()
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message ?? 'Error al enviar el formulario!' }))
      })
  }
  const distintoDeCeroCantidades = (listObj) => {
    if (listObj == null || listObj.lenght === 0)
      return false
    for (const objeto of listObj) {
      if (objeto.cantidad !== 0)
        return true
    }
    return false
  }
  return (
    <>
      <TitleFormData
        title={TagsStepCompraDictionary[stepNumber]}
        subtitle={currentData?.fecha_distribucion}
        aditionaData={'ESTABLECER CANTIDAD DE INGRESO O EGRESO EN ' + (currentData?.almacen_proveedor_nombre ?? '')}
      />
      {
        loading ?
          <Loader />
          :
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ errors, isSubmitting, touched, values, submitForm, handleChange, setFieldValue }) => (
              <>

                <InputDataList
                  list={vaciosDistribucion}
                  // addData={addData}
                  field={{ indexLabel: 'etiqueta', indexId: 'id', indexRight: 'cantidadAlmacen' }}
                  setFieldValue={setFieldValue}
                  setDataList={setVaciosDistribucion}
                />
                <Divisor />
                <Form className='col-span-12 grid grid-cols-12 bg-white rounded-lg dark:bg-gray-700 gap-3'>
                  {/* <ModalFormField
                    fields={CompraProductosPaso3(options, defaultValue)?.fields}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setFieldValue}
                    handleChange={handleChange}
                    calculators={CompraProductosPaso3()?.calculators}
                    submitForm={submitForm}
                  /> */}
                  <ModalFormField
                    fields={CompraProductosPaso4()?.fields}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setFieldValue}
                    handleChange={handleChange}
                    // calculators={CompraProductosPaso2()?.calculators}
                    submitForm={submitForm}
                  />
                  {
                    values.efectivo_id != null && values.efectivo_id !== '' &&
                    <SimpleAlert
                      data={<>Se creará un movimiento en egreso en <span className='font-semibold'>{currentData?.efectivosMap?.find(e => e.id === values.efectivo_id)?.etiqueta}</span>.</>}
                    />
                  }
                  {
                    distintoDeCeroCantidades(values.productos) &&
                    <SimpleAlert
                      data={<>Se creará un movimiento en ingreso(+) o egreso(-) para el proveedor <span className='font-semibold'>{currentData?.almacen_proveedor_nombre ?? ''}</span>.</>}
                    />
                  }
                  <div className='col-span-12 text-right mt-3'>
                    <ButtonSubmit
                      isSubmitting={isSubmitting}
                      disabled={generalData?.paso === stepNumber && stepsPermissions?.buttonsActive?.includes(4) ? false : true}
                      textButton='Siguiente'
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
      }
    </>
  )
}