import React, { useEffect, useState } from 'react'
import { ButtonSubmit, Divisor, InputDataList, Loader, SimpleAlert, TitleFormData } from './aditionalComponents'
import { requestAuth } from '../../../http/httpRequest'
import { toastOn } from '../../../store/slices/toast'
import { useDispatch } from 'react-redux'
import { Form, Formik } from 'formik'
import { TagsStepCompraDictionary } from '../../../utils/dictionary'
import { ModalFormField } from '../ModalFormField'
import { CompraProductosPaso1 } from '../../../FormSchemes/CompraScheme'

export const Step1 = ({ generalData, setMainRecall, setReload, saveUrlApi, stepNumber }) => {
  const dispatch = useDispatch()
  const [solicitados, setSolicitados] = useState([])
  const [currentData, setCurrentData] = useState(null)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const initialValues = {
    productos: solicitados,
    obs_solicitud_compra: currentData?.obs_solicitud_compra ?? ''
  };

  const getData = async () => {
    await requestAuth(
      'get',
      `compra/${generalData?.id}/fullDetail`,
      null
    )
      .then((response) => {
        setCurrentData(response.data)
        setSolicitados(response.data.solicitadosMap)

        setData(response.data.solicitadosMap.map(s => ({ 'almacen_producto_id': s.id + '', 'cantidad': 0 })))

        setLoading(false)
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message || 'error a listar las casillas.' }))
      })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addData = (value, name) => {
    const newData = data.map(item => {
      if (item.compra_historial_id === name) {
        return { ...item, cantidad: parseFloat(value) };
      }
      return item;
    })
    setData(newData)
  }
  const onSubmit = async (values) => {
    await requestAuth(
      'put',
      saveUrlApi,
      values
    )
      .then((response) => {
        dispatch(toastOn({ type: 'success', message: response.data.message }))
        setMainRecall(a => !a)
        setReload()
      }
      )
      .catch(error => {
        dispatch(toastOn({ type: 'danger', message: error?.response?.data?.message ?? 'Error al enviar el formulario!' }))
      })
  }
  return (
    <>
      <TitleFormData
        title={TagsStepCompraDictionary[stepNumber]}
        subtitle={currentData?.fecha_solicitud}
      />
      {
        loading ?
          <Loader />
          :
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ errors, isSubmitting, touched, values, submitForm, handleChange, setFieldValue }) => (
              <>

                <InputDataList
                  list={solicitados}
                  addData={addData}
                  field={{ indexLabel: 'etiqueta', indexId: 'id', indexRight: 'cantidadAlmacen' }}
                  setFieldValue={setFieldValue}
                  setDataList={setSolicitados}
                />
                <Divisor />
                <Form className='col-span-12 grid grid-cols-12 bg-white rounded-lg dark:bg-gray-700 gap-3'>
                  <ModalFormField
                    fields={CompraProductosPaso1()?.fields}
                    errors={errors}
                    touched={touched}
                    values={values}
                    setValues={setFieldValue}
                    handleChange={handleChange}
                    // calculators={CompraProductosPaso2()?.calculators}
                    submitForm={submitForm}
                  />
                  {
                    values.efectivo_id != null && values.efectivo_id !== '' &&
                    <SimpleAlert
                      data={<>Se creará un movimiento en egreso en <span className='font-semibold'>{currentData?.efectivosMap?.find(e => e.id === values.efectivo_id)?.etiqueta}</span>.</>}
                    />
                  }
                  <div className='col-span-12 text-right mt-3'>
                    <ButtonSubmit
                      isSubmitting={isSubmitting}
                      disabled={true}
                      textButton='Siguiente'
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
      }
    </>
  )
}