import React, { useEffect, useState, useRef } from 'react';
import { requestAuth } from '../../http/httpRequest';
import { useDispatch } from 'react-redux';
import { loginOut, removeToken } from '../../store/slices/auth/authSlice';

export const UserProfile = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const passwordInputRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchUserDetail = async () => {
            try {
                const response = await requestAuth('get', '/user/Detail');
                setUserData(response.data);
            } catch (err) {
                console.error('Error fetching user detail:', err);
                dispatch(loginOut());
                dispatch(removeToken());
                localStorage.removeItem('token');
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetail();
    }, [dispatch]);

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        if (!newPassword) {
            console.error('La nueva contraseña no puede estar vacía.');
            return;
        }

        try {
            await requestAuth('put', '/user/change-password', {
                id: userData.id,
                password: newPassword,
            });
            setNewPassword('');
            setIsModalOpen(false);
        } catch (err) {
            console.error('Error changing password:', err);
        }
    };

    const togglePasswordVisibility = (e) => {
        if (passwordInputRef.current) {
            passwordInputRef.current.type = e.target.checked ? 'text' : 'password';
        }
    };

    if (loading) return <p>Cargando perfil del usuario...</p>;
    if (!userData) return <p>No se encontraron datos del usuario.</p>;

    const { nombres, apellido_paterno, apellido_materno, telefono, codigo, rols, createdAt } = userData;

    return (
        <div className="p-4">
            <h2 className="text-xl font-bold mb-4 dark:text-white">Perfil del Usuario</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Tarjeta izquierda */}
                <div className="col-span-1 md:col-span-1 rounded bg-gray-50 dark:bg-gray-800 p-4 shadow-md transition-all duration-500">
                    <div className="text-center mb-4">
                        <div className="w-20 h-20 mx-auto bg-gray-200 rounded-full flex items-center justify-center">
                            <i className="fa-solid fa-user text-gray-500 text-4xl"></i>
                        </div>
                        <h3 className="text-lg font-semibold mt-2 dark:text-gray-200">{nombres}</h3>
                        <p className="text-gray-600 dark:text-gray-400">Código: {codigo || 'No disponible'}</p>
                    </div>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="mt-4 w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                    >
                        <i className="fa-solid fa-lock mr-2"></i>
                        Cambiar contraseña
                    </button>
                </div>

                {/* Tarjeta derecha */}
                <div className="col-span-1 md:col-span-1 rounded bg-gray-50 dark:bg-gray-800 p-4 shadow-md transition-all duration-500">
                    <h3 className="text-lg font-semibold mb-4 dark:text-gray-300">Detalles</h3>
                    <div className="space-y-2 dark:text-gray-300">
                        <p>
                            <i className="fa-solid fa-info-circle mr-2"></i>
                            <strong>Nombre:</strong> {nombres || 'No disponible'}
                        </p>
                        <p>
                            <i className="fa-solid fa-info-circle mr-2"></i>
                            <strong>Apellido paterno:</strong> {apellido_paterno || 'No disponible'}
                        </p>
                        <p>
                            <i className="fa-solid fa-info-circle mr-2"></i>
                            <strong>Apellido materno:</strong> {apellido_materno || '--'}
                        </p>
                        <p>
                            <i className="fa-solid fa-phone mr-2"></i>
                            <strong>Teléfono:</strong> {telefono || 'No disponible'}
                        </p>
                        <p>
                            <i className="fa-solid fa-calendar-alt mr-2"></i>
                            <strong>Fecha de creación:</strong> {new Date(createdAt).toLocaleDateString('es-ES')}
                        </p>
                        <p>
                            <i className="fa-solid fa-list-ul mr-2"></i>
                            <strong>Roles:</strong>
                        </p>
                        {rols?.length > 0 ? (
                            <ul className="list-disc pl-6">
                                {rols.map((role) => (
                                    <li key={role.id}>
                                        <strong>{role.nombre}</strong> - {role.etiqueta}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No tiene roles asignados.</p>
                        )}
                    </div>
                </div>
            </div>

            {/* Modal para cambiar contraseña */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-96">
                        {/* Header del Modal */}
                        <div className="flex justify-between items-center px-6 py-4 border-b dark:border-gray-600">
                            <h3 className="text-lg font-semibold dark:text-gray-200">Cambiar contraseña</h3>
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                            >
                                <i className="fa-solid fa-xmark text-xl"></i>
                            </button>
                        </div>

                        <div className="px-6 py-4">
                            <form onSubmit={handlePasswordChange} className="space-y-4">
                                <label className="block">
                                    <span className="text-gray-700 dark:text-gray-300">Nueva Contraseña:</span>
                                    <div className="relative mt-1">
                                        <input
                                            ref={passwordInputRef}
                                            type="password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            className="w-full p-2 border rounded-md pr-10 dark:bg-gray-700 dark:text-gray-300"
                                        />
                                        <i className="fa-solid fa-lock absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-300"></i>
                                    </div>
                                </label>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="show-password"
                                        onChange={togglePasswordVisibility}
                                        className="mr-2"
                                    />
                                    <label htmlFor="show-password" className="text-sm text-gray-700 dark:text-gray-300">
                                        Mostrar contraseña
                                    </label>
                                </div>
                            </form>
                        </div>

                        <div className="flex justify-end items-center px-6 py-4 border-t dark:border-gray-600 gap-4">
                            <button
                                type="button"
                                onClick={() => setIsModalOpen(false)}
                                className="py-2 px-4 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-200 rounded-md hover:bg-gray-400 dark:hover:bg-gray-500"
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                onClick={handlePasswordChange}
                                className="py-2 px-4 bg-red-500 text-white rounded-md hover:bg-red-600"
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
