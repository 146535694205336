import React, { useState } from 'react'
import CardInfo from './CardInfo'
import { VendedorTab } from './VendedorTab';
import { BackButton } from '../../../../../components/buttons/ButtonDefault';

export const PerfilVendedor = () => {
    const [reload, setReload] = useState(false);
    const [data, setData] = useState(null);
    return (
        <div className='relative'>
            <BackButton />
            <CardInfo
                reload={reload}
                data={data}
                setData={setData}
            />
            <VendedorTab
                reload={reload}
                setReload={setReload}
                dataCard={data}
            />
        </div>
    )
}
